import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormatType } from '../models/formatType.model';
import { Router } from '@angular/router';
import { Core } from '../shared/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FormatTypeService {
  baseURL: any;
  constructor(protected http: HttpClient, @Inject('BASE_URL') url: string, protected router: Router) {
    this.baseURL = url;
  }


  getFormatTypes() {
    return this.getFormatTypesEndPoint().pipe(
      map(response => this.processFormatTypeResponse(response)));
  }

  getFormatTypesEndPoint(){
    return this.http.get(this.baseURL + 'FormatType');
  }

  getFormatType(formatTypeID: number) {
      return this.getFormatTypeEndPoint(formatTypeID).pipe(
          map(response => this.processFormatTypeResponse(response)));
    }

    getFormatTypeEndPoint(formatTypeID: number) {
        return this.http.get(this.baseURL + 'FormatType/' + formatTypeID);
    }


  processFormatTypeResponse(formatTypes: any): any {
    let source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'integer' },
        { name: 'name', type: 'string' },
        { name: 'active', type: 'string' },
        { name: 'comment', type: 'string' },
        { name: 'formatTypeStatus', type: 'string' },
        { name: 'theme', type: 'string' },
        { name: 'themeID', type: 'integer' },
        { name: 'downloadType', type: 'string' },
        { name: 'downloadTypeID', type: 'integer' },
        { name: 'contactEmail', type: 'string' },
        { name: 'loopLimit', type: 'integer' },
        { name: 'mapStyle', type: 'string' }
      ],
      localdata: formatTypes
    };
    return source;
  }


  newFormatType(formatType: FormatType) {
    return this.getnewFormatTypeEndPoint<FormatType>(formatType);
  }

  getnewFormatTypeEndPoint<FormatType>(formatType: FormatType): Observable<FormatType>{
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    return this.http.post<FormatType>('/FormatType', Core.tryJsonStringify(formatType), options);
  }

  editFormatType(formatType: FormatType) {
    return this.getEditFormatTypeEndPoint<FormatType>(formatType);
  }

  getEditFormatTypeEndPoint<FormatType>(formatType: FormatType): Observable<FormatType>{
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    return this.http.put<FormatType>('/FormatType', Core.tryJsonStringify(formatType), options);
  }

  deleteFormatType(formatType: FormatType) {
    return this.getDeleteFormatTypeEndPoint(formatType);
  }

  getDeleteFormatTypeEndPoint(formatType: FormatType): Observable<FormatType>{
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    return this.http.delete<FormatType>('/FormatType/' + formatType.id, options);
  }

  getSynchronizer(formatType: FormatType, firstLoad: boolean): Observable<FormatType> {

    let params: HttpParams = new HttpParams();
    params = params.set('firstLoad', firstLoad.toString());

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      }),
      params
    };

    return this.http.post<FormatType>('/FormatType/Synchronize', Core.tryJsonStringify(formatType), options);
  }

  getSynchronizeAll() {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    return this.http.post<FormatType>('/FormatType/SynchronizeAll', options);
  }

  getDownloadType() {
    return this.getDownloadTypeEndPoint();
  }

  getDownloadTypeEndPoint() {
    return this.http.get(this.baseURL + 'FormatType/GetDownloadType');
  }
}
