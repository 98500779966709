import { Component, OnInit, Injectable, Inject, ViewChild, AfterViewInit } from "@angular/core";
import { AlertService, MessageSeverity, MessageType } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { Route, Router, ActivatedRoute } from "@angular/router";
import { jqxGridComponent  }  from "jqwidgets-ng/jqxgrid";
import { DownloadService } from '../services/download.service';
import { Core } from '../shared/core';


@Component({
  selector: 'app-download-management',
  templateUrl: './download-management.component.html'
})


@Injectable()
export class DownloadManagementComponent implements OnInit, AfterViewInit {
  downloadAdapter: any;
  downloadPartAdapter: any;
  columns: any[];
  downloadPartColumns: any[];
  initRowDetails: any;
  nestedGrids: any[];
  guid: string = '';
  @ViewChild("downloadGrid") downloadGrid: jqxGridComponent;

  baseURL: any;
  ngOnInit() {


  }

  constructor(private authService: AuthService, private router: Router, @Inject('BASE_URL') url: string, private alertService: AlertService, private route: ActivatedRoute, private downloadService: DownloadService) {
    this.baseURL = url;

  }

  

  Cellclick(event: any): void {
    // Do Something
    var args = event.args;
    var rowBoundIndex = args.rowindex;
    var columnindex = args.columnindex;
    var rowdata = this.downloadGrid.getrowdata(rowBoundIndex);
  }


  ngAfterViewChecked(): void {

  }

  ngAfterViewInit() {
    this.downloadService.getRecentdownloads().subscribe(
      download => {
        this.downloadAdapter = new jqx.dataAdapter(download, { autoBind: true });

        this.columns =
          [
            { text: 'GUID', datafield: 'guid', width: 300 },
            { text: 'Email', datafield: 'email', width: 200 },
            { text: 'Created', datafield: 'created' },
            { text: 'Download Status', datafield: 'downloadStatus', width: 250 },
          {
            text: 'Open', datafield: '', width: 100, columntype: 'button',
            cellsrenderer: (): string => { return 'Open'; },
            buttonclick: (row: number): void => {
              window.open(window.location.origin + "/downloads?guid=" + this.downloadGrid.getrowdata(row).guid, "_blank");
            }
          },
          {
            text: 'Run', datafield: 'downloadStatusID', width:100, columntype: 'button',
            cellsrenderer: (): string => { return 'Run'; },
            buttonclick: (row: number): void => {
              this.downloadService.repeatDownload(this.downloadGrid.getrowdata(row).guid).subscribe(
                download => {
                },
                error => {
                  this.alertService.showMessage('Unable to run RepeatDownload', Core.getHttpResponseMessage('', error), MessageSeverity.error);
                });
            }
          }


          ];
        //this.downloadService.getalldownloadparts().subscribe(
        //  download => {
        //    this.downloadPartAdapter = new jqx.dataAdapter(download, { autoBind: true });
        //    this.downloadPartColumns =
        //      [
        //        { text: 'Part GUID', datafield: 'partGUID', width: 300 },
        //        { text: 'Theme', datafield: 'theme', width: 250 },
        //        { text: 'Dataset', datafield: 'dataset', width: 250 },
        //        { text: 'Status', datafield: 'downloadStatus', width: 100 },
        //        { text: 'Time period', datafield: 'date', width: 250 },
        //        { text: 'Data Criteria', datafield: 'dataCriteria' },
        //        { text: 'Download', datafield: 'downloadStatusID', width: 80, cellsrenderer: this.downloadRender }
        //      ];

        //    //this.nestedGrids = new Array();
        //    //// create nested grid.
        //    //this.initRowDetails = (index: number, parentElement: any, gridElement: any, record: any): void => {
        //    //  let id = record.guid.toString();
        //    //  let nestedGridContainer = parentElement.children[0];
        //    //  this.nestedGrids[index] = nestedGridContainer;
        //    //  let filtergroup = new jqx.filter();
        //    //  let filter_or_operator = 1;
        //    //  let filtervalue = id;
        //    //  let filtercondition = 'equal';
        //    //  let filter = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);
        //    //  // fill the orders depending on the id.
        //    //  let downloadPart = this.downloadPartAdapter.records;
        //    //  let downloadPartbyid = [];
        //    //  for (let i = 0; i < downloadPart.length; i++) {
        //    //    let result = filter.evaluate(downloadPart[i]['guid']);
        //    //    if (result)
        //    //      downloadPartbyid.push(downloadPart[i]);
        //    //  }
        //    //  let downloadPartSource = {
        //    //    datafields: [
        //    //      { name: 'partGUID', type: 'string' },
        //    //      { name: 'theme', type: 'string' },
        //    //      { name: 'dataset', type: 'string' },
        //    //      { name: 'downloadStatus', type: 'string' },
        //    //      { name: 'date', type: 'string' },
        //    //      { name: 'dataCriteria', type: 'string' },
        //    //      { name: 'downloadStatusID', type: 'integer' },

        //    //    ],
        //    //    id: 'guid',
        //    //    localdata: downloadPartbyid
        //    //  }
        //    //  let nestedGridAdapter = new jqx.dataAdapter(downloadPartSource);
        //    //  if (nestedGridContainer != null) {
        //    //    let settings = {
        //    //      width: '90%',
        //    //      height: 200,
        //    //      source: nestedGridAdapter,
        //    //      columns: this.downloadPartColumns
        //    //    };
        //    //    jqwidgets.createInstance(`#${nestedGridContainer.id}`, 'jqxGrid', settings);
        //    //  }
        //    //}


        //  },
        //  error => {
        //    this.alertService.showMessage('Unable to load list of downloads ', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        //  }
        //)
      },
      error => {
        this.alertService.showMessage('Unable to load list of downloads ', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    )

   
  }

  ready = (): void => {
    this.downloadGrid.showrowdetails(1);
  };

  rowdetailstemplate: any = {
    rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>', rowdetailsheight: 220, rowdetailshidden: true
  };

  refrenderer(row, datafield, value) {
    '<a href="' + window.location.origin + '/downloads?guid=' + value + '" target="_blank">' + value + '</a>';
  }

  

  downloadRender(row, datafield, value) {
    if (value == 2)
      return '<button style="width: 80px; height: 32px;cursor:pointer;"> <span class="glyphicon glyphicon-download-alt"> </span> </button>';
    else
      return '<button style="width: 80px; height: 32px;"> <img style="margin-left: 5px; " height="20" title="Under process" alt="Under process" width="20" src="../image/loading.gif"/></button>';


  }
  getWidth(): any {
    return '100%';
  }

  CleanUpDownloadFiles() {
    this.downloadService.cleanUpDownloadFiles().subscribe(
      download => {
      },
      error => {
        this.alertService.showMessage('Unable to run cleanUpDownloadFiles', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      });
  }

  GetAllDownloadFiles() {
    this.downloadService.getalldownloads().subscribe(
      download => {
        this.downloadAdapter = new jqx.dataAdapter(download, { autoBind: true });

        this.columns =
          [
            { text: 'GUID', datafield: 'guid', width: 300 },
            { text: 'Email', datafield: 'email', width: 200 },
            { text: 'Created', datafield: 'created' },
            { text: 'Download Status', datafield: 'downloadStatus', width: 250 },
            {
              text: 'Open', datafield: '', width: 100, columntype: 'button',
              cellsrenderer: (): string => { return 'Open'; },
              buttonclick: (row: number): void => {
                window.open(window.location.origin + "/downloads?guid=" + this.downloadGrid.getrowdata(row).guid, "_blank");
              }
            },
            {
              text: 'Run', datafield: 'downloadStatusID', width: 100, columntype: 'button',
              cellsrenderer: (): string => { return 'Run'; },
              buttonclick: (row: number): void => {
                this.downloadService.repeatDownload(this.downloadGrid.getrowdata(row).guid).subscribe(
                  download => {
                  },
                  error => {
                    this.alertService.showMessage('Unable to run RepeatDownload', Core.getHttpResponseMessage('', error), MessageSeverity.error);
                  });
              }
            }


          ];
        },
      error => {
        this.alertService.showMessage('Unable to load list of downloads ', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    )
  }
}




