export class MapDatasetCondition {

  public firstYear: number
  public lastYear: number
  public totalYears: number
  public totalEvents: number
  public formatTypeID: number
  public themeID: number
  public theme: string
  public datasetID: number
  public dataset: string
  public layerTypeID: number
  public layerType: string
  public conditions: Condition[] = [];
}

export class Condition {
  public conditionTypeID: number //0: DatasetID 1: DateCondition  2:RefernceLayer 3: DataCriteria 4: GeoBoundaryCondition 5:wktGeometryConditions
  public text: string
  public description: string
}
