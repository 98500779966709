import { Component, Inject, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import Zoom from 'ol/control/Zoom';
import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import { get} from 'ol/proj';
import { getBottomLeft } from 'ol/extent';
import { getTopRight } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { ViewMapService } from '../services/view-map.service';
import { AlertService, MessageSeverity } from '../services/alert.service';
import { Core } from '../shared/core';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxLayoutModule } from 'jqwidgets-ng/jqxlayout';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';

import { ViewMapSearchComponent } from '../components/viewMap/viewMap-search.component';
import { FormatTypeService } from '../services/formatType.service';
import { RECOService } from '../services/reco.service';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { ViewMapProfileComponent } from '../components/viewMap/viewMap-profile.component';
import { createEmpty, extend } from 'ol/extent.js';
import { AuthService } from '../services/auth.service';
import { ViewMapDisclaimerComponent } from '../components/viewMap/viewMap-disclaimer.component';
import { ViewMapShowReferenceLayerComponent } from '../components/viewMap/viewMap-showReferenceLayer.component';
import { MapLayer } from '../models/mapLayer.model';
import { MapExternalLayer } from '../models/mapExternalLayer.model';




@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.css']
})
export class ViewMapComponent implements AfterViewInit, OnDestroy{
  @ViewChild('map') map: any;
  @ViewChild('widget') window: jqxWindowComponent;
  @ViewChild('widgetDataCriteria') windowDataCriteria: jqxWindowComponent;
  @ViewChild('jqxWidgetTree') jqxWidgetTree: jqxTreeComponent;
  //@ViewChild('layoutReference') jqxLayout: jqxLayoutComponent;
  //@ViewChild('jqxLayoutComponent') jqxLayoutComponent: jqxLayoutComponent;

  @ViewChild('viewMapSearch') viewMapSearch: ViewMapSearchComponent;
  @ViewChild('viewMapProfile') viewMapProfile: ViewMapProfileComponent;
  @ViewChild('viewMapDisclaimer') viewMapDisclaimer: ViewMapDisclaimerComponent;
  @ViewChild('viewMapShowReferenceLayer') viewMapShowReferenceLayer: ViewMapShowReferenceLayerComponent;
  @ViewChild('searchbutton') searchbutton: any;
  
  

  view: OlView;
  widgetAdapter: any;
  widgetData: any;
  records: any;
  layerRecords: any;
  layerColumns: any;
  layout: any[]; 
  counter: number = 0;
  alertService: any;
  isLoaded: boolean = false;
  baseURL: any;
  moveEndID: any;
  tagID: string = '';
  themeID: string = '';
  datasetID: string = '';
  area: string = '';
  

  constructor(private authService: AuthService, @Inject('BASE_URL') url: string, private router: Router, private route: ActivatedRoute, private viewMapService: ViewMapService, private alertServices: AlertService, private recoService: RECOService, private formatTypeService: FormatTypeService) {
    this.alertService = alertServices;
    this.route.queryParams.subscribe(params => {
      if (params['tag'] != '')
        this.tagID = params['tag'];
      if (params['theme'] != '')
        this.themeID = params['theme'];
      if (params['dataset'] != '') 
        this.datasetID = params['dataset'];
      if (params['area'] != '')
        this.area = params['area'];
    });
    this.baseURL = url;
  }


  ngOnInit() {
    document.getElementById("overlay").style.display = "none";
    
    this.view = new OlView({
      center: fromLonLat([5.34, 63.85]),
      zoom: 3,
      minZoom: 3,
      maxZoom: 10
    });

    //this.layout =
    //  [{
    //    type: 'layoutGroup',
    //    orientation: 'vertical',
    //    items: [{
    //      type: 'documentGroup',
    //      alignment: 'top',
    //      width: '100%',
    //      height: '63%',
    //      unpinnedHeight: 400,
    //      items: [{
    //        type: 'documentPanel',
    //        title: 'Map',
    //        contentContainer: 'MapPanel',
    //        initContent: () => {
    //          let btn = document.getElementById("searchbutton");
    //          btn.addEventListener("click", (e: Event) => this.ShowSearch());
    //          btn = document.getElementById("download");
    //          btn.addEventListener("click", (e: Event) => this.DownloadMap());
    //          btn = document.getElementById("showReferenceLayers");
    //          btn.addEventListener("click", (e: Event) => this.showReferenceLayers(event));
    //          btn = document.getElementById("map");
    //          btn.addEventListener("pointermove", (e: Event) => this.mapPointerMove(event));
    //          btn = document.getElementById("map");
    //          btn.addEventListener("click", (e: Event) => this.mapOnClick(event));
    //        }
    //      }]
    //    },
    //    {
    //      type: 'tabbedGroup',
    //      alignment: 'bottom',
    //      width: '100%',
    //      height: '36%',
    //      unpinnedHeight: 400,
    //      items: [{
    //        type: 'layoutPanel',
    //        title: 'Layer',
    //        contentContainer: 'LayerPanel',
    //        initContent: () => {
    //          this.layerColumns =
    //            [
    //              { text: 'ThemeID', datafield: 'themeID', hidden: true },
    //              { text: 'DatasetID', datafield: 'datasetID', hidden: true },
    //              { text: 'FormatTypeID', datafield: 'formatTypeID', hidden: true },
    //              { text: 'Theme', datafield: 'theme', width: '29.3%' },
    //              { text: 'Dataset', datafield: 'dataset', width: '29%' },
    //              { text: 'Layer type', datafield: 'layerType', width: '10%' },
    //              { text: 'Start date', datafield: 'firstYear', width: '10%' },
    //              { text: 'End date', datafield: 'lastYear', width: '10%' },
    //              { text: 'Total events', datafield: 'totalEvents', cellsFormat: 'n', width: '10%' }
    //            ];

    //          jqwidgets.createInstance('#layerGrid', 'jqxGrid', {
    //            width: '99.9%', height: '99%', scrollmode: 'logical', pageable: 'true', sortable: 'true', columnsresize: 'true',
    //            autoheight: 'true', pagermode: 'simple', source: this.layerRecords, columns: this.layerColumns
    //          });
    //        }
    //      }]
    //    }]

    //  }];


  }

  ngOnDestroy() {
    this.viewMapShowReferenceLayer.close();
    this.viewMapSearch.close();
    this.window.close();
    this.windowDataCriteria.close();
  }

  ngAfterViewChecked(): void {
    
  }

  onlayerGridRowselect(event: any) {
    var args = event.args;
    if (args.rowindex.length != undefined) {
      var count = this.layerRecords.records.length;
      var check = true;
      if (args.rowindex.length == 0)
        check = false;
      for (var i = 0; i < count; i++)
        this.gridRowCheck(this.layerRecords.records[i].datasetID, check);
    }
    else
      this.gridRowCheck(event.args.row.datasetID, true);
  }

  gridRowCheck(datasetID: number, check: boolean) {
    var mapLayer = this.viewMapSearch.mapLayers.filter(o => o.datasetID == datasetID)[0];
    if (mapLayer != undefined) {
      mapLayer.visible = check;
      this.viewMapSearch.layers[mapLayer.mainIndex].setVisible(check);
      this.ShowLegend();
    }
  }
  
  onlayerGridRowunselect(event: any) {
    this.gridRowCheck(event.args.row.datasetID, false);
  }

  

  
  Initialized(event: any): void {
    this.isLoaded = true;
  }

  ngAfterViewInit(): void {
    let btn = document.getElementById("searchbutton");
    btn.addEventListener("click", (e: Event) => this.ShowSearch());
    btn = document.getElementById("download");
    btn.addEventListener("click", (e: Event) => this.DownloadMap());
    btn = document.getElementById("showReferenceLayers");
    btn.addEventListener("click", (e: Event) => this.showReferenceLayers(event));
    btn = document.getElementById("map");
    btn.addEventListener("pointermove", (e: Event) => this.mapPointerMove(event));
    btn = document.getElementById("map");
    btn.addEventListener("click", (e: Event) => this.mapOnClick(event));

    this.layerColumns =
      [
        { text: 'ThemeID', datafield: 'themeID', hidden: true },
        { text: 'DatasetID', datafield: 'datasetID', hidden: true },
        { text: 'FormatTypeID', datafield: 'formatTypeID', hidden: true },
        { text: 'Theme', datafield: 'theme', width: '29.3%' },
        { text: 'Dataset', datafield: 'dataset', width: '29%' },
        { text: 'Layer type', datafield: 'layerType', width: '10%' },
        { text: 'Start date', datafield: 'firstYear', width: '10%' },
        { text: 'End date', datafield: 'lastYear', width: '10%' },
        { text: 'Total events', datafield: 'totalEvents', cellsFormat: 'n', width: '10%' }
      ];

    jqwidgets.createInstance('#layerGrid', 'jqxGrid', {
      width: '99.9%', height: '99%', scrollmode: 'logical', pageable: 'true', sortable: 'true', columnsresize: 'true',
      autoheight: 'true', pagermode: 'simple', source: this.layerRecords, columns: this.layerColumns
    });


    this.viewMapSearch.notifyUpdateMap = () => {
          //var extent = createEmpty();
          //this.viewMapSearch.PointSource.forEach(function (source) {
          //  extend(extent, source.getExtent());
          //});
          //this.viewMapSearch.PolygonSource.forEach(function (source) {
          //  extend(extent, source.getExtent());
          //});

          //this.map.getView().fit(extent, this.map.getSize());
        

         this.widgetData = [];
         var i;
         for (let dataset of this.viewMapSearch.selectedDatasets) {
           this.widgetData.push({ "text": dataset.dataset, "id": dataset.datasetID, "parentid": "-1" });
           var t = this.widgetData.filter(o => o.id == dataset.datasetID);
           i = 0;
           for (let condition of dataset.conditions) {
             if (condition.conditionTypeID == 1 && condition.text != "") {
               this.widgetData.push({ "text": "Time period", "id": dataset.datasetID + "1", "parentid": t[0].id });
               var w = this.widgetData.filter(o => o.id == dataset.datasetID + "1");
               this.widgetData.push({ "text": condition.text, "id": dataset.datasetID + "2", "parentid": w[0].id });
             }
             else
               if (condition.conditionTypeID == 3 && condition.text != "") {
                 var w = this.widgetData.filter(o => o.id == dataset.datasetID + "3");
                 if (w.length == 0) {
                   this.widgetData.push({ "text": "Data Criteria", "id": dataset.datasetID + "3", "parentid": t[0].id });
                   w = this.widgetData.filter(o => o.id == dataset.datasetID + "3");
                 }
                 this.widgetData.push({ "text": condition.description, "id": dataset.datasetID + (i++).toString(), "parentid": w[0].id });
               }
           }
         }

          let widgetSource =
          {
            datatype: "json",
            datafields: [
              { name: 'id' },
              { name: 'parentid' },
              { name: 'text' },
            ],
            id: 'id',
            localdata: this.widgetData
      };


      if (this.viewMapSearch.selectedDatasets.length == 0 || this.viewMapSearch.selectedDatasets.filter(s => s.totalEvents != 0).length == 0)
        this.alertService.showRemainMessage('Information', "No data is available in the search criteria", MessageSeverity.information);
      else {
        this.widgetAdapter = new jqx.dataAdapter(widgetSource, { autoBind: true });
        this.widgetAdapter.dataBind();
        this.records = this.widgetAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
        this.window.open();
        this.windowDataCriteria.open();
        this.jqxWidgetTree.expandAll();

        let layerSource =
        {
          datatype: "json",
          datafields: [
            { name: 'themeID' },
            { name: 'datasetID' },
            { name: 'formatTypeID' },
            { name: 'theme' },
            { name: 'dataset' },
            { name: 'layerType' },
            { name: 'firstYear' },
            { name: 'lastYear' },
            { name: 'totalEvents' },
          ],
          localdata: this.viewMapSearch.selectedDatasets.filter(s => s.totalEvents != 0)
        };

        this.layerRecords = new jqx.dataAdapter(layerSource, { autoBind: true });
        const layerGrid = jqwidgets.createInstance('#layerGrid', 'jqxGrid',
          {
            width: '99.9%',
            height: '99%',
            source: this.layerRecords,
            columns: this.layerColumns,
            autoheight: true,
            pageable: true,
            sortable: true,
            altrows: true,
            enabletooltips: true,
            editable: false,
            groupable: true,
            pagesize: 5,
            pagesizeoptions: ['5', '10', '20'],
            selectionmode: 'checkbox'
          });
        

        layerGrid.addEventHandler('rowselect', this.onlayerGridRowselect.bind(this) );
        layerGrid.addEventHandler('rowunselect', this.onlayerGridRowunselect.bind(this));

        for (let item of layerGrid.getrows()) {
          var datasetID = item.datasetID;
          var mapLayer = this.viewMapSearch.mapLayers.filter(o => o.datasetID == datasetID);
          if (mapLayer != undefined && mapLayer[0].visible)
            layerGrid.selectrow(item.uid);
        }

        //layerGrid.selectallrows();
       
        
        this.LoadLegend();
        this.ShowLegend();
        this.isLoaded = true;
        this.moveEndID = undefined;
      }
      };

    this.viewMapSearch.notifyStartUpdateMap = () => {
      this.window.close();
      this.windowDataCriteria.close();
      this.layerRecords = [];
      jqwidgets.createInstance('#layerGrid', 'jqxGrid', { width: '99.9%', height: '99%', source: this.layerRecords, columns: this.layerColumns });
      var legend = document.getElementById('legend');
      legend.setAttribute("style", "display:none;");
      this.map.updateSize();
    }

    this.viewMapDisclaimer.notifyDownload = () => {
      //Just download visible layers #3317
      this.alertService.startLoadingMessage();
      var download = [];
      for (let condition of this.viewMapSearch.downloadCondition) {
        if (this.viewMapSearch.mapLayers.filter(s => s.formatTypeID == condition.formatTypeID && s.visible && s.datasetID == condition.datasetID).length > 0)
          download.push(condition);
        }
      this.viewMapService.getDownloadGUID(download).subscribe(
        result => {
          this.viewMapService.getDownloadOroginalData(download, result.toString()).subscribe(
            result => {
            },
            error => {
              this.alertService.showMessage('Unable to download data', Core.getHttpResponseMessage('', error), MessageSeverity.error);
            }
          );
          window.open(window.location.origin + "/downloads?guid=" + result.toString(), "_blank");
          this.alertService.stopLoadingMessage();
        },
        error => {
          this.alertService.showMessage('Unable to download data', Core.getHttpResponseMessage('', error), MessageSeverity.error);
          this.alertService.stopLoadingMessage();
        }
      );

     
    };

    this.viewMapShowReferenceLayer.notifyAddReferenceLayers = (url: string, layerDefs: string) => {
      this.AddReferenceLayers(url, layerDefs);
    };

    this.viewMapShowReferenceLayer.notifyRemoveReferenceLayers = (url: string, layerDefs: string) => {
      this.RemoveReferenceLayers(url, layerDefs);
    };

    this.viewMapSearch.notifyviewMapSearchAddReferenceLayers = (url: string, layerDefs: string) => {
      this.AddReferenceLayers(url, layerDefs);
    };

    this.viewMapSearch.notifyviewMapSearchRemoveReferenceLayers = (url: string, layerDefs: string) => {
      this.RemoveReferenceLayers(url, layerDefs);
    };

        
    this.map = new OlMap({
      target: 'map',
      layers: this.viewMapSearch.layers,
      view: this.view
    });

    this.map.on('moveend', (e) => {
      this.moveEnd();
    });

    this.map.updateSize();

    var tooltip = document.getElementById('tooltip');
    var overlay = new Overlay({
      id: 1,
      element: tooltip,
      offset: [10, 0],
      positioning: 'bottom-left'
    });
    this.map.addOverlay(overlay);

    var tooltip1 = document.getElementById('profilertooltip');
    var overlay1 = new Overlay({
      id: 2,
      element: tooltip1,
      offset: [10, 20],
      positioning: 'bottom-left'
    });
    this.map.addOverlay(overlay1);
    //this.map.on('pointermove', this.displayTooltip);
    //this.map.on('click', this.mapClick);

      //Load ViewMapsearch data
      this.viewMapService.getJsonSchema().subscribe(
        result => {
          this.viewMapSearch.schema = result;
          this.viewMapSearch.dataCriteriaAdapter = new jqx.dataAdapter(result);
        },
        error => {
          this.alertService.showMessage('Unable to get jsonSchema', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
      );

      this.viewMapService.getTagView().subscribe(
        async result => {
          this.viewMapSearch.tagView = result;
          this.viewMapSearch.treeMapLevel = 0;
          this.viewMapSearch.createTreeMap("");
        },
        error => {
          this.alertService.showMessage('Unable to getTagView', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
    );

    this.viewMapService.getThemeView().subscribe(
      async result => {
        this.viewMapSearch.themeView = result;
      },
      error => {
        this.alertService.showMessage('Unable to getThemeView', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );

    this.formatTypeService.getFormatTypes().subscribe(
        async result => {
          this.viewMapSearch.formatTypes = result;
        },
        error => {
          this.alertService.showMessage('Unable to getFormatTypes', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
      );

      this.viewMapService.getDatasetInventory().subscribe(
        async result => {
          this.viewMapSearch.datasetView = result;
          this.viewMapSearch.datasetRecords = new jqx.dataAdapter(result, { autoBind: true });
          this.viewMapSearch.columns =
            [
              { text: 'ThemeID', datafield: 'themeID', hidden: true },
              { text: 'DatasetID', datafield: 'datasetID', hidden: true },
              { text: 'FormatTypeID', datafield: 'formatTypeID', hidden: true },
              { text: 'Theme', datafield: 'theme', width: '22%' },
              { text: 'Dataset', datafield: 'dataset', width: '28%' },
              { text: 'Layer type', datafield: 'layerType', width: '15%' },
              { text: 'First year', datafield: 'firstYear', width: '7%' },
              { text: 'Last year', datafield: 'lastYear', width: '7%' },
              { text: 'Total events', datafield: 'totalEvents', cellsFormat: 'n', width: '8%' },
              { text: 'Years of data', datafield: 'totalYears', width: '10%'}
            ];
          this.viewMapSearch.tagID = this.tagID;
          this.viewMapSearch.themeID = this.themeID;
          this.viewMapSearch.datasetID = this.datasetID;
          this.viewMapSearch.area = this.area;
          this.viewMapSearch.open();
        },
        error => {
          this.alertService.showMessage('Unable to getDatasetInventory', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
    );

    

  }

  AddReferenceLayers(url: string, layerDefs: string) {
    var ml = this.viewMapSearch.mapLayers.filter(s => s.mapExternalLayers.filter(y => y.url == url && y.filter == layerDefs).length > 0);
    if (ml.length > 0)
      this.viewMapSearch.layers[ml[0].mainIndex].setVisible(true);
    else {
      this.viewMapSearch.externalSource[this.viewMapSearch.externalLayerIndex].setUrl(url);
      this.viewMapSearch.externalSource[this.viewMapSearch.externalLayerIndex].updateParams({
        VERSION: "1.3.1",
        LAYERS: "0",
        FORMAT: "image/png",
        STYLES: "PolygonGray",
        layerDefs: layerDefs
      });
      var mapLayer = new MapLayer();
      mapLayer.visible = true;
      mapLayer.layerIndex = this.viewMapSearch.externalLayerIndex;
      mapLayer.mainIndex = this.viewMapSearch.externalLayerIndex + 2;
      mapLayer.sourceType = 2;
      mapLayer.mapExternalLayers.push(new MapExternalLayer());
      mapLayer.mapExternalLayers[0].url = url;
      mapLayer.mapExternalLayers[0].filter = layerDefs;
      this.viewMapSearch.mapLayers.push(mapLayer);
      this.viewMapSearch.layers[mapLayer.mainIndex].setVisible(true);
      this.viewMapSearch.externalLayerIndex++;
    }
  }

  RemoveReferenceLayers(url: string, layerDefs: string) {
    var ml = this.viewMapSearch.mapLayers.filter(s => s.mapExternalLayers.filter(y => y.url == url && y.filter == layerDefs).length > 0);
    if (ml.length > 0){
      this.viewMapSearch.layers[ml[0].mainIndex].setVisible(false);
    }
  }


  LoadLegend() {
    var i = 0;
    for (let mapLayer of this.viewMapSearch.mapLayers) {
      mapLayer.legends = [];
      if (mapLayer.visible && mapLayer.datasetID != undefined) {
        var selectedDataset = this.viewMapSearch.selectedDatasets.filter(o => o.datasetID == mapLayer.datasetID);
        var feature;
        var img;
        var width, height;
        var legends = [];
       if (mapLayer.sourceType == 1 || mapLayer.sourceType == 0) {
          var layerStyle = this.viewMapSearch.layers[mapLayer.mainIndex].getStyleFunction();
         var feature;
         var source;
         if (mapLayer.sourceType == 0)
           source = this.viewMapSearch.PointSource[mapLayer.layerIndex].getFeatures();
         else
           source = this.viewMapSearch.PolygonSource[mapLayer.layerIndex].getFeatures();

         for (var i = 0; i < source.length; i++) {
            feature = source[i];
           var featureStyle = layerStyle(feature);
           var image;
           if (!Array.isArray(featureStyle))
             image = featureStyle.getImage();
           else
             image = featureStyle[0].getImage();


           if (image != null) {
             img = image.canvas_.toDataURL("image/png");
             if (image != null) {
               width = image.imageSize_[0];
               height = image.imageSize_[1];
             }
             else {
               width = 7;
               height = 7;
             }
             if (legends.filter(s => s == img).length == 0) {
               var tr = document.createElement("tr");
               tr.setAttribute("style", "display:table-row;");
               var cell = document.createElement("td");
               cell.setAttribute("style", "padding-left:10px;");
               var rect = document.createElement("div");
               rect.setAttribute("style", "width:" + width + "px; height:" + height + "px; background:url('" + img + "');background-repeat: no-repeat");
               rect.id = "mapLegendRect" + i++;
               cell.appendChild(rect);
               tr.appendChild(cell);
               cell = document.createElement("td");
               cell.setAttribute("style", "padding-left:10px;");
               cell.innerHTML = selectedDataset[0].dataset;
               tr.appendChild(cell);
               mapLayer.legends.push(tr);
               legends.push(img);
             }
           }
           else 
           {
             var fill = featureStyle.getFill();
             var stroke = featureStyle.getStroke();
               var tr = document.createElement("tr");
               tr.setAttribute("style", "display:table-row;");
               var cell = document.createElement("td");
              cell.setAttribute("style", "padding-left:10px;");
              var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
               svg.setAttribute("width", "20");
               svg.setAttribute("height", "20");
               svg.setAttribute("style", "padding-top: 15px;")
               svg.id = "mapLegendSvg" + i++;
               var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
               line.setAttribute("x1", "0");
               line.setAttribute("y1", "0");
               line.setAttribute("x2", "20");
               line.setAttribute("y2", "0");
               line.setAttribute("stroke", stroke.getColor());
               line.setAttribute("stroke-width", "4");
               line.setAttribute("fill", fill.getColor());

                line.setAttribute("line", "");
               svg.appendChild(line);
               cell.appendChild(svg);
               tr.appendChild(cell);
               cell = document.createElement("td");
               cell.setAttribute("style", "padding-left:10px;");
               cell.innerHTML = selectedDataset[0].dataset;
               tr.appendChild(cell);
             if (legends.filter(s => s == svg.innerHTML).length == 0) {
                 mapLayer.legends.push(tr);
                 legends.push(svg.innerHTML);
               }
           }
           
         }
        }
        else {
          var url = mapLayer.mapExternalLayers[0].url;
          img = url + '?REQUEST=GetLegendGraphic&sld_version=1.0.0&layer=' + mapLayer.mapExternalLayers[0].layer + '&format=image/png&style=' + mapLayer.mapExternalLayers[0].style;
          var tr = document.createElement("tr");
          tr.setAttribute("style", "display:table-row;");
          var cell = document.createElement("td");
          cell.setAttribute("style", "padding-left:10px;");
          var im = document.createElement("img");
          im.src = img;
          cell.appendChild(im);
          tr.appendChild(cell);
          cell = document.createElement("td");
          cell.setAttribute("style", "padding-left:10px;");
          cell.innerHTML = selectedDataset[0].dataset;
          tr.appendChild(cell);
          mapLayer.legends.push(tr);
        }
      }
    }
  }

  ShowLegend() {
    var legend = document.getElementById('legend');
    while (legend.hasChildNodes())
      legend.removeChild(legend.lastChild);
    legend.innerText = '';
    var table = document.createElement("table");
    table.setAttribute("style", "width:100%;");
    var showLegend = false;
    for (let mapLayer of this.viewMapSearch.mapLayers) {
      if (mapLayer.visible) {
        for (var i = 0; i < mapLayer.legends.length; i++) 
          table.appendChild(mapLayer.legends[i]);
        showLegend = true;
      }
    }

    if (showLegend) {
      legend.appendChild(table);
      legend.setAttribute("style", "display:table;");
      this.window.open();
      this.window.move(50, 580);
      this.windowDataCriteria.open();
      this.windowDataCriteria.move(50, 830);
    }
    else {
      legend.setAttribute("style", "display:none;");
      this.window.close();
      this.windowDataCriteria.close();
    }
  }


 
  moveEnd() {
    //2.5 sec delay after map mov eend to load cluster again
    let timerVal = 2500;
    if (this.moveEndID != undefined) {
      clearTimeout(this.moveEndID);
      this.moveEndID = undefined;
    }
    if (this.viewMapSearch.mapLayers != undefined && this.viewMapSearch.mapLayers.filter(s => s.formatTypeID != undefined).length != 0 && this.viewMapSearch.loadingCount == 0)
      this.moveEndID = setTimeout(function () { this.viewMapSearch.UpdateClusterMap(); }.bind(this), timerVal);
  }

  DownloadMap() {
    if (this.viewMapSearch.downloadCondition.length != 0) 
      this.viewMapDisclaimer.open();
  }


  showReferenceLayers(event: any) {
    this.viewMapShowReferenceLayer.viewMapShowReferenceLayerWindow.position({ x: (event.clientX - Number(this.viewMapShowReferenceLayer.viewMapShowReferenceLayerWindow.attrWidth) - 10), y: event.clientY - 10 });
    this.viewMapShowReferenceLayer.mode = 0;
    this.viewMapShowReferenceLayer.open();
  }

  ShowSearch() {
    this.viewMapShowReferenceLayer.close();
    if (this.moveEndID != undefined) {
      clearTimeout(this.moveEndID);
      this.moveEndID = undefined;
    }
    this.viewMapProfile.close();
    this.viewMapSearch.open();
  }

  wrapLon(value) {
    var worlds = Math.floor((value + 180) / 360);
    return value - (worlds * 360);
  }

  mapPointerMove(event: any) {
     var extent = this.map.getView().calculateExtent(this.map.getSize());
    var bottomLeft = transform(getBottomLeft(extent), get("EPSG:3857"), 'EPSG:4326');
    var topRight = transform(getTopRight(extent), get("EPSG:3857"), 'EPSG:4326');
    this.viewMapSearch.zoom = this.map.getView().getZoom();
    this.viewMapSearch.left = this.wrapLon(bottomLeft[0]);
    this.viewMapSearch.right = this.wrapLon(topRight[0]);
    if (this.viewMapSearch.left > this.viewMapSearch.right) {
      var temp = this.viewMapSearch.left;
      this.viewMapSearch.left = this.viewMapSearch.right;
      this.viewMapSearch.right = temp;
    }
    this.viewMapSearch.top = topRight[1];
    this.viewMapSearch.bottom = bottomLeft[1];

    var pixel = this.map.getEventPixel(event);
    var feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
      return feature;
    });
    var tooltip = document.getElementById('tooltip');
    var tooltip1 = document.getElementById('profilertooltip');
    tooltip.style.display = feature ? '' : 'none';
    tooltip1.style.display = feature ? '' : 'none';

    if (feature) {
      this.map.getOverlayById(1).setPosition(this.map.getEventCoordinate(event));
      this.map.getOverlayById(2).setPosition(this.map.getEventCoordinate(event));
      tooltip.innerHTML = feature.mapFeatureTooltip;
      if (tooltip.innerHTML == 'undefined')
        tooltip.innerHTML = '';
    }
  }

  async mapOnClick(event: any) {
    if (this.viewMapSearch.loadingCount != 0 || this.viewMapSearch.viewMapSearch.isOpen() || this.moveEndID != undefined) return;
    var pixel = this.map.getEventPixel(event);
    var coordinate = this.map.getEventCoordinate(event);
    var c = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
    var ids: string[] = [];
    //Features
    this.map.forEachFeatureAtPixel(pixel, function (feature) {
      if (feature && feature.id != undefined) {
        for (let item of feature.id)
          ids.push(item);
      }
    });
    //External layers
    var externalList = this.viewMapSearch.mapLayers.filter(s => s.sourceType == 2 && s.visible);
    for (let ex of externalList) {
      var wmsSource = this.viewMapSearch.externalSource[ex.layerIndex];
      var url = '';
      if (wmsSource.getParams().LAYERS) {
        if (!wmsSource.urls[0].toString().includes('gis.ices.dk/gis'))
          //  url = wmsSource.getGetFeatureInfoUrl(
          //    coordinate, this.map.getView().getResolution(), 'EPSG:3857',
          //    {
          //      'INFO_FORMAT': 'application/geojson',
          //      'propertyName': ex.mapExternalLayers[0].filter
          //    });
          //else
            url = wmsSource.getGetFeatureInfoUrl(
              coordinate, this.map.getView().getResolution(), 'EPSG:3857',
              {
                'INFO_FORMAT': 'application/json',
                'propertyName': ex.mapExternalLayers[0].filter
              });

        if (url && url != undefined) {
          await this.viewMapService.getMapFeature(url).then(
            result => {
              var parser = new GeoJSON();
              if (result != null && result != "") {
                var res = parser.readFeatures(result);
                var info = [];
                for (let item of res)
                  info.push(item.get(ex.mapExternalLayers[0].filter));
                if (info.length > 0) {
                  for (let item of info)
                    var li = ex.mapExternalLayers.filter(o =>
                      o.value.toString().trim() == item.toString().trim()
                    );
                  for (let l of li)
                    ids = ids.concat(l.ids);
                }
              }
            },
            error => {
              this.alertService.showMessage('Unable to getMapFeature', Core.getHttpResponseMessage('', error), MessageSeverity.error);
            }
          );
        }
      }
    }
    if (ids.length > 0 && this.viewMapSearch.loadingCount == 0 && !this.viewMapSearch.viewMapSearch.isOpen()) {
      this.viewMapProfile.conditionString = ids;
      var x = pixel[0] + 100;
      var content = document.getElementById('content');
      if (x + 620 > content.clientWidth)
        x = content.clientWidth - 620;
      this.viewMapProfile.open(x, pixel[1] + 200);
    }
  }

  getWidth(): any {
    return '99.7%';
  }
}
