import { Component, OnInit, OnDestroy, Input, Injectable  } from '@angular/core'
import { Router, Route, ActivatedRoute } from '@angular/router'
import { AuthService } from '../services/auth.service';
import { IUser } from '../models/account/user.model'
import { UserLogin } from '../models/account/user-login.model';
import { AlertService, MessageType, MessageSeverity } from '../services/alert.service';
import { Core } from '../shared/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable()
export class LoginComponent implements OnInit, OnDestroy {
  currentUser: IUser;
  returnUrl: string;
  userLogin = new UserLogin();
  isLoading = false;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private alertService: AlertService) {

  }

  ngOnInit() {
    this.userLogin.rememberMe = this.authService.rememberMe;
    this.authService.logOff();
    this.returnUrl = this.route.snapshot.queryParams['return'] || '/';
  }

  ngOnDestroy() {

  }
  

  login() {
    this.isLoading = true;
    this.alertService.startLoadingMessage('', 'Attempting login...');
    this.authService.loginUser(this.userLogin.userName, this.userLogin.password, this.userLogin.rememberMe).subscribe(
      user => {
        this.alertService.stopLoadingMessage();
        this.isLoading = false;
        this.router.navigate([this.returnUrl]);
        this.alertService.showMessage('Login', `Welcome ${user.userName}`, MessageSeverity.information);
        //this.AlertService.showDialog(`Welcome ${user.userName}`, MessageType.confirm);
     },
      error => {
        this.alertService.showMessage('Unable to login', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        this.alertService.stopLoadingMessage();
        this.isLoading = false;
      });
  }
}

