import { Component, OnInit } from '@angular/core';
import { AlertService, Dialog, MessageType, Message, MessageSeverity } from './services/alert.service';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { AuthService } from './services/auth.service';
declare var alertify: any;



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  title = 'app';
  remainMessages: number[] = [];
  constructor(private AlertService: AlertService, private toastaService: ToastaService, private toastaConfig: ToastaConfig, public authService: AuthService) {
    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
  }

  ngOnInit() {
    this.AlertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.AlertService.getMessageEvent().subscribe(alert => this.showToast(alert, false));
    this.AlertService.getRemainMessageEvent().subscribe(alert => this.showToast(alert, true));
  }

  showDialog(dialog: Dialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case MessageType.alert:
        alertify.alert(dialog.message);

        break;
      case MessageType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          });

        break;
      case MessageType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          }, dialog.defaultValue);

        break;
    }
  }

  showToast(message: Message, isRemain: boolean) {

    if (message == null) {
      for (const id of this.remainMessages.slice(0)) {
        this.toastaService.clear(id);
      }
      return;
    }

    const toastOptions: ToastOptions = {
      title: message.summary,
      msg: message.detail,
      showClose: message.showClose,
      timeout: isRemain ? 0 : 4000
    };

    if (isRemain) {
      toastOptions.onAdd = (toast: ToastData) => this.remainMessages.push(toast.id);

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.remainMessages.indexOf(toast.id, 0);

        if (index > -1) {
          this.remainMessages.splice(index, 1);
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    }


    switch (message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.information: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warning: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  logout() {
    this.authService.logOff();
  }

}
