<div class="vertical-center-flex">
  <div class="login-container center-block">
    <div class="panel panel-primary boxshadow">
      <div class="panel-heading">
        <span class="glyphicon glyphicon-lock"></span> Login
        <button type="button" class="close pull-right" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="panel-body">
        <div class="col-md-8 col-md-offset-2">
          <form #loginForm="ngForm" class="form-horizontal login" (ngSubmit)="login()" autocomplete="off">
            <h2>Use your ICES account to log in</h2>
            <div class="form-group has-feedback">
              <label class="control-label" for="login-userName">User name:</label>
              <input type="text" id="login-userName" name="userName" placeholder="Enter username" class="form-control" [(ngModel)]="userLogin.userName" required />
            </div>
            <div class="form-group has-feedback">
              <label class="control-label" for="login-password">Password:</label>
              <input type="password" id="login-password" name="password" placeholder="Enter password" class="form-control" [(ngModel)]="userLogin.password" required />
            </div>
            <div class="form-group col-md-offset-0">
              <div class="checkbox">
                <label><input type="checkbox" name="rememberMe" [(ngModel)]="userLogin.rememberMe"> Remember me</label>
              </div>
            </div>

            <div class="form-group col-md-offset-0">
              <button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid">Login</button>
            </div>
            <div class="form-group col-md-offset-0">
              <h5>Please contact accessions&#64;ices.dk to request a SharePoint Login</h5>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

