import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild, AfterViewChecked, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { ViewMapService } from "../../services/view-map.service";
import { Core } from "../../shared/core";
import { AuthService } from '../../services/auth.service';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import Zoom from 'ol/control/Zoom';
import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import { get } from 'ol/proj';
import { getBottomLeft } from 'ol/extent';
import { getTopRight } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import OlVector from 'ol/source/Vector';
import OlTileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import OlXYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import WKT from 'ol/format/WKT';
import WMS from 'ol/source/TileWMS';
import Draw, {createBox} from 'ol/interaction/Draw';
import { ViewMapShowReferenceLayerComponent } from './viewMap-showReferenceLayer.component';
import { MapLayer } from '../../models/mapLayer.model';
import { MapExternalLayer } from '../../models/mapExternalLayer.model';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';



@Component({
  selector: 'app-viewMap-geographicalBox',
  templateUrl: './viewMap-geographicalBox.component.html',
  styleUrls: ['./viewMap-geographicalBox.component.css']
})

export class ViewMapGeographicalBoxComponent implements AfterViewInit, OnInit, OnDestroy  {
  @ViewChild('viewGeographicalBoxWindow') viewGeographicalBoxWindow: jqxWindowComponent;
  @ViewChild('geoBoxMap') geoBoxMap: any;
  @ViewChild('viewMapShowReferenceLayer') viewMapShowReferenceLayer: ViewMapShowReferenceLayerComponent;
  view: OlView;
  geoBoxMaplayers: any;
  externalSource: any = [];
  totalExternalLayer: number = 10;
  mapLayers: MapLayer[] = [];
  externalLayerIndex: number = 0;
  source: any;
  draw: any;
  geometryConditions: string = '';
  wkt: any;
  wktGeometryConditions:string = '';
  referenceLayerConditions:string = '';
  referenceLayerConditionsText: string = '';
  referenceLayerConditionsExternalLayer: string = '';
  public notifyAddGeoConditions: () => void;
  public notifyAddReferenceLayers: (url: string, layerDefs: string) => void;
  public notifyRemoveReferenceLayers: (url: string, layerDefs: string) => void;
  public notifyGeoBoxMapApplyCondition: () => void;

  ngOnInit() {
    this.wkt = new WKT();
   }

  ngAfterViewInit(): void {
    this.viewMapShowReferenceLayer.notifyGeoBoxMapAddReferenceLayers = (url: string, layerDefs: string, id: string, id1: string) => {
      if (this.source != undefined && this.source.getFeatures().length > 0) {
        this.wktGeometryConditions = '';
        this.clearMap();
      }
      var ml = this.mapLayers.filter(s => s.mapExternalLayers.filter(y => y.url == url && y.filter == layerDefs).length > 0);
      if (ml.length > 0) {
        ml[0].visible = true;
        this.geoBoxMaplayers[ml[0].mainIndex].setVisible(true);
        
      }
      else {
        this.externalSource[this.externalLayerIndex].setUrl(url);
        this.externalSource[this.externalLayerIndex].updateParams({
          VERSION: "1.3.1",
          LAYERS: "0",
          FORMAT: "image/png",
          Style: "default",
          layerDefs: layerDefs 
        });
        var mapLayer = new MapLayer();
        mapLayer.visible = true;
        mapLayer.layerIndex = this.externalLayerIndex;
        mapLayer.mainIndex = this.externalLayerIndex + 3;
        mapLayer.sourceType = 2;
        mapLayer.mapExternalLayers.push(new MapExternalLayer());
        mapLayer.mapExternalLayers[0].url = url;
        mapLayer.mapExternalLayers[0].filter = layerDefs;
        mapLayer.mapExternalLayers[0].ids[0] = id;
        mapLayer.mapExternalLayers[0].ids[1] = id1;

        this.mapLayers.push(mapLayer);
        this.geoBoxMaplayers[mapLayer.mainIndex].setVisible(true);
        
        this.externalLayerIndex++;
      }

      if (this.viewMapShowReferenceLayer.area != "")
        this.AddGeoBoxCondition();
    };

    this.viewMapShowReferenceLayer.notifyGeoBoxMapRemoveReferenceLayers = (url: string, layerDefs: string) => {
      //this.externalLayerIndex--;
      var ml = this.mapLayers.filter(s => s.mapExternalLayers.filter(y => y.url == url && y.filter == layerDefs).length > 0);
      if (ml.length > 0) {
        ml[0].visible = false;
        this.geoBoxMaplayers[ml[0].mainIndex].setVisible(false);
        
      }
    };

    this.viewMapShowReferenceLayer.notifyGeoBoxMapApplyCondition = () => {
      this.AddGeoBoxCondition();
    };
   
  }

  
  constructor(http: HttpClient, private router: Router, private alertService: AlertService, private viewMapService: ViewMapService, private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.viewMapShowReferenceLayer.close();
    this.viewGeographicalBoxWindow.close();
  }

 

  open() {

    this.viewGeographicalBoxWindow.open();
    if (this.geoBoxMaplayers == undefined) {
      this.view = new OlView({
        center: fromLonLat([5.34, 63.85]),
        zoom: 3,
      });

      this.geoBoxMaplayers = [];
      var source = new OlXYZ({
        //url: '//server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}',
        url: '//server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous'
      });
      var layer = new OlTileLayer({
        source: source,
      });
      this.geoBoxMaplayers.push(layer);

      var referenceLayer = new OlTileLayer({
        source: new OlXYZ({
          url: '//server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}',
          crossOrigin: 'Anonymous'
        })
      });

      this.geoBoxMaplayers.push(referenceLayer);

      this.source = new VectorSource({ wrapX: false });
      var vector = new OlVectorLayer({
        source: this.source
      });
      this.geoBoxMaplayers.push(vector);

      for (var i = 0; i < this.totalExternalLayer; i++) {
        this.externalSource.push(new WMS({
          serverType: "geoserver",
          transition: 0
        }));
        var layer1 = new OlTileLayer({
          source: this.externalSource[i]
        });
        this.geoBoxMaplayers.push(layer1);
      }

      this.geoBoxMap = new OlMap({
        target: 'geoBoxMap',
        layers: this.geoBoxMaplayers,
        view: this.view,
      });

      this.geoBoxMap.updateSize();

      this.cdr.detectChanges();
    }
    
  }

  mapPointerMove(event: any) {
    var extent = this.geoBoxMap.getView().calculateExtent(this.geoBoxMap.getSize());
    var bottomLeft = transform(getBottomLeft(extent), get("EPSG:3857"), 'EPSG:4326');
    var topRight = transform(getTopRight(extent), get("EPSG:3857"), 'EPSG:4326');
    var pixel = this.geoBoxMap.getEventPixel(event);
    this.geoBoxMap.updateSize();
  }

  close() {
    this.viewGeographicalBoxWindow.close();
    this.viewMapShowReferenceLayer.close();
  }
  
  getWidth(): any {
    return '99%';
  }

  drawRectangle() {
    this.clearReferenceLayers();
    this.geoBoxMap.removeInteraction(this.draw);
    this.draw = new Draw({
      source: this.source,
      type: 'Circle',
      geometryFunction: createBox()
    });
    this.geoBoxMap.addInteraction(this.draw);
  }

  drawPolygon() {
    this.clearReferenceLayers();
    this.geoBoxMap.removeInteraction(this.draw);
    this.draw = new Draw({
      source: this.source,
      type: 'Polygon'
    });
    this.geoBoxMap.addInteraction(this.draw);
  }

  clearReferenceLayers() {
    if (this.mapLayers.length > 0) {
      this.referenceLayerConditions = '';
      this.referenceLayerConditionsText = '';
      this.mapLayers = [];
      this.clearMap();
    }
  }

  clearMap() {
    if (this.draw == undefined && this.source == undefined )
      return;
    this.geoBoxMap.removeInteraction(this.draw);
    this.source.clear();
    this.viewMapShowReferenceLayer.referenceLayerTreeClear();
    for (var i = 0; i < this.totalExternalLayer; i++) {
      this.geoBoxMaplayers[i + 3].setVisible(false);
    }
    this.externalLayerIndex = 0;
    this.clearReferenceLayers();
    this.wktGeometryConditions = '';
    this.referenceLayerConditions = '';
    this.referenceLayerConditionsText = '';
  }

  showReferenceLayers(event: any) {
    this.viewMapShowReferenceLayer.viewMapShowReferenceLayerWindow.position({ x: (event.clientX - Number(this.viewMapShowReferenceLayer.viewMapShowReferenceLayerWindow.attrWidth) - 10), y: event.clientY - 10 });
    this.viewMapShowReferenceLayer.mode = 1;
    this.viewMapShowReferenceLayer.open();
  }

  AddGeoBoxCondition() {

    this.referenceLayerConditions = '';
    this.wktGeometryConditions = '';
    this.referenceLayerConditionsText = '';
    if (this.source != undefined) {
      var features = this.source.getFeatures();
      if (features.length > 0) {
        var geometry = this.wkt.writeFeatures(features, {
          rightHanded: true,
          decimals: 4
        });

        var f = this.wkt.readFeatures(geometry);
        for (var i = 0; i < f.length; i++)
          f[i].getGeometry().transform("EPSG:3857", "EPSG:4326");

        this.wktGeometryConditions = this.wkt.writeFeatures(f, {
          rightHanded: true,
          decimals: 4
        });
      }
      else
        this.wktGeometryConditions = '';
    }

    if (this.mapLayers.length > 0) {
      var list = this.mapLayers.filter(s => s.visible == true);
      for (var i = 0; i < list.length; i++) {
        this.referenceLayerConditions = this.referenceLayerConditions + list[i].mapExternalLayers[0].ids[0] + ' or ';
        this.referenceLayerConditionsText = this.referenceLayerConditionsText + list[i].mapExternalLayers[0].ids[1] + ' or ';
        var featureName = list[i].mapExternalLayers[0].ids[1].split('=')[0];
        this.viewMapService.getfeatureGeometry(featureName, list[i].mapExternalLayers[0].ids[1].split('=')[1]).subscribe(
          result => {
            var s = result;
            if (this.referenceLayerConditionsExternalLayer != "")
              this.referenceLayerConditionsExternalLayer = this.referenceLayerConditionsExternalLayer + ',' + s;
            else
              this.referenceLayerConditionsExternalLayer = s;
          },
          error => {
            this.alertService.showMessage('Unable to getfeatureGeometry', Core.getHttpResponseMessage('', error), MessageSeverity.error);
          }
        );
      }
      this.referenceLayerConditions = this.referenceLayerConditions.substring(0, this.referenceLayerConditions.length - 4);
      this.referenceLayerConditionsText = this.referenceLayerConditionsText.substring(0, this.referenceLayerConditionsText.length - 4);
    }
    this.notifyAddGeoConditions();
    this.close();
  }

  cancel() {
    this.close();
  }
}
