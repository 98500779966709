import { NgModule } from '@angular/core'
import { Router, RouterModule, Routes } from '@angular/router'

import { HomeComponent } from './home/home.component';
import { ManagementComponent } from './management/management.component';
import { ViewMapComponent } from './view-map/view-map.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DownloadsComponent } from './downloads/downloads.component';
import { DownloadManagementComponent } from './download-management/download-management.component';


const routes: Routes = [
  { path: '', redirectTo: '/view-map', pathMatch: 'full' },
  { path: 'home', redirectTo: '/view-map', pathMatch: 'full' },
  { path: 'management', component: ManagementComponent, canActivate: [AuthGuardService], data: { title: 'Management' } },
  { path: 'view-map', component: ViewMapComponent, data: { title: 'view-map' } },
  { path: 'downloads', component: DownloadsComponent, data: { title: 'downloads' } },
  { path: 'download-management', component: DownloadManagementComponent, canActivate: [AuthGuardService], data: { title: 'download-management' } },
  { path: 'login', component: LoginComponent, data: { title: 'login' } },
  { path: '404', component: NotFoundComponent, data: { title: 'Page Not Found' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
