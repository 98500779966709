<jqxWindow #dateConditionWindow id="dateConditionWindow" [width]="400" [height]="280" [resizable]="true" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
  <div #windowHeader>
    <span id="captureContainer" style="float: left">Date condition</span>
  </div>
  <div #windowContent style="overflow: hidden; float:left">
    <form class="form-inline" style="height:100%; width:100%;" #dateConditionForm="ngForm" name="dateConditionForm" (ngSubmit)="AddDateCondition()">
      <div class="form-group row" >
        <div class="container" style="float:left; width:400px; height:90%; margin-top: 5px;">
          <div class="form-row">
            <div class="col" style="float:left; width:150px; margin-top: 5px;margin-left:30px;">
              <label for="startYear">Start year:</label>
              <input appAutofocus type="text" #startYear class="form-control input-sm" id="startYear" name="startYear" style="width:55px; float:right;" minlength="4" maxlength="4" pattern="([1-2][0-9][0-9][0-9])" placeholder="yyyy" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': startYear.valid, 'glyphicon-remove' : !startYear.valid}"></div>-->
              <div *ngIf="startYear.touched && startYear.invalid" class="errorMessage">
                Start year is not valid
              </div>
            </div>
            <div class="col" style="float:left; width:150px; margin-top: 5px;margin-left:30px;">
              <label for="startYear">End year:</label>
              <input appAutofocus #endYear type="text" class="form-control input-sm" id="endYear" name="endYear" style="width:55px;float:right;" minlength="4" maxlength="4" pattern="([1-2][0-9][0-9][0-9])" placeholder="yyyy" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': endYear.valid, 'glyphicon-remove' : !endYear.valid}"></div>-->
              <div *ngIf="endYear.touched && endYear.invalid" class="errorMessage">
                End year is not valid
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 edit-separator-hr">
            </div>
          </div>

          <div class="form-row">
            <div class="col" style="float:left; width:150px; margin-top: 5px;margin-left:30px;">
              <label for="startMonth">Start month:</label>
              <input appAutofocus #startMonth type="text" class="form-control input-sm" id="startMonth" name="startMonth" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="(0[1-9]|1[0-2])" placeholder="MM" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': startMonth.valid, 'glyphicon-remove' : !startMonth.valid}"></div>-->
              <div *ngIf="startMonth.touched && startMonth.invalid" class="errorMessage">
                Start month is not valid
              </div>
            </div>
            <div class="col" style="float: left; width: 150px; margin-top: 5px; margin-left: 30px;">
              <label for="endMonth">End month:</label>
              <input appAutofocus #endMonth type="text" class="form-control input-sm" id="endMonth" name="endMonth" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="(0[1-9]|1[0-2])" placeholder="MM" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': endMonth.valid, 'glyphicon-remove' : !endMonth.valid}"></div>-->
              <div *ngIf="endMonth.touched && endMonth.invalid" class="errorMessage">
                End month is not valid
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 edit-separator-hr">
            </div>
          </div>

          <div class="form-row">
            <div class="col" style="float:left; width:150px; margin-top: 5px;margin-left:30px;">
              <label for="startDay">Start day:</label>
              <input appAutofocus #startDay type="text" class="form-control input-sm" id="startDay" name="startDay" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="(0[1-9]|1[0-9]|2[0-9]|3[0-1])" placeholder="dd" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': startDay.valid, 'glyphicon-remove' : !startDay.valid}"></div>-->
              <div *ngIf="startDay.touched && startDay.invalid" class="errorMessage">
                Start day is not valid
              </div>
            </div>
            <div class="col" style="float: left; width: 150px; margin-top: 5px; margin-left: 30px;">
              <label for="endDay">End day:</label>
              <input appAutofocus #endDay type="text" class="form-control input-sm" id="endDay" name="endDay" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="(0[1-9]|1[0-9]|2[0-9]|3[0-1])" placeholder="dd" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': endDay.valid, 'glyphicon-remove' : !endDay.valid}"></div>-->
              <div *ngIf="endDay.touched && endDay.invalid" class="errorMessage">
                End day is not valid
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 edit-separator-hr">
            </div>
          </div>

          <div class="form-row">
            <div class="col" style="float:left; width:150px; margin-top: 5px;margin-left:30px;">
              <label for="startHour">Start hour:</label>
              <input appAutofocus #startHour type="text" class="form-control input-sm" id="startHour" name="startHour" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="([0-1][0-9]|2[0-3])" placeholder="hh" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': startHour.valid, 'glyphicon-remove' : !startHour.valid}"></div>-->
              <div *ngIf="startHour.touched && startHour.invalid" class="errorMessage">
                Start hour is not valid
              </div>
            </div>
            <div class="col" style="float: left; width: 150px; margin-top: 5px; margin-left: 30px;">
              <label for="endHour">End hour:</label>
              <input appAutofocus #endHour type="text" class="form-control input-sm" id="endHour" name="endHour" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="([0-1][0-9]|2[0-3])" placeholder="hh" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': endHour.valid, 'glyphicon-remove' : !endHour.valid}"></div>-->
              <div *ngIf="endHour.touched && endHour.invalid" class="errorMessage">
                End hour is not valid
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 edit-separator-hr">
            </div>
          </div>

          <div class="form-row">
            <div class="col" style="float:left; width:150px; margin-top: 5px;margin-left:30px;">
              <label for="startMinute">Start minute:</label>
              <input appAutofocus #startMinute type="text" class="form-control input-sm" id="startMinute" name="startMinute" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="([0-5][0-9])" placeholder="mm" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': startMinute.valid, 'glyphicon-remove' : !startMinute.valid}"></div>-->
              <div *ngIf="startMinute.touched && startMinute.invalid" class="errorMessage">
                Start minute is not valid
              </div>
            </div>
            <div class="col" style="float: left; width: 150px; margin-top: 5px; margin-left: 30px;">
              <label for="endMinute">End minute:</label>
              <input appAutofocus #endMinute type="text" class="form-control input-sm" id="endMinute" name="endMinute" style="width:55px;float:right;" minlength="2" maxlength="2" pattern="([0-5][0-9])" placeholder="mm" />
              <!--<div *ngIf="dateConditionForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': endMinute.valid, 'glyphicon-remove' : !endMinute.valid}"></div>-->
              <div *ngIf="endMinute.touched && endMinute.invalid" class="errorMessage">
                End minute is not valid
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 edit-separator-hr">
            </div>
          </div>


          <div class="form-group row" style="float: left; width: 360px;   margin-top:10px; height: 40px;">
            <div class="col-sm-2 pull-left">
              <button type="submit" class="btn btn-primary btn-sm">Ok</button>
            </div>
            <div class="col-sm-1 pull-right">
              <button type="button" class="btn btn-primary btn-sm" (click)="cancel()">Cancel</button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</jqxWindow>
