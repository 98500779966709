import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { jqxWindowModule }  from "jqwidgets-ng/jqxwindow";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { ViewMapService } from "../../services/view-map.service";
import { Core } from "../../shared/core";
import { AuthService } from '../../services/auth.service';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';



@Component({
  selector: 'app-viewMap-disclaimer',
  templateUrl: './viewMap-disclaimer.component.html',
  styleUrls: ['./viewMap-disclaimer.component.css']
})


export class ViewMapDisclaimerComponent implements AfterViewInit, OnInit {
  @ViewChild('viewMapDisclaimer') viewMapDisclaimer: jqxWindowComponent;
  baseURL: any;
  ngOnInit() {
  }

  getStyle() {

  }

  ngAfterViewInit(): void {
  }

  constructor(private authService: AuthService, @Inject('BASE_URL') url: string, http: HttpClient, private router: Router, private alertService: AlertService, private viewMapService: ViewMapService) {
    this.baseURL = url;
  }

  public notifyDownload: () => void;

  open() {
    //this.viewMapDisclaimer.position({ x: 400, y: 400 });
      this.viewMapDisclaimer.open();
  }

  close() {
    this.viewMapDisclaimer.hide();
  }

  StartDownload() {
    this.notifyDownload();
    this.close();
  }

  showAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  getWidth(): any {
    return '99%';
  }
}
