import { Injectable, Inject } from '@angular/core'
import { IUser } from '../models/account/user.model'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, Route, UrlSerializer } from '@angular/router'
import { UserLogin } from '../models/account/user-login.model';
import { LoginResponse } from '../models/account/login-response.model';
import { LocalStorageService } from './local-storage.service';
import { Const } from './Const';
import { AlertService, MessageSeverity } from './alert.service';
import { Core } from '../shared/core';


//import { CommonFactory } from './common-factory.service';

@Injectable()

export class AuthService {
  currentUser: IUser;
  

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private alertService: AlertService)
  {
  }

  loginUser(userName: string, password: string, rememberMe?: boolean) {
    return this.getLoginEndpoint<LoginResponse>(userName, password, rememberMe).pipe(
      map(response => this.processLoginResponse(response, rememberMe))
      );
  }

  logOff() {
    if (!!this.currentUser) {
      this.getLogOffEndpoint().subscribe();
      this.alertService.showMessage('Logout', `${this.currentUser.userName} logged out`, MessageSeverity.information);
      this.localStorageService.removeData(Const.CurrentUser);
    }
 }

  private getLoginEndpoint<LoginResponse>(userName: string, password: string, rememberMe?: boolean): Observable<LoginResponse> {
    const params = new HttpParams()
      .append('username', userName)
      .append('password', password);
    const requestBody = params.toString();

    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return this.http.post<LoginResponse>('/account/login', requestBody, options);
  }

  private processLoginResponse(response: LoginResponse, rememberMe?: boolean) {
     this.currentUser = {
         userName: response.userName,
         password: response.password,
         roles: [],
         email: response.email,
         userImage: "/UserImages/" + response.userName + ".png"
     }
    this.localStorageService.saveData(Const.CurrentUser, this.currentUser);
    this.localStorageService.saveData(Const.RememberMe, rememberMe);
   return this.currentUser;
  }

  private getLogOffEndpoint(){
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    return this.http.post('/account/LogOff', null, options);
  }


  //private handleError(error: Response | any) {
  //  let errMsg: string;
  //  if (error instanceof Response) {
  //    const body = error.json() || '';
  //    const err = body.toString() || JSON.stringify(body);
  //    errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  //  } else {
  //    errMsg = error.message ? error.message : error.toString();
  //  }
  //  console.error(errMsg);
  //  return Observable.throw(errMsg);
  //}

  isAuthenticated() {
    this.currentUser = this.localStorageService.getData<IUser>(Const.CurrentUser);
    return !!this.currentUser;
  }

  get rememberMe(): boolean {
    return this.localStorageService.getData<boolean>(Const.RememberMe)== true;
  }
}

