<form class="form-horizontal" #viewMap="ngForm">
  <div class="container" style="float:left; width: 100%; height:1100px;">
    <!--<div style="float:left; height:25px;">
    <a href="javascript:;" (click)="ShowSearch()">
      Add layer(s) to the map
    </a>
  </div>-->
    <!--<jqxLayout [width]="getWidth()" [height]="1100" style="float:left" [layout]="layout">
    <div data-container="MapPanel">
      <div #map id="map" style="float:left; width: 100%; height: 100%; position:relative;">
        <div #tooltip id="tooltip" class="tooltip"></div>
        <div #profilertooltip id="profilertooltip" class="profilertooltip">Click to view attributes of event(s)</div>
        <div class="ol-custom-right ol-unselectable ol-control">
          <button #download style="width: 210px; height: 40px;font-size: 14px;font-weight: normal;" id="download" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" title="Download data"><i class="glyphicon glyphicon-download-alt" style="margin-right: 10px;" aria-hidden="true"></i>Download data</button>
          <button #showReferenceLayers style="width: 210px; height: 40px;font-size: 14px;font-weight: normal;" id="showReferenceLayers" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" title="Show Reference Layers"><i class="glyphicon glyphicon-th-list" style="margin-right: 10px;" aria-hidden="true"></i>Show Reference Layers</button>
        </div>
        <div class="ol-custom-left ol-unselectable ol-control">
          <button #searchbutton style="width: 250px;height: 40px;font-size: 14px;font-weight: normal;" id="searchbutton" class="btn btn-primary btn-sm" type="button" title="Add layer(s) to the map"><i class="glyphicon glyphicon-search" style="margin-right: 10px;" aria-hidden="true"></i>Add/Edit layer(s) to the map</button>
        </div>
      </div>
    </div>
    <div data-container="LayerPanel" style="overflow-x: auto;">
      <div id="layerGrid" style="float:left; width: 100%; height: 100%; position:relative; overflow-x: scroll;">
      </div>
    </div>
  </jqxLayout>-->

    <div style="float:left; width: 100%; height: 63%; display: table;">
      <div #map id="map" style="float: left; width: 100%; height: 100%;position:relative; ">
        <div #tooltip id="tooltip" class="tooltip"></div>
        <div #profilertooltip id="profilertooltip" class="profilertooltip">Click to view attributes of event(s)</div>
        <div class="ol-custom-right ol-unselectable ol-control">
          <button #download style="width: 210px; height: 40px;font-size: 14px;font-weight: normal;" id="download" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" title="Download data"><i class="glyphicon glyphicon-download-alt" style="margin-right: 10px;" aria-hidden="true"></i>Download data</button>
          <button #showReferenceLayers style="width: 210px; height: 40px;font-size: 14px;font-weight: normal;" id="showReferenceLayers" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" title="Show Reference Layers"><i class="glyphicon glyphicon-th-list" style="margin-right: 10px;" aria-hidden="true"></i>Show Reference Layers</button>
        </div>
        <div class="ol-custom-left ol-unselectable ol-control">
          <button #searchbutton style="width: 250px;height: 40px;font-size: 14px;font-weight: normal;" id="searchbutton" class="btn btn-primary btn-sm" type="button" title="Add layer(s) to the map"><i class="glyphicon glyphicon-search" style="margin-right: 10px;" aria-hidden="true"></i>Add/Edit layer(s) to the map</button>
        </div>
      </div>
    </div>

    <div style="float: left; width: 100%; height: 35%; display: table; position: relative;">
      <div id="layerGrid" style="float: left; width: 100%; height: 100%; overflow-x: scroll;">
      </div>
    </div>

    <div class="form-group row">
      <jqxWindow #widget id="widget" [width]="370" [height]="220" [resizable]="true" [isModal]="false" [autoOpen]="false">
        <div #windowHeader>
          <span id="captureContainer" style="float: left">Legend</span>
        </div>
        <div #windowContent>
          <div id="legend" style="display:none;">
          </div>
          <!--<jqxTree #jqxWidgetTree (onInitialized)="Initialized($event)" (onCheckChange)="CheckChange($event)" id="jqxWidgetTree" [source]="records" [width]="'100%'" [height]="'100%'" [hasThreeStates]="true" [checkboxes]="true"> </jqxTree>-->
        </div>
      </jqxWindow>
    </div>

    <div class="form-group row">
      <jqxWindow #widgetDataCriteria id="widgetDataCriteria" [width]="370" [height]="220" [resizable]="false" [isModal]="false" [autoOpen]="false">
        <div #windowHeader>
          <span id="captureContainer" style="float: left">Search Criteria</span>
        </div>
        <div #windowContent style="overflow: hidden;">
          <jqxTree #jqxWidgetTree (onInitialized)="Initialized($event)" id="jqxWidgetTree" [source]="records" [width]="'100%'" [height]="'100%'"> </jqxTree>
        </div>
      </jqxWindow>
    </div>

    <div>
      <app-viewMap-search #viewMapSearch> </app-viewMap-search>
    </div>
    <div>
      <app-viewMap-profile #viewMapProfile> </app-viewMap-profile>
    </div>
    <div>
      <app-viewMap-disclaimer #viewMapDisclaimer> </app-viewMap-disclaimer>
    </div>
    <div>
      <app-viewMap-showReferenceLayer #viewMapShowReferenceLayer> </app-viewMap-showReferenceLayer>
    </div>
  </div>
</form>


