import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { map, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Core } from '../shared/core';
import * as FileSaver from 'file-saver';
import { FormatType } from '../models/formatType.model';
import { promise } from 'selenium-webdriver';
import { MapCondition } from '../models/mapCondition.model';

@Injectable()
export class ViewMapService {
  baseURL: any;
  constructor(protected http: HttpClient, @Inject('BASE_URL') url: string, protected router: Router) {
    this.baseURL = url;
    
  }

  getSummaryData(formatTypeID: number, condition: string, zoom: number, left: number, right: number, bottom: number, top: number) {
    return this.getSummaryDataEndPoint(formatTypeID, condition, zoom, left, right, bottom, top);
  }

  getSummaryDataEndPoint(formatTypeID: number, condition: string, zoom: number, left:number, right: number, bottom: number, top: number) {
    var mapCondition = {
      FormatTypeID: formatTypeID,
      Condition: condition
    };

    let params: HttpParams = new HttpParams();
    params = params.set('zoom', zoom.toString());
    params = params.set('left', left.toString());
    params = params.set('right', right.toString());
    params = params.set('bottom', bottom.toString());
    params = params.set('top', top.toString());

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; application/zip; charset=utf-8'
      }),
      params
    };

    return this.http.post(this.baseURL + 'ViewMap/GetSummaryData', Core.tryJsonStringify(mapCondition), options);
  }

  getSummaryDataProfile(conditionArray: string[]) {
    return this.getSummaryDataProfileEndPoint(conditionArray);
  }

  getSummaryDataProfileEndPoint(conditionArray: string[]) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    var ids: string = "";
    for (let entry of conditionArray) {
      ids += entry + ",";
    }
    ids = ids.substring(0, ids.length - 1);
    var mapCondition = {
      FormatTypeID: 0,
      Condition: ids
    };

    return this.http.post(this.baseURL + 'ViewMap/GetSummaryDataProfile', Core.tryJsonStringify(mapCondition), options);
  }

  getJsonSchema() {
    return this.getJsonSchemaEndPoint();
  }

  getJsonSchemaEndPoint() {
    return this.http.get(this.baseURL + 'ViewMap/GetJsonSchema');
  }


  processJsonSchemaResponse(schema: any): any {
    let source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'title', type: 'string' },
        { name: 'codeType', type: 'string' },
        { name: 'formatTypeID', type: 'number' }
      ],
      localdata: schema
    };
    return source;
  }

  getDownloadGUID(conditionArray: MapCondition[]) {
    return this.getDownloadGUIDEndPoint(conditionArray);
  }

  getDownloadGUIDEndPoint(conditionArray: MapCondition[]) {
    var mapConditions = [];
    for (let entry of conditionArray) {
      var mapCondition = {
        FormatTypeID: entry.formatTypeID,
        Condition: entry.ids[0],
        ConditionText: entry.conditionText,
        DataCriteriaCondition: entry.dataCriteriaCondition
      };
      mapConditions.push(mapCondition);
    }

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; application/zip; charset=utf-8'
      })
    };

    return this.http.post(this.baseURL + 'ViewMap/GetDownloadGUID', Core.tryJsonStringify(mapConditions), options);
  }

  getDownloadOroginalData(conditionArray: MapCondition[], guid: string) {
    return this.getDownloadOroginalDataEndPoint(conditionArray, guid);
  }


  getDownloadOroginalDataEndPoint(conditionArray: MapCondition[], guid: string) {
    var mapConditions = [];
    for (let entry of conditionArray) {
      var mapCondition = {
        FormatTypeID: entry.formatTypeID,
        Condition: entry.ids[0]
        };
        mapConditions.push(mapCondition);
    }

    let params: HttpParams = new HttpParams();
    params = params.set('guid', guid.toString());

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; application/zip; charset=utf-8'
      }),
      params
    };

    return this.http.post(this.baseURL + 'ViewMap/DownloadOriginalData', Core.tryJsonStringify(mapConditions), options);
  }


  getTagView() {
    return this.getTagViewEndPoint();
  }

  getTagViewEndPoint() {
    return this.http.get(this.baseURL + 'ViewMap/GetTagView');
  }

  getThemeView() {
    return this.getThemeViewEndPoint();
  }

  getThemeViewEndPoint() {
    return this.http.get(this.baseURL + 'ViewMap/GetThemeView');
  }

  getDatasetInventory() {
    return this.getDatasetInventoryEndPoint();
  }

  getDatasetInventoryEndPoint() {
    return this.http.get(this.baseURL + 'ViewMap/GetDatasetInventory');
  }

  async getMapFeature(url: string) {
    return await this.getMapFeatureEndPoint(url);
  }

  async getMapFeatureEndPoint(url: string) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    var mapCondition = {
      FormatTypeID: 0,
      Condition: url
    };

    return await this.http.post(this.baseURL + 'ViewMap/GetMapFeature', Core.tryJsonStringify(mapCondition), options).toPromise();
  }

  getfeatureTypes() {
    return this.getfeatureTypesEndPoint();
  }

  getfeatureTypesEndPoint() {
    return this.http.get(this.baseURL + 'ViewMap/GetfeatureTypes');
  }

  getfeatureGeometry(featureType: string, value: string) {
    return this.getfeatureGeometryEndPoint(featureType, value);
  }

  getfeatureGeometryEndPoint(featureType: string, value: string) {
    return this.http.get(this.baseURL + 'ViewMap/GetFeatureGeometry/' + featureType + '/' + value, { responseType: 'text' });
  }
}
