import { MapExternalLayer } from './mapExternalLayer.model';

export class MapLayer {
  public formatTypeID: number;
  public datasetID: number;
  public layerIndex: number;
  public mainIndex: number;
  public visible: boolean;
  //0: point 1: polygon 2: external
  public sourceType: number;
  public legends: HTMLTableRowElement[] = [];
  public mapExternalLayers: MapExternalLayer[] = [];
}
