<ngx-toasta></ngx-toasta>
<div id="mainMenu" class="app-component collapse navbar-collapse menuItemsContainer" style="visibility:hidden;">
  <ul class="app-component nav navbar-nav nav-pills navBarPadding">
    <li routerLinkActive="active"><a routerLink=""><span class="glyphicon glyphicon-home"></span></a></li>
    <li routerLinkActive="active"><a routerLink="/management">Admin</a></li>
    <li routerLinkActive="active"><a routerLink="/view-map">View On Map</a></li>
    <li routerLinkActive="active"><a routerLink="/download-management">Downloads Managemnet</a></li>
    <li routerLinkActive="active" *ngIf="authService.isAuthenticated()"><a (click)="logout()" style="float:left;padding-right:5px;cursor:pointer;"><span class="glyphicon glyphicon-log-out"></span>Logout</a></li>
    <li routerLinkActive="active" *ngIf="!authService.isAuthenticated()"><a [routerLink]="['/login']"><span class="glyphicon glyphicon-lock"></span>Login</a></li>
  </ul>
  <p class="app-component navbar-text navbar-left notifications-popup" *ngIf="authService.isAuthenticated()">
    <img src={{authService.currentUser.userImage}} class="roundedcorners" style="float:left;" />
    <span style="float:left;padding-left:5px;">
      Welcome {{authService.currentUser.userName}}
    </span>
  </p>
</div>
<router-outlet></router-outlet>


