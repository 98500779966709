<div id="container" class="container" style="float:left; width: 100%; height:800px;">
  <div style="float:none; width: 80%; height:100%; margin-left:auto; margin-right:auto;">
    <h1>Download list</h1>
    <h2>
      Here is the list of dataset(s) you have selected to download.
      If you have any problems with downloading, please contact <a href="mailto:accessions@ices.dk">accessions&#64;ices.dk</a>
    </h2>
    <div>
      <div class="form-group row" style="float:left; width:100%; height:5%; margin-left: 1px;">
        <div class="row">
          <div id="downloadAllbtn" style="visibility:visible;" class="col-sm-1 pull-left">
            <div ngPreserveWhitespaces class="pull-left">
              <button class="btn btn-primary btn-sm" (click)="downloadAll()">Download All File(s)</button>
            </div>
          </div>
          <div id="sendNotificationbtn" style="visibility: visible;" class="col-sm-1 pull-right">
            <div ngPreserveWhitespaces class="pull-right">
              <button class="btn btn-primary btn-sm" (click)="showSendNotificationWindow()">Send notification when all download files are ready</button>
            </div>
          </div>

        </div>
      </div>

      <jqxGrid #downloadGrid [source]="dataAdapter" [columns]="columns" [columnsautoresize]="true" (onCellclick)="Cellclick($event)" [width]="getWidth()"
               [pageable]="true" [autoheight]="true" [sortable]="true"
               [altrows]="true" [enabletooltips]="true" [editable]="false"
               [selectionmode]="'singlecell'" [pagesize]="30">
      </jqxGrid>
    </div>
  </div>
</div>
<div>
  <jqxWindow #sendNotificationWindow [width]="800" [height]="230" [title]="'Send Notification'" [animationType]='"fade"' [resizable]="false" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
    <div style="overflow: hidden;">
      <form class="form-inline" style="height:100%; width:100%; margin-left: 15px; " name="viewMapDisclaimerForm" #viewMapDisclaimerForm="ngForm"
            novalidate (ngSubmit)="sendNotification()">
        <div id="windowHeader" #windowHeader>
        </div>
        <div style="overflow: hidden;" #windowContent>
          <br />
          <div class="GeneralLabel">If you would like to receive notification by email when the download is ready, please enter your email address below.</div>
          <br />
          <div class="GeneralLabel">The download link will be active for 7 days and your email address will not be stored by this website beyond that period.</div>
          <div class="form-group ">
            <br />
            <div class="col-md-8">
              <label class="control-label col-md-4">Email Address: </label>
              <div class="col-md-5">
                <input appAutofocus type="email" ngModel email #email="ngModel" [(ngModel)]="emailAddress" class="form-control input-sm" id="email" name="email" minlength="2" maxlength="200" placeholder="Enter Email" />
              </div>
            </div>
            <!--<div class="col-md-12">
      <div class="col-md-1">
        <input appAutofocus type="checkbox" ngModel #emailStore="ngModel" id="emailStore" name="emailStore"
               class="form-control " />
      </div>
      <label class="control-lab col-md-10" for="emailStore" style="margin-left:-40px; margin-top:12px;">I’d like to receive notification by email and accept to store the email at ICES Dataportal</label>
    </div>-->
          </div>
          <br />
          <br />
          <div>
            <div class="col-sm-0 pull-left">
              <div ngPreserveWhitespaces class="pull-left">
                <button class="btn btn-primary btn-sm">Accept</button>
              </div>
            </div>
            <div class="col-sm-1 pull-right" style="margin-right:15px;">
              <div ngPreserveWhitespaces class="pull-right">
                <button type="reset" class="btn btn-primary btn-sm" (click)="closeSendNotificationWindow()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </jqxWindow>


</div>



