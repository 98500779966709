<div class="container" style="float:left; width: 100%;">
  <div class="content-container" style="float:left; width: 15%;">
      <jqxMenu #menu [width]="200" [mode]="'vertical'">
        <ul>
          <li><a href="#FormatType">Format type</a></li>
          <li>
            Users
            <ul>
              <li><a href="#UserList">User list</a></li>
            </ul>
          </li>
        </ul>
      </jqxMenu>
    </div>
    <div class="content-container" style="float:left; width: 85%;">
      <app-formatType-management></app-formatType-management>
    </div>
  </div>



