export class TreeMapViewModel 
{
  public label: string;
  public value: number;
  public color: string;
  public data: TreeMapDataModel;
}

export class TreeMapDataModel  {
  constructor() {
    this.firstYear = 3000;
    this.lastYear = 0;
    this.totalYears = 0;
    this.totalEvents = 0;
    this.link = '';
    
  }
  public firstYear: number;
  public lastYear: number;
  public totalYears: number;
  public totalEvents: number;
  public link: string;
}
