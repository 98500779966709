import { Component, OnInit, Injectable, Inject, ViewChild, AfterViewInit } from "@angular/core";
import { AlertService, MessageSeverity, MessageType } from "../../services/alert.service";
import { AuthService } from "../../services/auth.service";
import { HttpClient } from "@angular/common/http";
import { Route, Router } from "@angular/router";
import { FormatTypeEditorComponent } from '../formatType/formatType-editor.component';
import { FormatTypeService } from '../../services/formatType.service';
import { Core } from "../../shared/core";
import { FormatType } from "../../models/formatType.model";
import { jqxGridComponent } from 'jqwidgets-ng/jqxGrid';




@Component({
  selector: 'app-formatType-management',
  templateUrl: './formatType-management.component.html',
  styleUrls: ['./formatType-management.component.css']
})


@Injectable()
export class FormatTypeManagementComponent implements OnInit, AfterViewInit{
  dataAdapter: any;
  formatTypes: any;
  formatType: FormatType;
  columns: any[];
  private editRow: number = -1;

  @ViewChild('FormatTypeEditor')
  FormatTypeEditor: FormatTypeEditorComponent;

  @ViewChild("formatTypeGrid")
  formatTypeGrid: jqxGridComponent;


  ngOnInit() {
  }
  
  constructor(private router: Router, private formatTypeService: FormatTypeService, private alertService: AlertService) {
    this.alertService.startLoadingMessage();
    this.formatTypeService.getFormatTypes().subscribe(
      formatType => {
        this.formatTypes = formatType;
        this.dataAdapter = new jqx.dataAdapter(this.formatTypes);
        this.columns =
          [
            { text: 'ID', datafield: 'id', hidden: true  },
            { text: 'Name', datafield: 'name', width: 150 },
            { text: 'Active', datafield: 'active', width: 50 },
            { text: 'Status', datafield: 'formatTypeStatus', width: 150 },
            { text: 'Theme', datafield: 'theme', width: 350 },
            { text: 'ThemeID', datafield: 'themeID', hidden: true },
            { text: 'LoopLimit', datafield: 'loopLimit', width: 100 },
            { text: 'ContactEmail', datafield: 'contactEmail', width: 150 },
            { text: 'Comment', datafield: 'comment', width: 150 },
            {
            text: 'Edit', datafield: 'edit', width: 50, columntype: 'button',
              cellsrenderer: () : string =>
                { return 'Edit'; },
              buttonclick: (row: number): void => {
                this.editRow = row;
                this.formatType = this.FormatTypeEditor.editFormatType(this.formatTypeGrid.getrowdata(row));
                this.FormatTypeEditor.open();
              }
          },
          {
            text: 'Delete', datafield: 'delete', width: 50, columntype: 'button',
            cellsrenderer: (): string => { return 'Delete'; },
            buttonclick: (row: number): void => {
              this.formatType = this.formatTypeGrid.getrowdata(row);
              this.alertService.showDialog('Are you sure you want to delete \"' + this.formatType.name + '\"?', MessageType.confirm,
                () => {
                  this.editRow = row;
                  this.deleteFormatType(row);
                });

            }
          },
          {
            text: 'Co-ordinator', datafield: 'Co-ordinator', width: 100, columntype: 'button',
            cellsrenderer: (): string => { return 'Run'; },
            buttonclick: (row: number): void => {
              this.editRow = row;
             this.formatType = this.formatTypeGrid.getrowdata(row);
              this.runSynchronizer(row, false);
            }
          },
          {
            text: 'Co-ordinator', datafield: 'Co-ordinatorFirstLoad', width: 150, columntype: 'button',
            cellsrenderer: (): string => { return 'Run First Load'; },
            buttonclick: (row: number): void => {
              this.editRow = row;
              this.formatType = this.formatTypeGrid.getrowdata(row);
              this.runSynchronizer(row, true);
            }
          }
          ];
      },
      error => {
        this.alertService.showMessage('Unable to load format type', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );
    this.alertService.stopLoadingMessage();
  }

  ngAfterViewInit() {
    var mainMenu = document.getElementById('mainMenu');
    mainMenu.setAttribute("style", "visibility:visible;");

    this.FormatTypeEditor.notifySaveChanges = () => {
        this.updateGrid();
    };
  }

  deleteFormatType(row: number) {
    this.alertService.startLoadingMessage('Deleting...');
    this.formatType = this.formatTypeGrid.getrowdata(row);
    this.formatTypeService.deleteFormatType(this.formatType).subscribe(
      formatType => {
        this.alertService.showMessage('Success', `Format Type \"${this.formatType.name}\" was deleted successfully`, MessageSeverity.success);
        let rowID = this.formatTypeGrid.getrowid(row);
        this.formatTypeGrid.deleterow(rowID);
      },
      error => {
        this.alertService.showMessage('Unable to delete format type', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );
    this.alertService.stopLoadingMessage();
  }

  updateGrid() {
    if (this.editRow == -1) {
      this.formatTypeGrid.addrow(null, this.formatType);
    }
    else {
      this.formatTypeGrid.updaterow(this.editRow, this.formatType);
    }

  }

  runSynchronizer(row: number, firstLoad: boolean) {

    this.formatTypeService.getSynchronizer(this.formatType, firstLoad).subscribe(
      formatType => {
        this.alertService.showRemainMessage('Success', `Co-ordinator \"${this.formatType.name}\" run successfully`, MessageSeverity.success);
        //this.formatType = formatType;
        //this.updateGrid();
      },
      error => {
        this.alertService.showRemainMessage('Unable to run Synchronizer', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );

  }

  syncAll() {
    let rows = this.formatTypeGrid.getrows();
    for (var i = 0; i < rows.length; i++) {
      this.formatType = this.formatTypeGrid.getrowdata(rows[i].uid);
      this.runSynchronizer(rows[i].uid, false);
    }
  }

  newFormatType() {
    this.editRow = -1;
    this.formatType = this.FormatTypeEditor.newFormatType();
    this.FormatTypeEditor.open();
  }

  getWidth(): any {
    return '100%';
  }
}




