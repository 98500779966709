import { Injectable } from "@angular/core";
import { Core } from "../shared/core";


@Injectable()
export class LocalStorageService {


  public clearLocalStorage() {
    localStorage.clear();
  }

  public saveData(key: string, data: any) {
    this.localStorageSet(key, data);
  }

  public removeData(key: string) {
    this.localStorageRemove(key);
  }

  public getData<T>(key: string) {
    return <T>this.localStorageGet(key);
  }



  private localStorageSet(key: string, data: any) {
    localStorage.setItem(key, Core.tryJsonStringify(data));
  }

  private localStorageGet(key: string) {
    return Core.tryJsonParse(localStorage.getItem(key));
  }

  private localStorageRemove(key: string) {
    localStorage.removeItem(key);
  }
}
