import { Injectable } from '@angular/core';
import { HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class Core {
  public static readonly messageSeparator = ':';
  public static readonly noNetwork = 'No Network';
  public static readonly noNetworkDetail = 'The server cannot be reached';
  public static readonly accessDenied = 'Access Denied!';
  public static readonly accessDeniedDetail = '';

  public static tryJsonStringify(object) {
    let result: string;

    try {
      result = JSON.stringify(object);
      return result;
    }
    catch (error) {

    }
    return result;
  }

  public static tryJsonParse(value: string) {
    try {
      return JSON.parse(value);
    }
    catch (error) {
      return void 0;      
    }
  }

  public static checkAccessDenied(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status == 403;
    }

    return false;
  }

  public static checkNoNetwork(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status == 0;
    }

    return false;
  }

  public static checkNotFound(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status == 404;
    }

    return false;
  }

  public static getResponseBody(response: HttpResponseBase) {
    if (response instanceof HttpResponse)
      return response.body;

    if (response instanceof HttpErrorResponse)
      return response.error || response.message || response.statusText;
  }

  public static split(text: string, separator: string): { firstPart: string, secondPart: string } {
    const separatorIndex = text.indexOf(separator);

    if (separatorIndex == -1)
      return { firstPart: text, secondPart: null };

    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }

  public static analyseHttpResponseMessage(data: HttpResponseBase | any): string[] {

    const responses: string[] = [];

    if (data instanceof HttpResponseBase) {

      if (this.checkNoNetwork(data)) {
        responses.push(`${this.noNetwork}${this.messageSeparator} ${this.noNetworkDetail}`);
      }
      else {
        const responseObject = this.getResponseBody(data);

        if (responseObject && (typeof responseObject === 'object' || responseObject instanceof Object)) {

          for (const key in responseObject) {
            if (key)
              responses.push(`${key}${this.messageSeparator} ${responseObject[key]}`);
            else if (responseObject[key])
              responses.push(responseObject[key].toString());
          }
        }
      }

      if (!responses.length && this.getResponseBody(data))
        responses.push(`${data.statusText}: ${this.getResponseBody(data).toString()}`);
    }

    if (!responses.length)
      responses.push(data.toString());

    if (this.checkAccessDenied(data))
      responses.splice(0, 0, `${this.accessDenied}${this.messageSeparator} ${this.accessDeniedDetail}`);


    return responses;
  }

  public static getHttpResponseMessage(messageToFind: string, data: HttpResponse<any> | any, seachInCaptionOnly = true,
    includeCaptionInResult = false): string {

    const searchString = messageToFind.toLowerCase();
    const httpMessages = this.analyseHttpResponseMessage(data);

    for (const message of httpMessages) {
      const fullMessage = Core.split(message, this.messageSeparator);

      if (fullMessage.firstPart && fullMessage.firstPart.toLowerCase().indexOf(searchString) != -1) {
        return includeCaptionInResult ? message : fullMessage.secondPart || fullMessage.firstPart;
      }
    }

    if (!seachInCaptionOnly) {
      for (const message of httpMessages) {

        if (message.toLowerCase().indexOf(searchString) != -1) {
          if (includeCaptionInResult) {
            return message;
          }
          else {
            const fullMessage = Core.split(message, this.messageSeparator);
            return fullMessage.secondPart || fullMessage.firstPart;
          }
        }
      }
    }

    return null;
  }


}
