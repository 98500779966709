export class FormatType {
  public id: number
  public name: string
  public active: boolean;
  public formatTypeStatus: string;
  public formatTypeStatusID: number;
  public theme: string;
  public themeID: number;
  public downloadType: string;
  public downloadTypeID: number;
  public loopLimit: number;
  public contactEmail: string;
  public comment: string;
  public mapStyle: string;
}



