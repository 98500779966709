import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormatType } from '../models/formatType.model';
import { Router } from '@angular/router';
import { Core } from '../shared/core';

@Injectable()
export class DownloadService {
  baseURL: any;
  constructor(protected http: HttpClient, @Inject('BASE_URL') url: string, protected router: Router) {
    this.baseURL = url;
  }


  getdownloads(guid: string) {
    return this.getdownloadsEndPoint(guid).pipe(
      map(response => this.processgetdownloadsResponse(response)));
  }

  getdownloadsEndPoint(guid: string) {
    return this.http.get(this.baseURL + 'Download/GetParts/' + guid);
  }

  processgetdownloadsResponse(downloads: any): any {
    let source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'guid', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'partGUID', type: 'string' },
        { name: 'downloadStatusID', type: 'integer' },
        { name: 'downloadStatus', type: 'string' },
        { name: 'formatTypeID', type: 'integer' },
        { name: 'theme', type: 'string' },
        { name: 'datasetID', type: 'integer' },
        { name: 'dataset', type: 'string' },
        { name: 'date', type: 'string' },
        { name: 'dataCriteria', type: 'string' }
      ],
      localdata: downloads
    };
    return source;

  }

  downloadFile(guid: string) {
    return this.downloadFileEndPoint(guid).pipe(
      map(response => this.processgetdownloadsResponse(response)));
  }

  downloadFileEndPoint(guid: string) {
    return this.http.get(this.baseURL + 'Download/' + guid);
  }

  

  sendNotification(guid: string, emailAddress: string) {
    return this.sendNotificationEndPoint(guid, emailAddress).pipe(
      map(response => this.processgetdownloadsResponse(response)));
  }

  sendNotificationEndPoint(guid: string, emailAddress: string) {

    let params: HttpParams = new HttpParams();
    params = params.set('emailAddress', emailAddress.toString());

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; application/zip; charset=utf-8'
      }),
      params
    };

    return this.http.get(this.baseURL + 'Download/RegisterEmail/' + guid, options);

  }

  getalldownloadparts() {
    return this.getalldownloadpartsEndPoint().pipe(
      map(response => this.processgetalldownloadpartsResponse(response)));
  }

  getalldownloadpartsEndPoint() {
    return this.http.get(this.baseURL + 'Download/GetAllDownloadParts');
  }
  processgetalldownloadpartsResponse(downloads: any): any {
    let source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'guid', type: 'string' },
        { name: 'partGUID', type: 'string' },
        { name: 'downloadStatusID', type: 'integer' },
        { name: 'downloadStatus', type: 'string' },
        { name: 'formatTypeID', type: 'integer' },
        { name: 'theme', type: 'string' },
        { name: 'datasetID', type: 'integer' },
        { name: 'dataset', type: 'string' },
        { name: 'date', type: 'string' },
        { name: 'dataCriteria', type: 'string' }
      ],
      localdata: downloads
    };
    return source;

  }

  getalldownloads() {
    return this.getalldownloadsEndPoint().pipe(
      map(response => this.processgetalldownloadsResponse(response)));
  }

  getalldownloadsEndPoint() {
    return this.http.get(this.baseURL + 'Download/GetAllDownloads');
  }

  getRecentdownloads() {
    return this.getRecentdownloadsEndPoint().pipe(
      map(response => this.processgetalldownloadsResponse(response)));
  }

  getRecentdownloadsEndPoint() {
    return this.http.get(this.baseURL + 'Download/GetRecentDownloads');
  }
  processgetalldownloadsResponse(downloads: any): any {
    let source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'guid', type: 'string' },
        { name: 'email', type: 'string' },
        { name: 'created', type: 'string' },
        { name: 'downloadStatusID', type: 'integer' },
        { name: 'downloadStatus', type: 'string' }

      ],
      localdata: downloads
    };
    return source;

  }

  repeatDownload(guid: string) {
    return this.repeatDownloadEndPoint(guid);
  }

  repeatDownloadEndPoint(guid: string) {
    return this.http.get(this.baseURL + 'Download/RepeatDownload/' + guid);
  }


  cleanUpDownloadFiles() {
    return this.http.get(this.baseURL + 'Download/CleanUpDownloadFiles');
  }
 

}
