<jqxWindow #viewMapDisclaimer [width]="800" [height]="380" [title]="'Disclaimer'" [animationType]='"fade"' [resizable]="false" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
  <div style="overflow: hidden;">
    <form class="form-inline" style="height:100%; width:100%; margin-left: 15px; " name="viewMapDisclaimerForm" #viewMapDisclaimerForm="ngForm"
          novalidate (ngSubmit)="StartDownload()" >
      <div id="windowHeader" #windowHeader>
      </div>
      <div style="overflow: hidden;" #windowContent>
        <div id="Disclaimer">
          <br />
          <div>Please confirm your acceptance of the following conditions before downloading data.</div>
          <div>
            <ul style="list-style-type: circle;">
              <br />
              <li> • All data products are by default publicly available, including those derived from restricted data..</li>
              <br />
              <li> • All public data are under the Creative Commons (CC BY 4.0) licence.</li>
              <br />
              <li> • You can obtain publicly available data as soon as is feasible.</li>
              <br />
              <li> • You have sole responsibility for correct and appropriate data interpretation.</li>
              <br />
              <li> • Results, conclusions, and/or recommendations derived from the data do not imply endorsement from ICES.</li>
              <br />
              <li> • You are requested to inform ICES of any suspected problems in the data.</li>
              <br />
              <li> The full ICES data policy and terms of use can be viewed <a href="https://ices.dk/data/guidelines-and-policy/Pages/ICES-data-policy.aspx" target="_blank">here</a></li>
            </ul>
            <br />
          </div>
        </div>
        <br/>
        <div>
          <div class="col-sm-0 pull-left">
            <div ngPreserveWhitespaces class="pull-left">
              <button class="btn btn-primary btn-sm" >Accept</button>
            </div>
          </div>
          <div class="col-sm-1 pull-right" style="margin-right:15px;">
            <div ngPreserveWhitespaces class="pull-right">
              <button type="reset" class="btn btn-primary btn-sm"  (click)="close()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
</jqxWindow>
