<jqxWindow #myWindow [width]="700" [height]="450" [resizable]="false" [title]="'Format Type'" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
  <div style="overflow: hidden;">
    <form class="form-horizontal" name="formatTypeEdit" #formatTypeEditForm="ngForm"
          novalidate (ngSubmit)="formatTypeEditForm.valid ? saveBtn() :
          (formatTypeName.valid || showAlert('Name is required', 'Please enter a format type name (minimum of 2 and maximum of 200 characters)'));
          (formatTypeTheme.valid || showAlert('Theme is required', 'Please enter a format type Theme'));
          (formatTypeDownloadType.valid || showAlert('Download Type is required', 'Please enter a DownloadType'));
          (formatTypeLoopLimit.valid || showAlert('Loop Limit is required', 'Enter Format Type Loop Limit(Fetching how many summary data in each call)'));
          (formatTypeContactEmail.valid || showAlert('Contact Email is required', 'Enter Format Type Contact Email(Email of responsible person)'));
          ">
      <div class="form-group">
        <label class="control-label col-md-3">Name:</label>
        <div class="col-md-8" [ngClass]="{'has-success': formatTypeEditForm.submitted && formatTypeName.valid, 'has-error' : formatTypeEditForm.submitted && !formatTypeName.valid}">
          <input appAutofocus type="text" id="formatTypeName" name="formatTypeName" placeholder="Enter Format Type name"
                 class="form-control" [(ngModel)]="formatType.name" #formatTypeName="ngModel" required minlength="2" maxlength="200" />
          <div *ngIf="formatTypeEditForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': formatTypeName.valid, 'glyphicon-remove' : !formatTypeName.valid}"></div>
          <div *ngIf="formatTypeName.touched && formatTypeName.invalid" class="errorMessage">
            Name is required
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-3">Active:</label>
        <div class="col-md-8">
          <input appAutofocus type="checkbox" id="formatTypeActive" name="formatTypeActive" placeholder="Enter Format Type Service URL"
                 class="form-control" [(ngModel)]="formatType.active" #formatTypeActive="ngModel" />
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-3">Theme:</label>
        <div class="col-md-8" [ngClass]="{'has-success': formatTypeEditForm.submitted && formatTypeTheme.valid, 'has-error' : formatTypeEditForm.submitted && !formatTypeTheme.valid}">

          <jqxComboBox #formatTypeTheme id="formatTypeTheme" name="formatTypeTheme" [width]='450' [height]='25'
                       [source]='themeAdapter' [displayMember]="'title'" placeholder="Enter Format Type Theme" [valueMember]="'id'" [(ngModel)]="formatType.themeID" required>
          </jqxComboBox>
          <div *ngIf="formatTypeEditForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': formatTypeTheme.valid, 'glyphicon-remove' : !formatTypeTheme.valid}"></div>
          <div *ngIf="formatTypeTheme.touched && formatTypeTheme.invalid" class="errorMessage">
            Theme is required
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 edit-separator-hr">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-3">Download Type:</label>
        <div class="col-md-8" [ngClass]="{'has-success': formatTypeEditForm.submitted && formatTypeDownloadType.valid, 'has-error' : formatTypeEditForm.submitted && !formatTypeDownloadType.valid}">

          <jqxComboBox #formatTypeDownloadType id="formatTypeDownloadType" name="formatTypeDownloadType" [width]='450' [height]='25'
                       [source]='downloadTypeAdapter' [displayMember]="'title'" placeholder="Enter Download Type" [valueMember]="'id'" [(ngModel)]="formatType.downloadTypeID" required>
          </jqxComboBox>
          <div *ngIf="formatTypeEditForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': formatTypeDownloadType.valid, 'glyphicon-remove' : !formatTypeDownloadType.valid}"></div>
          <div *ngIf="formatTypeDownloadType.touched && formatTypeDownloadType.invalid" class="errorMessage">
            Download Type is required
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 edit-separator-hr">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-3">Loop limit:</label>
        <div class="col-md-8">
          <input appAutofocus #formatTypeLoopLimit type="text" id="formatTypeLoopLimit" name="formatTypeLoopLimit" placeholder="Enter Format Type Loop Limit(Fetching how many summary data in each call) "
                 class="form-control" [(ngModel)]="formatType.loopLimit" required minlength="1" maxlength="10" />
        </div>
        <div *ngIf="formatTypeEditForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': formatTypeLoopLimit.valid, 'glyphicon-remove' : !formatTypeLoopLimit.valid}"></div>
        <div *ngIf="formatTypeLoopLimit.touched && formatTypeLoopLimit.invalid" class="errorMessage">
          Loop Limit is required
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 edit-separator-hr">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-3">Contact Email:</label>
        <div class="col-md-8">
          <input appAutofocus #formatTypeContactEmail type="text" id="formatTypeContactEmail" name="formatTypeContactEmail" placeholder="Enter Format Type Contact Email(Email of responsible person) "
                 class="form-control" [(ngModel)]="formatType.contactEmail" />
        </div>
        <div *ngIf="formatTypeEditForm.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': formatTypeContactEmail.valid, 'glyphicon-remove' : !formatTypeContactEmail.valid}"></div>
        <div *ngIf="formatTypeContactEmail.touched && formatTypeContactEmail.invalid" class="errorMessage">
          Contact Email is required
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 edit-separator-hr">
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-3">Comment:</label>
        <div class="col-md-8">
          <input appAutofocus type="text" id="formatTypeComment" name="formatTypeComment" placeholder="Enter Format Type comment"
                 class="form-control" [(ngModel)]="formatType.comment" #formatTypeComment="ngModel" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 edit-separator-hr">
        </div>
      </div>

      <div class="col-sm-7 pull-right">
        <div ngPreserveWhitespaces class="pull-right">
          <button type="button" (click)="cancelBtn()" class="btn btn-danger"><i class='fa fa-times'></i>Cancel</button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </form>
  </div>
</jqxWindow>
