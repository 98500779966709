import { Component, OnInit, Injectable, Inject, ViewChild, AfterViewInit } from "@angular/core";
import { AlertService, MessageSeverity, MessageType } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { HttpClient } from "@angular/common/http";
import { Route, Router, ActivatedRoute } from "@angular/router";
import { Core } from "../shared/core";
import { jqxGridComponent }  from "jqwidgets-ng/jqxgrid";
import { DownloadService } from '../services/download.service';
import { jqxWindowComponent }  from 'jqwidgets-ng/jqxwindow';


@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})


@Injectable()
export class DownloadsComponent implements OnInit, AfterViewInit {
  dataAdapter: any;
  columns: any[];
  guid: string = '';
  @ViewChild("downloadGrid") downloadGrid: jqxGridComponent;
 
  baseURL: any;
  @ViewChild('email') private email;
 // @ViewChild('emailStore', { static: false }) private emailStore;
  @ViewChild('sendNotificationWindow') sendNotificationWindow: jqxWindowComponent;

  emailAddress: string;

  ngOnInit() {

   
  }

  //TODO: Set timer to update for
  

  constructor(private authService: AuthService, private router: Router, @Inject('BASE_URL') url: string, private alertService: AlertService, private route: ActivatedRoute, private downloadService: DownloadService) {
    this.alertService.startLoadingMessage();
    this.route.queryParams.subscribe(params => {
      if (params['guid'] != '')
        this.guid = params['guid'];
    });

    this.baseURL = url;

    if (this.authService.currentUser != undefined && this.authService.currentUser.email != "")
      this.emailAddress = this.authService.currentUser.email;

    this.refreshData();

    setInterval(() => {
      this.refreshData();
    }, 10000);

    
    this.alertService.stopLoadingMessage();
  }

  refreshData() {
    if (this.downloadService == undefined) return;
    this.downloadService.getdownloads(this.guid).subscribe(
      download => {
        if (download.localdata.length == 0) {
          var container = document.getElementById('container');
          container.setAttribute("style", "visibility:hidden;width: 0px; height:0px;");
          this.alertService.showMessage('Information', 'This link is not active anymore', MessageSeverity.information);
          return;
        }
       this.dataAdapter = new jqx.dataAdapter(download);
        this.columns =
          [
            { text: 'partguid', datafield: 'partGUID', hidden: true },
            { text: 'Theme', datafield: 'theme', width: 250 },
            { text: 'Dataset', datafield: 'dataset', width: 250},
            { text: 'Status', datafield: 'downloadStatus', width: 100 },
            { text: 'Time period', datafield: 'date', width: 250 },
            { text: 'Data Criteria', datafield: 'dataCriteria'},
            { text: 'Download', datafield: 'downloadStatusID', width: 80, cellsrenderer: this.downloadRender }
          ];

        var container = document.getElementById('container');
        container.setAttribute("style", "visibility:visible;width: 100%; height:800px;");
        var downloadAllbtn = document.getElementById('downloadAllbtn');
        downloadAllbtn.setAttribute("style", "visibility:visible;");
        var sendNotificationbtn = document.getElementById('sendNotificationbtn');
        var isFinished = true;
        for (var i = 0; i < download.localdata.length; i++) {
          if (download.localdata[i].email != "" && download.localdata[i].email != null)
            sendNotificationbtn.setAttribute("style", "visibility:hidden;");
          if (download.localdata[i].downloadStatusID == 1) {
            downloadAllbtn.setAttribute("style", "visibility:hidden;");
            isFinished = false;
          }
        }

        if (isFinished)
          sendNotificationbtn.setAttribute("style", "visibility:hidden;");
      },
      error => {
        this.alertService.showMessage('Unable to load download parts', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    )
  }

  Cellclick(event: any): void {
    // Do Something
    var args = event.args;
    var rowBoundIndex = args.rowindex;
    var columnindex = args.columnindex;
    var rowdata = this.downloadGrid.getrowdata(rowBoundIndex);
    if (columnindex == 6 && rowdata.downloadStatusID == 2) {
      window.location.href = this.baseURL + 'Download/' + rowdata.partGUID
    }
  }


  ngAfterViewChecked(): void {
    
  }


  downloadRender(row, datafield, value) {
    if (value == 2)
      return '<button style="width: 80px; height: 32px;cursor:pointer;"> <span class="glyphicon glyphicon-download-alt"> </span> </button>';
    else
      return '<button style="width: 80px; height: 32px;"> <img style="margin-left: 5px; " height="20" title="Under process" alt="Under process" width="20" src="../image/loading.gif"/></button>';


  }
  downloadAll() {
    window.location.href = this.baseURL + 'Download/' + this.guid;
  }

  ngAfterViewInit() {
    
  }

  showSendNotificationWindow() {
    this.sendNotificationWindow.open();
  }

  closeSendNotificationWindow() {
    this.sendNotificationWindow.close();
  }

  sendNotification() {
    if (this.emailAddress == "") {
      this.alertService.showMessage('Email Address is required', '', MessageSeverity.error);
      return;
    }
    //else
    //  if (this.emailAddress != "" && !this.emailStore.value) {
    //    this.alertService.showMessage('Please accept to store the email at ICES Dataportal', '', MessageSeverity.error);
    //    return;
    //  }
    this.downloadService.sendNotification(this.guid, this.emailAddress).subscribe(
      download => {
        this.sendNotificationWindow.close();
        this.refreshData();
        this.alertService.showRemainMessage('Success', 'You have successfully submitted a request to get notification. When the data have been extracted, you will get an e-mail with a link to download data.', MessageSeverity.information);

      },
      error => {
        this.alertService.showMessage('Unable to register email for download', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );

      


  }


  getWidth(): any {
    return '100%';
  }
}




