import { Component, ViewChild, OnInit, Injectable, Inject, AfterViewInit } from "@angular/core";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { AuthService } from "../../services/auth.service";
import { HttpClient } from "@angular/common/http";
import { Route, Router } from "@angular/router";
import { FormatType } from "../../models/formatType.model";
import { FormatTypeService } from "../../services/formatType.service";
import { Core } from "../../shared/core";
import { jqxComboBoxModule }  from "jqwidgets-ng/jqxcombobox";
import OlStyle from 'ol/style/Style';
import OlStyleFill from 'ol/style/Fill';
import OlStyleCircle from 'ol/style/Circle';
import OlStyleStroke from 'ol/style/Stroke';
import { RECOService } from '../../services/reco.service';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxComboBoxComponent } from 'jqwidgets-ng/jqxComboBox';


@Component({
  selector: 'app-formatType-editor',
  templateUrl: './formatType-editor.component.html',
  styleUrls: ['./formatType-editor.component.css']
})

export class FormatTypeEditorComponent implements AfterViewInit, OnInit {
  @ViewChild('myWindow') myWindow: jqxWindowComponent;
  isNewRow = false;
  formatType: FormatType;
  themeAdapter: any;
  downloadTypeAdapter: any;
  
  @ViewChild('formatTypeName')
  private formatTypeName;

  @ViewChild('formatTypeTheme')
  formatTypeTheme: jqxComboBoxComponent;

  @ViewChild('formatTypeDownloadType')
  formatTypeDownloadType: jqxComboBoxComponent;



  public notifySaveChanges: () => void;

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }


  constructor(http: HttpClient, private router: Router, private alertService: AlertService, private formatTypeService: FormatTypeService, private recoService: RECOService) {
    this.formatType = new FormatType();
    this.recoService.getCodeType('Theme').subscribe(
      result => {
        this.themeAdapter = new jqx.dataAdapter(result);
      },
      error => {
        this.alertService.showMessage('Unable to get theme', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );
    this.formatTypeService.getDownloadType().subscribe(
      result => {
        this.downloadTypeAdapter = new jqx.dataAdapter(result);
      },
      error => {
        this.alertService.showMessage('Unable to Download Type', Core.getHttpResponseMessage('', error), MessageSeverity.error);
      }
    );
  }

  newFormatType() {
    this.isNewRow = true;
    this.formatType = new FormatType();
    this.formatTypeTheme.clearSelection();
    this.formatType.loopLimit = 1;
    return this.formatType;
  }


  editFormatType(formatType: FormatType) {
    this.isNewRow = false;
    this.formatType = formatType;
    this.formatTypeTheme.selectItem(this.formatType.themeID.toString());
    this.formatTypeDownloadType.selectItem(this.formatType.downloadTypeID.toString());
    return this.formatType;
  }

  open() {
    this.myWindow.position({ x: 500, y: 300 });
    this.myWindow.open();
  }

  saveBtn() {
    this.alertService.startLoadingMessage('Saving changes');

    if (this.isNewRow) {
        this.formatType.mapStyle = this.getDefaultStyle();
        this.formatTypeService.newFormatType(this.formatType).subscribe(
        formatType => {
          Object.assign(this.formatType, formatType);
          this.alertService.showMessage('Success', `Format Type \"${this.formatType.name}\" was created successfully`, MessageSeverity.success);
          this.notifySaveChanges();
          this.myWindow.hide();
        },
        error => {
          this.alertService.showMessage('Unable to add format type', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
      );
    }
    else {
      this.formatTypeService.editFormatType(this.formatType).subscribe(
        formatType => {
          Object.assign(this.formatType, formatType);
          this.alertService.showMessage('Success', `Format Type \"${this.formatType.name}\" was updated successfully`, MessageSeverity.success);
          this.notifySaveChanges();
          this.myWindow.hide();
        },
        error => {
          this.alertService.showMessage('Unable to edit format type', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
      );


    }
    this.alertService.stopLoadingMessage();

  }

  showAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  cancelBtn() {
    this.myWindow.hide();
  }

  getDefaultStyle() {
    var c1 = this.getRandomColor();
    var c2 = this.getRandomColor();
    var c3 = this.getRandomColor();
    var style = `
      ({
        run: function (feature) {
          var style = new ol.style.Style({
          image: new ol.style.Circle({
            points: 1,
            radius: 2,
            angle: Math.PI / 4,
            stroke: new ol.style.Stroke({
              color: '` + c1 + `',
              width: 1
            }),
            fill: new ol.style.Fill({
              color: '` + c2 + `'
            })
          }),
          stroke: new ol.style.Stroke(
            {
              color: '` + c3 + `',
              width: 2
            }),
          fill: new ol.style.Fill(
            {
              color: 'rgba(0, 0, 255, 0.0)'
            })
        });
        return [style];
      }  }) `;

    return style;
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
