import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { jqxWindowComponent }  from "jqwidgets-ng/jqxwindow";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { RECOService } from "../../services/reco.service";
import { ViewMapService } from "../../services/view-map.service";
import { Core } from "../../shared/core";
import { FormatTypeService } from "../../services/formatType.service";
import { FormatType } from "../../models/formatType.model";
import { VocabService } from "../../services/vocab.service";






@Component({
  selector: 'app-viewMap-profile',
  templateUrl: './viewMap-profile.component.html',
  styleUrls: ['./viewMap-profile.component.css']
})


export class ViewMapProfileComponent implements AfterViewInit, OnInit {
  @ViewChild('viewMapProfile') viewMapProfile: jqxWindowComponent;
  @ViewChild('profileGrid') profileGrid: jqxGridComponent;

  conditionString: string[];
  dataAdapter: any;
  columns: any[];
  source: any;
  current: number = 0;
  ngOnInit() {
  }

  getStyle() {

  }

  ngAfterViewInit(): void {
  }

  public notifyUpdateMap: () => void;

  constructor(http: HttpClient, private router: Router, private alertService: AlertService, private viewMapService: ViewMapService, private formatTypeService: FormatTypeService, private vocabservice: VocabService) {
    
  }
   
  open(xp: number, yp: number) {
    this.alertService.startLoadingMessage();
    this.viewMapService.getSummaryDataProfile(this.conditionString).subscribe(
      resultData => {
        this.source = <Array<any>>resultData;
        this.current = 0;
        this.viewMapProfile.setTitle('Attributes of event (1 of ' + this.source.length + ')');
        this.dataAdapter = new jqx.dataAdapter(this.processJsonResponse(this.source[0]));
        this.columns =
          [
          { text: 'Name', datafield: 'name', width: '25%', pinned: true },
          { text: 'Value', datafield: 'value', width: '75%', cellsrenderer: this.cellsrenderer }
          ];
        this.alertService.stopLoadingMessage();
        this.viewMapProfile.position({ x: xp, y: yp });
        //this.viewMapProfile.resize(xp, yp);
        this.viewMapProfile.open();
      },
      error => {
        this.alertService.showMessage('Unable to load format type', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        this.alertService.stopLoadingMessage();
      });
  }

  cellsrenderer(row, cell, value) {
    if (value.includes('http'))
      return '<a target="_blank" href="' + value + '"/>' + value + '</a>';
    else
      return '<span style="vertical-align:left; display:block; word-wrap:break-word;">' + value + '</span>';
  }

    processJsonResponse(data: any): any {
    let source: any =
    {
      datatype: 'json',
      datafields: [
        { name: 'name', type: 'string' },
        { name: 'value', type: 'string' }
      ],
      localdata: data
    };
    return source;
  }
  close() {
    this.viewMapProfile.hide();
  }

  getWidth(): any {
    return '99%';
  }

  ShowNext() {
    if (this.current < this.source.length -1) {
      this.current++;
      this.viewMapProfile.setTitle('Attributes of event (' + (this.current + 1).toString() + ' of ' + this.source.length + ')');
      this.dataAdapter = new jqx.dataAdapter(this.processJsonResponse(this.source[this.current]));
    }
  }

  ShowPrev() {
    if (this.current > 0) {
      this.current--;
      this.viewMapProfile.setTitle('Attributes of event (' + (this.current + 1).toString() + ' of ' + this.source.length + ')');
      this.dataAdapter = new jqx.dataAdapter(this.processJsonResponse(this.source[this.current]));
    }

  }

  ngAfterViewChecked(): void {
    var pager = document.getElementsByClassName('jqx-clear jqx-position-absolute jqx-grid-pager jqx-widget-header');
    if (pager.length > 2)
      pager[2].setAttribute("style", "width: 0px;height:0px;");
  }
}
