import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild, AfterViewChecked, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { jqxWindowComponent }  from "jqwidgets-ng/jqxwindow";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { ViewMapService } from "../../services/view-map.service";
import { Core } from "../../shared/core";
import { AuthService } from '../../services/auth.service';
import { jqxTreeGridComponent }  from 'jqwidgets-ng/jqxtreegrid';


@Component({
  selector: 'app-viewMap-showReferenceLayer',
  templateUrl: './viewMap-showReferenceLayer.component.html',
  styleUrls: ['./viewMap-showReferenceLayer.component.css']
})

export class ViewMapShowReferenceLayerComponent implements AfterViewInit, OnInit, AfterViewChecked, OnDestroy  {
  @ViewChild('viewShowReferenceLayer') viewMapShowReferenceLayerWindow: jqxWindowComponent;
  @ViewChild('referenceLayerTree') referenceLayerTree: jqxTreeGridComponent;

  
  records: any;
  columns: any;
  featureTypes: any = [];
  featureTypesData: any = [];
  mode: number; //0: MainMap 1: GeoBoxMap
  area: string = '';
  ngOnInit() {
  }

  public notifyGeoBoxMapApplyCondition: () => void;


  ngAfterViewInit(): void {
      this.viewMapService.getfeatureTypes().subscribe(
        result => {
          this.featureTypes = [];
          this.featureTypesData = result;
          for (let entry of this.featureTypesData) {
            var parent: any = {};
            parent.id = entry.id;
            parent.name = entry.name;
            parent.parentid = -1;
            this.featureTypes.push(parent);
            for (let feature of entry.featureList) {
              if (this.featureTypes.filter(s => s.id == feature.id).length == 0) {
                var item: any = {};
                item.id = feature.id;
                item.name = feature.name;
                item.parentid = parent.id;
                this.featureTypes.push(item);
              }
            }
          }

          let widgetSource =
          {
            datatype: "json",
            datafields: [
              { name: 'id' },
              { name: 'parentid' },
              { name: 'name' },
            ],
            hierarchy:
            {
              keyDataField: { name: 'id' },
              parentDataField: { name: 'parentid' }
            },
            id: 'id',
            localdata: this.featureTypes
          };
          this.records = new jqx.dataAdapter(widgetSource, { autoBind: true });
          this.columns = [
            { text: "name", align: "center", dataField: "name", width: 290 },
          ];

         
        },
        error => {
          this.alertService.showMessage('Unable to getfeatureTypes', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        }
      );
  }

  ngAfterViewChecked(): void {
    if (this.area != "") {
      var rows = this.referenceLayerTree.getRows();
      for (let row of rows) {
        if (row.uid == this.area && !this.referenceLayerTree.getRow(row.uid).checked) {
          this.referenceLayerTree.checkRow(this.area);
          
        }
      }

    }
  }

  ngOnDestroy() {

  }
  constructor(http: HttpClient, private router: Router, private alertService: AlertService, private viewMapService: ViewMapService) {
  }

  open() {
    this.viewMapShowReferenceLayerWindow.open();
    
  }

  close() {
    this.viewMapShowReferenceLayerWindow.close();
  }

  public notifyAddReferenceLayers: (url: string, layerDefs: string) => void;
  public notifyRemoveReferenceLayers: (url: string, layerDefs: string) => void;

  public notifyGeoBoxMapAddReferenceLayers: (url: string, layerDefs: string, id: string, id1: string) => void;
  public notifyGeoBoxMapRemoveReferenceLayers: (url: string, layerDefs: string) => void;

  referenceLayerTreeCheckChange(event: any): void {
    if (event.args.row.id != undefined) {
      if (this.mode == 0) {
        var layerDefs = '';
        var url = '';
        if (event.args.row.parent != null) {
          var selectedRow = this.featureTypesData.filter(s => s.id == event.args.row.parent.id)[0];
          layerDefs = '{"0":"' + selectedRow.layerDefsFilterName + '=\'' + event.args.row.name + '\'"}';
          url = selectedRow.url;
        }
        else
          url = this.featureTypesData.filter(s => s.id == event.args.row.id)[0].url;
        if (event.args.row.checked)
          this.notifyAddReferenceLayers(url, layerDefs);
        else
          this.notifyRemoveReferenceLayers(url, layerDefs);


      }
      else
        if (this.mode == 1) {
          //'{"0":"Area_Full=\'27.14.a\'"}'
          var layerDefs = '';
          var url = '';
          var id = '';
          var id1 = '';
          if (event.args.row.parent != null) {
            var selectedRow = this.featureTypesData.filter(s => s.id == event.args.row.parent.id)[0];
            layerDefs = '{"0":"' + selectedRow.layerDefsFilterName + '=\'' + event.args.row.name + '\'"}';
            url = selectedRow.url;
            id = event.args.row.parent.name.replaceAll(' ', '') + '=' + event.args.row.id;
            id1 = event.args.row.parent.name + ' = ' + event.args.row.name;
          }
          else {
            url = this.featureTypesData.filter(s => s.id == event.args.row.id)[0].url;
            id = event.args.row.name.replaceAll(' ', '') + ' is not null';
            id1 = 'located in ' + event.args.row.name;
          }
          if (event.args.row.checked) 
            this.notifyGeoBoxMapAddReferenceLayers(url, layerDefs, id, id1);
          else 
            this.notifyGeoBoxMapRemoveReferenceLayers(url, layerDefs);
        }
    }
  }

  referenceLayerTreeClear() {
    this.referenceLayerTree.clearSelection();
    var rows = this.referenceLayerTree.getRows();
    for (let row of rows) {
      if (row.checked)
        this.referenceLayerTree.uncheckRow(row.uid.toString());
      for (let row2 of row.records) 
        if (row2.checked)
          this.referenceLayerTree.uncheckRow(row2.uid.toString());
    }
  }


  getWidth(): any {
    return '99%';
  }
}
