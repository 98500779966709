
<jqxWindow #viewMapSearch [width]="1100" [height]="750" [title]="'Here you can find and download data by first adding topics or dataset themes to the map'" [animationType]='"fade"' [resizable]="false" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
  <div style="overflow: hidden;">
    <form class="form-inline" style="height:100%; width:100%; margin-left: 15px; " name="viewMapSearchForm" #viewMapSearchForm="ngForm" (ngSubmit)="UpdateSearchMap()">
      <div class="form-group row" style="float:left; width:100%; height:5%; ">
        <div class="row">
          <div class="col-sm-2 pull-right">
            <div ngPreserveWhitespaces class="pull-right">
              <jqxTooltip [position]="'mouse'" [name]="'movieTooltip'"
                          [content]="'Click on this button to see selected topics or datasets on the map'">
                <button class="btn btn-primary btn-sm" style="float:left; margin-left: 5px;"><i class="fas fa-map" style="margin-right: 10px;" aria-hidden="true"></i>Update map</button>
              </jqxTooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row" style="float:left; width:100%; height:95%; margin-top: 5px;">
        <jqxTabs #tabs (onTabclick)="tabclick($event)"
                 [width]='"100%"' [height]='"99%"' [position]='"top"'
                 [animationType]='"fade"' [selectionTracker]='false'>
          <ul>
            <li>Topics</li>
            <li>Dataset themes</li>
            <li>Advanced search</li>
          </ul>

          <div class="container" style="width:100%; height:100%; display:block; padding: 1% 2% 1% 2%; ">
            <div style="width:100%; height:5%; float:left;">
              <div id="topicLinksHome" #topicLinksHome class="form-group" style="visibility:hidden;">
                <button type="button" class="buttonLink" (click)="backHome()">Home</button>
              </div>
              <div id="topicLinksTheme" #topicLinksTheme class="form-group" style="visibility:hidden;">
                /
                <button type="button" class="buttonLink" (click)="backTheme()">Theme</button>
              </div>
            </div>
            <div style="width:100%; height:94%; float:left;">

              <div id="container" style="width: 290px; height: 5%; position: absolute; margin-left: 25px; margin-top: 5px; z-index: 90000;">
                <jqxNotification #msgNotification [width]="290" [appendContainer]="'#container'" [opacity]="0.9" [autoOpen]="false"
                                 [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="4000" [template]="'info'">
                  <div>
                    Please check this box if you want select this theme or dataset.
                  </div>
                </jqxNotification>
              </div>

              <jqxTreeMap #treeMap id="treeMap" [width]='"100%"' [height]='"100%"' [source]="treeMapData"
                          [colorRange]="50" [renderCallbacks]="renderCallbacks" [selectionEnabled]='true'>
              </jqxTreeMap>

            </div>
          </div>
          <div style="width:100%; height:99%; display:block;">
            <jqxGrid #datasetGrid [source]="datasetRecords" [columns]="columns" [width]="getWidth()"
                     [autoheight]="true" [sortable]="true"
                     [altrows]="true" [enabletooltips]="true" [editable]="false" [groupable]="true" (onRowselect)="ondatasetGridRowselect($event)" (onRowunselect)="ondatasetGridRowunselect($event)"
                     [selectionmode]="'checkbox'">
            </jqxGrid>
          </div>
          <div class="container" style="width:100%; height:100%; display:block; margin: 1% 1% 1% 1%;">
            <div>
              <h4>Selected Dataset(s):</h4>
              <h5 #searchBy id="searchBy"></h5>
            </div>
            <div class="GeneralLabel">Date/Time</div>
            <div class="form-group">
              <jqxRadioButton id="Period" #period [checked]="true" [groupName]="1" (onChecked)="DateRadioGroupOnChecked()">
                <label>Date/Time range</label>
              </jqxRadioButton>
            </div>

            <div class="form-group" style=" margin-left: 10px">
              <jqxRadioButton id="season" #season [groupName]="1" (onChecked)="DateRadioGroupOnChecked()">
                <label>Periodic Intervals</label>
              </jqxRadioButton>
            </div>

            <div id="containerdatetime" style="width: 850px; height: 5%; position: absolute; margin-left: 10px; margin-top: -150px; z-index: 90000;">
              <jqxNotification #msgNotificationdatetime [width]="850" [appendContainer]="'#containerdatetime'" [opacity]="0.9" [autoOpen]="false"
                               [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="8000" [template]="'info'">
                <div>
                  <ul style="list-style-type: circle;">
                    <li>Date/Time range: will give all data between start-end date </li>
                    <br />
                    <li>Periodic intervals: will give all data between start year-end year for month/day/hour/minute periodic intervals</li>
                  </ul>
                  ex. start: 2010-02 end: 2022-03 will give all data taken between Feburary to March between year 2010 and 2022
                  <br />
                  ex. start: ****-**-**08:00 end: ****-**-**20:00 will give all data taken between 8 o'clock to 20 o'clock for all years
                </div>
              </jqxNotification>
            </div>


            <div class="form-group" style="margin-left:65px;">
              <label style="margin-left:5px;" for="startDate">Start:</label>
              <input appAutofocus style="margin-left: 5px; width: 150px;" #startDate type="text" class="form-control input-sm" id="startDate" name="startDate" (keyup)="dateKeyUp($event)" maxlength="16" placeholder="yyyy-MM-dd hh:mm" title="yyyy-MM-dd hh:mm" />
            </div>
            <div class="form-group" style="margin-left:30px;">
              <label style="margin-left:5px;" for="endDate">End:</label>
              <input appAutofocus style="margin-left:5px;width:150px;" #endDate type="text" class="form-control input-sm" (keyup)="dateKeyUp($event)" maxlength="16" placeholder="yyyy-MM-dd hh:mm" title="yyyy-MM-dd hh:mm" id="endDate" name="endDate" />
            </div>
            <div class="form-group" style="margin-left:20px;">
              <button style="float:left; margin-left: 10px;" id="searchbutton" class="btn btn-primary btn-sm" type="button" title="Date condition(s)" (click)="showDateConditions()"><i class="fas fa-calendar-alt" style="margin-right: 10px;" aria-hidden="true"></i>Date and time condition(s)</button>
              <button class="btn btn-basic btn-sm" type="button" title="Remove date condition(s)" (click)="resetdateCondition()" style="float:left; margin-left: 5px;"><i class="fas fa-eraser"></i></button>
            </div>
            <div>
              <app-viewMap-dateCondition #viewMapDateCondition> </app-viewMap-dateCondition>
            </div>

            <div class="row">
              <div class="col-md-12 edit-separator-hr">
              </div>
            </div>

            <div class="GeneralLabel">Geographical box</div>
            <div class="form-group">
              <input appAutofocus type="text" #north style="width:50px; margin-left:42px;" class="form-control input-sm" id="north" name="north" placeholder="N" title="Northern boundary" />
              <div class="row">
                <div class="col-md-12 edit-separator-hr">
                </div>
              </div>
              <input appAutofocus type="text" #west style="width:50px;" class="form-control input-sm" id="west" name="west" placeholder="W" title="Western boundary" />
              <input appAutofocus type="text" #east style="width:50px; margin-left:35px; " class="form-control input-sm" id="east" name="east" placeholder="E" title="Eastern boundary" />
              <div class="row">
                <div class="col-md-12 edit-separator-hr">
                </div>
              </div>
              <input appAutofocus type="text" #south style="width:50px; margin-left:42px;" class="form-control input-sm" id="south" name="south" placeholder="S" title="Southern boundary" />
            </div>
            <div class="form-group" style="margin-left:5px;">
              <button style="float:left; margin-left: 5px;" id="searchbutton" class="btn btn-primary btn-sm" type="button" title="Select geographical area" (click)="showGeographicalSearch()"><i class="fas fa-map-marked" style="margin-right: 10px;" aria-hidden="true"></i>Filter by geographical area</button>
            </div>
            <div class="form-group">
              <textarea disabled="true" id="geoConditionText" style="width:645px; height:100px; float:left; margin-left:5px; ">{{geoConditionText}}</textarea>
              <button class="btn btn-basic btn-sm" type="button" title="Remove geographical area condition(s)" (click)="resetgeoCondition()" style="float:left; margin-left: 5px;"><i class="fas fa-eraser"></i></button>
            </div>
            <div>
              <app-viewMap-geographicalBox #viewMapShowGeographicalBox> </app-viewMap-geographicalBox>
            </div>
            <div class="row">
              <div class="col-md-12 edit-separator-hr">
              </div>
            </div>

            <div class="GeneralLabel">Data criteria</div>
            <div id="containerdatacriteria" style="width: 290px; height: 5%; position: absolute; margin-left: 210px; margin-top: 5px; z-index: 90000;">
              <jqxNotification #msgNotificationdatacriteria [width]="290" [appendContainer]="'#containerdatacriteria'" [opacity]="0.9" [autoOpen]="false"
                               [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="4000" [template]="'info'">
                <div>
                  Please select a dataset to see the list of data criteria(s)
                </div>
              </jqxNotification>
            </div>

            <div class="form-group">
              <label for="comboboxDataCriteria">Select Data Criteria:</label>
              <jqxComboBox #comboboxDataCriteria id="comboboxDataCriteria" name="comboboxDataCriteria"
                           [source]="dataCriteriaAdapter" [multiSelect]="false" [displayMember]="'title'" (onChange)="comboboxDataCriteriaOnChange()" placeholder="Select Data criteria" (onOpen)="comboboxDataCriteriaOpen($event)">
              </jqxComboBox>
            </div>
            <div class="form-group" id="vocabCondition" style="visibility:hidden; width:0px; margin-left:20px;">
              <label for="comboboxDataCriteriaVocab">Select Code(s):</label>
              <jqxComboBox #comboboxDataCriteriaVocab id="comboboxDataCriteriaVocab" name="comboboxDataCriteriaVocab" autoItemsHeight="true"
                           [source]="dataCriteriaVocabAdapter" [multiSelect]="true" [width]='500' [displayMember]="'title'" searchMode="containsignorecase" (onSelect)="comboboxDataCriteriaVocabOnSelect($event)" placeholder="Select Code(s)">
              </jqxComboBox>
            </div>
            <div class="form-group" id="textCondition" style="visibility:hidden; width:0px; margin-left:20px;">
              <label for="inputDataCriteria" style="display:block;">Search keyword(s):</label>
              <jqxInput #inputDataCriteria id="inputDataCriteria" name="inputDataCriteria" [width]='500' [height]='25'
                        placeholder="Enter Search keyword(s)" (onChange)="inputDataCriteriaOnChange($event)">
              </jqxInput>
            </div>

            <div class="form-group" id="addCondition" style="visibility:hidden; margin-left:20px; margin-top:20px;">
              <jqxRadioButton id="andRadioButton" #andRadioButton [checked]="true" [groupName]="2">
                <label>And</label>
              </jqxRadioButton>
            </div>

            <div class="form-group" id="addCondition1" #orRadioButton style="visibility: hidden; margin-top: 20px; margin-left: 10px">
              <jqxRadioButton id="orRadioButton" [groupName]="2">
                <label>Or</label>
              </jqxRadioButton>
            </div>
            <div class="form-group" id="addCondition2" style="visibility:hidden; margin-left:20px; margin-top:20px; ">
              <button class="btn btn-basic btn-sm" id="addCondition" type="button" (click)="addCondition()"><i class="fas fa-plus-circle"></i></button>
            </div>



            <div class="row">
              <div class="col-md-12 edit-separator-hr">
              </div>
            </div>
            <div>
              <textarea disabled="true" id="conditionText" style="width:95%; height:200px; float:left;">{{conditionString}}</textarea>
              <button class="btn btn-basic btn-sm" type="button" title="Remove data criteria condition(s)" (click)="resetCondition()" style="float:left; margin-left: 5px;"><i class="fas fa-eraser"></i></button>
            </div>
          </div>
        </jqxTabs>
      </div>
    </form>
  </div>
</jqxWindow>
