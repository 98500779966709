import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild, AfterViewChecked, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { jqxWindowModule }  from "jqwidgets-ng/jqxwindow";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { ViewMapService } from "../../services/view-map.service";
import { Core } from "../../shared/core";
import { AuthService } from '../../services/auth.service';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import Zoom from 'ol/control/Zoom';
import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import { get } from 'ol/proj';
import { getBottomLeft } from 'ol/extent';
import { getTopRight } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import OlVector from 'ol/source/Vector';
import OlTileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import OlXYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import WKT from 'ol/format/WKT';
import WMS from 'ol/source/TileWMS';
import Draw, { createBox } from 'ol/interaction/Draw';
import { ViewMapShowReferenceLayerComponent } from './viewMap-showReferenceLayer.component';
import { MapLayer } from '../../models/mapLayer.model';
import { MapExternalLayer } from '../../models/mapExternalLayer.model';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';


@Component({
  selector: 'app-viewMap-dateCondition',
  templateUrl: './viewMap-dateCondition.component.html'
})

export class ViewMapDateConditionComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('dateConditionWindow') dateConditionWindow: jqxWindowComponent;
  startDateConditionsText: string = '';
  endDateConditionsText: string = '';
  @ViewChild('startYear') private startYear;
  @ViewChild('endYear') private endYear;
  @ViewChild('startMonth') private startMonth;
  @ViewChild('endMonth') private endMonth;
  @ViewChild('startDay') private startDay;
  @ViewChild('endDay') private endDay;
  @ViewChild('startHour') private startHour;
  @ViewChild('endHour') private endHour;
  @ViewChild('startMinute') private startMinute;
  @ViewChild('endMinute') private endMinute;

  public notifyAddDateConditions: () => void;

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }


  constructor(http: HttpClient, private router: Router, private alertService: AlertService, private viewMapService: ViewMapService) {
  }

  ngOnDestroy() {
  }

  open(startDate: string, endDate: string) {
    this.clearAllDateCondition();
    if (startDate.length > 0) {
      if (startDate.substring(0, 4) > "1800")
        this.startYear.nativeElement.value = startDate.substring(0, 4).replace('-', '').replace(':', '');
      if (startDate.substring(5, 7) > "00")
        this.startMonth.nativeElement.value = startDate.substring(5, 7).replace('-', '').replace(':', '');
      if (startDate.substring(8, 10) > "00")
        this.startDay.nativeElement.value = startDate.substring(8, 10).replace('-', '').replace(':', '');
      if (startDate.substring(11, 13) > "00")
        this.startHour.nativeElement.value = startDate.substring(11, 13).replace('-', '').replace(':', '');
      if (startDate.substring(14, 16) > "00")
        this.startMinute.nativeElement.value = startDate.substring(14, 16).replace('-', '').replace(':', '');
    }

    if (endDate.length > 0) {
      if (endDate.substring(0, 4) > "1800")
        this.endYear.nativeElement.value = endDate.substring(0, 4).replace('-', '').replace(':', '');
      if (endDate.substring(5, 7) > "00")
        this.endMonth.nativeElement.value = endDate.substring(5, 7).replace('-', '').replace(':', '');
      if (endDate.substring(8, 10) > "00")
        this.endDay.nativeElement.value = endDate.substring(8, 10).replace('-', '').replace(':', '');
      if (endDate.substring(11, 13) > "00")
        this.endHour.nativeElement.value = endDate.substring(11, 13).replace('-', '').replace(':', '');
      if (endDate.substring(14, 16) > "00")
        this.endMinute.nativeElement.value = endDate.substring(14, 16).replace('-', '').replace(':', '');
    }

    this.dateConditionWindow.open();
  }

  close() {
    this.dateConditionWindow.close();
  }

  getWidth(): any {
    return '99%';
  }

  clearAllDateCondition() {
    this.startDateConditionsText = '';
    this.endDateConditionsText = '';
    this.startYear.nativeElement.value = '';
    this.endYear.nativeElement.value = '';
    this.startMonth.nativeElement.value = '';
    this.endMonth.nativeElement.value = '';
    this.startDay.nativeElement.value = '';
    this.endDay.nativeElement.value = '';
    this.startHour.nativeElement.value = '';
    this.endHour.nativeElement.value = '';
    this.startMinute.nativeElement.value = '';
    this.endMinute.nativeElement.value = '';
  }


  AddDateCondition() {
    this.startDateConditionsText = '';
    this.endDateConditionsText = '';
    if (this.startYear.nativeElement.value != '')
      this.startDateConditionsText = this.startYear.nativeElement.value;

    if (this.startMonth.nativeElement.value != '') {
      if (this.startMonth.nativeElement.value.length == 1)
        this.startMonth.nativeElement.value = '0' + this.startMonth.nativeElement.value;

      if (this.startDateConditionsText != '')
        this.startDateConditionsText += '-' + this.startMonth.nativeElement.value;
      else
        this.startDateConditionsText = '****-' + this.startMonth.nativeElement.value;
    }

    if (this.startDay.nativeElement.value != '') {
      if (this.startDay.nativeElement.value.length == 1)
        this.startDay.nativeElement.value = '0' + this.startDay.nativeElement.value;

      if (this.startDateConditionsText != '') {
        if (this.startDateConditionsText.length < 5)
          this.startDateConditionsText += '-**'
        this.startDateConditionsText += '-' + this.startDay.nativeElement.value;
      }
      else
        this.startDateConditionsText = '****-**-' + this.startDay.nativeElement.value;
    }

    if (this.startHour.nativeElement.value != '') {
      if (this.startHour.nativeElement.value.length == 1)
        this.startHour.nativeElement.value = '0' + this.startHour.nativeElement.value;

      if (this.startDateConditionsText != '') {
        if (this.startDateConditionsText.length < 5)
          this.startDateConditionsText += '-**-**';
        if (this.startDateConditionsText.length < 9)
          this.startDateConditionsText += '-**';
        this.startDateConditionsText += ' ' + this.startHour.nativeElement.value;
      }
      else
        this.startDateConditionsText = '****-**-** ' + this.startHour.nativeElement.value;
    }

    if (this.startMinute.nativeElement.value != '') {
      if (this.startMinute.nativeElement.value.length == 1)
        this.startMinute.nativeElement.value = '0' + this.startMinute.nativeElement.value;

      if (this.startDateConditionsText != '') {
        if (this.startDateConditionsText.length < 5)
          this.startDateConditionsText += '-**-** **';
        if (this.startDateConditionsText.length < 9)
          this.startDateConditionsText += '-**';
        if (this.startDateConditionsText.length < 11)
          this.startDateConditionsText += ' **';

        this.startDateConditionsText += ':' + this.startMinute.nativeElement.value;
      }
      else
        this.startDateConditionsText = '****-**-** **:' + this.startMinute.nativeElement.value;
    }

    if (this.endYear.nativeElement.value != '')
      this.endDateConditionsText = this.endYear.nativeElement.value;

    if (this.endMonth.nativeElement.value != '') {
      if (this.endMonth.nativeElement.value.length == 1)
        this.endMonth.nativeElement.value = '0' + this.endMonth.nativeElement.value;

      if (this.endDateConditionsText != '')
        this.endDateConditionsText += '-' + this.endMonth.nativeElement.value;
      else
        this.endDateConditionsText = '****-' + this.endMonth.nativeElement.value;
    }

    if (this.endDay.nativeElement.value != '') {
      if (this.endDay.nativeElement.value.length == 1)
        this.endDay.nativeElement.value = '0' + this.endDay.nativeElement.value;

      if (this.endDateConditionsText != '') {
        if (this.endDateConditionsText.length < 5)
          this.endDateConditionsText += '-**'
        this.endDateConditionsText += '-' + this.endDay.nativeElement.value;
      }
      else
        this.endDateConditionsText = '****-**-' + this.endDay.nativeElement.value;
    }

    if (this.endHour.nativeElement.value != '') {
      if (this.endHour.nativeElement.value.length == 1)
        this.endHour.nativeElement.value = '0' + this.endHour.nativeElement.value;

      if (this.endDateConditionsText != '') {
        if (this.endDateConditionsText.length < 5)
          this.endDateConditionsText += '-**-**';
        if (this.endDateConditionsText.length < 9)
          this.endDateConditionsText += '-**';
        this.endDateConditionsText += ' ' + this.endHour.nativeElement.value;
      }
      else
        this.endDateConditionsText = '****-**-** ' + this.endHour.nativeElement.value;
    }

    if (this.endMinute.nativeElement.value != '') {
      if (this.endMinute.nativeElement.value.length == 1)
        this.endMinute.nativeElement.value = '0' + this.endMinute.nativeElement.value;

      if (this.endDateConditionsText != '') {
        if (this.endDateConditionsText.length < 5)
          this.endDateConditionsText += '-**-** **';
        if (this.endDateConditionsText.length < 9)
          this.endDateConditionsText += '-**';
        if (this.endDateConditionsText.length < 11)
          this.endDateConditionsText += ' **';

        this.endDateConditionsText += ':' + this.endMinute.nativeElement.value;
      }
      else
        this.endDateConditionsText = '****-**-** **:' + this.endMinute.nativeElement.value;
    
    }
  

    this.notifyAddDateConditions();
    this.close();
  }

  cancel() {
    this.close();
  }
}
