<jqxWindow #viewMapProfile [width]="800" [height]="600" [maxWidth]="1200" [maxHeight]="1200" [title]="'Attributes'" [animationType]='"fade"' [resizable]="true" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
  <div id="windowHeader" #windowHeader>
    <!--<span>
      <img style="cursor: pointer;" (click)="ShowPrev()" id="popImageLeft" src="../../../assets/images/icon-left-white.png" />
    </span>
    <span>
      <img style="cursor: pointer;" (click)="ShowNext()" id="popImageRight" src="../../../assets/images/icon-right-white.png" />
    </span>-->
  </div>

   <div style="overflow: hidden;" #windowContent>
      <form class="form-horizontal" style="height:100%; width:100%; " name="viewMapProfileForm" #viewMapProfileForm="ngForm" (ngSubmit)="close()">
        <div class="col-sm-4 col-sm-offset-5 text-center">
          <button id="prevbutton" style="float:left;" class="btn btn-primary btn-sm glyphicon glyphicon-triangle-left" type="button" (click)="ShowPrev()"></button>
          <button id="nextbutton" style="float:left; margin-left:5px;" class="btn btn-primary btn-sm glyphicon glyphicon-triangle-right" type="button" (click)="ShowNext()"></button>
        </div>

        <div class="form-group">
          <div style="width:99%; height:99%; margin-left:12px; margin-top:40px;" >
            <jqxGrid #profileGrid [source]="dataAdapter" [columns]="columns" [width]="getWidth()"
                     [pagerheight]="0"  [sortable]="true"
                     [altrows]="true" [enabletooltips]="true" [editable]="false" [showheader]="false"
                     [selectionmode]="'singlerow'" [autorowheight]="true" [autoheight]="true">
            </jqxGrid>
          </div>
        </div>
</form>
    </div>
</jqxWindow>
