import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild, Renderer2, ElementRef, AfterContentChecked, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { jqxComboBoxComponent } from "jqwidgets-ng/jqxcombobox";
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { jqxWindowComponent } from "jqwidgets-ng/jqxwindow";
import { jqxRadioButtonComponent } from "jqwidgets-ng/jqxradiobutton";
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { jqxTreeMapComponent } from "jqwidgets-ng/jqxtreemap";
import { AlertService, MessageSeverity } from "../../services/alert.service";
import { RECOService } from "../../services/reco.service";
import { ViewMapService } from "../../services/view-map.service";
import { Core } from "../../shared/core";
import { FormatTypeService } from "../../services/formatType.service";
import { FormatType } from "../../models/formatType.model";
import { VocabService } from "../../services/vocab.service";
import { jqxInputComponent } from "jqwidgets-ng/jqxinput";
import { jqxNotificationComponent } from "jqwidgets-ng/jqxnotification";

import OlVector from 'ol/source/Vector';
import OlCluster from 'ol/source/cluster';
import OlFeature from 'ol/Feature';
import OlPoint from 'ol/geom/Point';
import OlStyle from 'ol/style/Style';
import OlStyleFill from 'ol/style/Fill';
import OlStyleCircle from 'ol/style/Circle';
import OlStyleStroke from 'ol/style/Stroke';
import OlTileLayer from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import OlXYZ from 'ol/source/XYZ';
import WKT from 'ol/format/WKT';
import WMS from 'ol/source/TileWMS';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import GeoJSON from 'ol/format/GeoJSON';
import MVT from 'ol/format/MVT';

import { MapCondition } from "../../models/mapCondition.model";
import { MapLayer } from '../../models/mapLayer.model';
import { MapExternalLayer } from '../../models/mapExternalLayer.model';
import { delay, timeout } from 'rxjs/operators';
import { TreeMapViewModel, TreeMapDataModel } from '../../models/dataSummaryView.model';
import { MapDatasetCondition, Condition } from '../../models/mapDatasetCondition.model';
import { ViewMapGeographicalBoxComponent } from './viewMap-geographicalBox.component';
import { ViewMapDateConditionComponent } from './viewMap-dateCondition.component';
import { EmailService } from "../../services/email.service";





@Component({
  selector: 'app-viewMap-search',
  templateUrl: './viewMap-search.component.html',
  styleUrls: ['./viewMap-search.component.css']
})


export class ViewMapSearchComponent implements AfterViewInit, OnInit, AfterViewChecked {
  @ViewChild('viewMapSearch') viewMapSearch: jqxWindowComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;
  @ViewChild('comboboxDataCriteria') comboboxDataCriteria: jqxComboBoxComponent;
  @ViewChild('comboboxDataCriteriaVocab') comboboxDataCriteriaVocab: jqxComboBoxComponent;
  @ViewChild('inputDataCriteria') inputDataCriteria: jqxInputComponent;
  @ViewChild('andRadioButton') andRadioButton: jqxRadioButtonComponent;
  @ViewChild('orRadioButton') orRadioButton: jqxRadioButtonComponent;
  @ViewChild('treeMap') treeMap: jqxTreeMapComponent;
  @ViewChild("datasetGrid") datasetGrid: jqxGridComponent;
  @ViewChild('startDate') private startDate;
  @ViewChild('endDate') private endDate;
  @ViewChild('topicLinksHome') private topicLinksHome;
  @ViewChild('topicLinksTheme') private topicLinksTheme;
  @ViewChild('searchBy') private searchBy;
  @ViewChild('viewMapShowGeographicalBox') viewMapShowGeographicalBox: ViewMapGeographicalBoxComponent;
  @ViewChild('viewMapDateCondition') viewMapDateCondition: ViewMapDateConditionComponent;
  @ViewChild('east') private east;
  @ViewChild('west') private west;
  @ViewChild('north') private north;
  @ViewChild('south') private south;
  @ViewChild('msgNotification') msgNotification: jqxNotificationComponent;
  @ViewChild('msgNotificationdatacriteria') msgNotificationdatacriteria: jqxNotificationComponent;
  @ViewChild('period') period: jqxRadioButtonComponent;
  @ViewChild('season') season: jqxRadioButtonComponent;
  @ViewChild('msgNotificationdatetime') msgNotificationdatetime: jqxNotificationComponent;



  PointSource: OlVector[] = [];
  PolygonSource: OlVector[] = [];
  externalSource: WMS[] = [];
  pointLayerIndex: number = 0;
  polygonLayerIndex: number = 0;
  externalLayerIndex: number = 0;
  mainIndex: number = 1;
  totalPointLayer: number = 100;
  totalPolygonLayer: number = 100;
  totalExternalLayer: number = 500;
  layers: any = [];
  downloadCondition: MapCondition[] = [];
  loadingCount: number = 0;
  layer: OlTileLayer;
  data: OlVectorLayer
  source: OlXYZ;
  columns: any[];
  dataCriteriaAdapter: any;
  schema: any;
  conditionString: string = "";
  geoConditionText: string = "";
  currentDataCriteria: any;
  dataCriteriaConditions: any = [];
  dataCriteriaVocabAdapter: any;
  isVocab: boolean;
  mapLayers: MapLayer[] = [];
  selectedDatasets: MapDatasetCondition[] = [];
  treeMapData: any;
  treeMapDataIsChanged: boolean = false;
  tagView: any;
  treeMapLevel: number = 0; //0:TagView 1: ThemView 2: DatasetView
  formatTypes: any;
  themeView: any;
  datasetView: any;
  listenerFN: any[] = [];
  rend: any;
  datasetRecords: any;
  zoom: number = 3;
  left: number = -180;
  right: number = 180;
  bottom: number = -90;
  top: number = 90;
  currentColorIndex: number;
  currentTreemapLevel: number = 0;
  currentTheme: string;
  layerStyle: OlStyle;
  minYear: number = 1800;
  maxYear: number = 2100;
  loadingMessage: string;
  validationErrorMessage: string = "";
  validationWarningMessage: string = "";
  tagID: string = '';
  themeID: string = '';
  datasetID: string = '';
  area: string = '';


  constructor(private renderer: Renderer2, http: HttpClient, private router: Router, private alertService: AlertService, private viewMapService: ViewMapService, private formatTypeService: FormatTypeService, private vocabservice: VocabService, private ref: ChangeDetectorRef, private emailservice: EmailService) {
    this.isVocab = false;
    this.rend = renderer;
  }

  ngOnInit() {

    this.source = new OlXYZ({
     // url: '//server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}',
      url: '//server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
      crossOrigin: 'Anonymous'
    });

    this.layer = new OlTileLayer({
      source: this.source,
    });

    this.layers.push(this.layer);

    var referenceLayer = new OlTileLayer({
      source: new OlXYZ({
        url: '//server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous'
      })
    });

    this.layers.push(referenceLayer);

    //var s = new TileArcGISRest({
    //  url: 'http://gis.ices.dk/gis/rest/services/ICES_reference_layers/ICES_Areas/MapServer',
    //  params: {
    //    LAYERS: "0",
    //    FORMAT: "image/PNG"
    //  }
    //});

    //this.layers.push(new OlTileLayer({
    //  source: s
    //}));

    //var s = new WMS({
    //  url: 'http://map.ices.dk/geoserver/ices_eg/wms',
    //  params: {
    //    VERSION: "1.1.0",
    //    LAYERS: "ices_eg:ICES_AREAS_VISA_SIMPLE_5KM",
    //    FORMAT: "image/png",
    //    Style: "Default",
    //    cql_filter: "Area_Full='27.1.b'"
    //},
    //  serverType: "geoserver",
    //  transition: 0
    //});

    //this.layers.push(new OlTileLayer({
    //  source: s
    //}));

    //var s = new WMS({
    //  url: 'http://gis.ices.dk/gis/services/ICES_reference_layers/ICES_Areas/MapServer/WmsServer',
    //  params: {
    //    LAYERS: "0",
    //    FORMAT: "image/png"
    //  },
    //  serverType: "geoserver",
    //  // Countries have transparency, so do not fade tiles:
    //  transition: 0
    //});

    //this.layers.push(new OlTileLayer({
    //  source: s
    //}));

    //var clusterSource = new OlCluster({
    //    source: this.viewMapSearch.dataSource,
    //    distance: 20,
    //    geometryFunction: function (feature) {
    //        var geom = feature.getGeometry();
    //        if (geom.getType() == 'Point') {
    //            return geom;
    //        } else if (geom.getType() == 'Polygon') {
    //            return geom.getInteriorPoint();
    //        }
    //        return null;
    //    },
    //});

    // this.layers.push(this.layer);


    for (var i = 0; i < this.totalExternalLayer; i++) {
      this.externalSource.push(new WMS({
        serverType: "geoserver",
        transition: 0
      }));
      var layer1 = new OlTileLayer({
        source: this.externalSource[i]
      });
      this.layers.push(layer1);
    }

    for (var i = 0; i < this.totalPolygonLayer; i++) {
      this.PolygonSource.push(new OlVector());
      var layer1 = new OlVectorLayer({
        source: this.PolygonSource[i]
      });
      this.layers.push(layer1);
    }

    for (var i = 0; i < this.totalPointLayer; i++) {
      this.PointSource.push(new OlVector());
      var layer1 = new OlVectorLayer({
        source: this.PointSource[i]
      });
      this.layers.push(layer1);
    }

    //this.externalSource[this.externalLayerIndex].setUrl("http://gis.ices.dk/gis/services/ICES_reference_layers/ICES_Areas/MapServer/WMSServer");
    //this.externalSource[this.externalLayerIndex].updateParams({
    //  VERSION: "1.3.1",
    //  LAYERS: "0",
    //  FORMAT: "image/png",
    //  Style: "default",
    //  cql_filter: ""
    //});
    //this.layers[this.externalLayerIndex].setVisible(true);
    //this.externalLayerIndex++;
  }
  ngAfterViewChecked(): void {
    this.BindingComplete();

  }

  ngOnDestroy() {
    for (var i = 0; i < this.listenerFN.length; i++)
      this.listenerFN[i]();
    this.viewMapShowGeographicalBox.close();
  }

  getStyle() {

  }



  ngAfterViewInit(): void {
    this.viewMapShowGeographicalBox.notifyAddGeoConditions = () => {
      if (this.viewMapShowGeographicalBox.wktGeometryConditions != '')
        this.geoConditionText = this.viewMapShowGeographicalBox.wktGeometryConditions;
      else
        this.geoConditionText = this.viewMapShowGeographicalBox.referenceLayerConditionsText;
    };

    this.viewMapDateCondition.notifyAddDateConditions = () => {
      if (this.viewMapDateCondition.startDateConditionsText != '')
        this.startDate.nativeElement.value = this.viewMapDateCondition.startDateConditionsText;
      else
        this.startDate.nativeElement.value = '';
      if (this.viewMapDateCondition.endDateConditionsText != '')
        this.endDate.nativeElement.value = this.viewMapDateCondition.endDateConditionsText;
      else
        this.endDate.nativeElement.value = '';
    };

    this.viewMapShowGeographicalBox.notifyAddReferenceLayers = (url: string, layerDefs: string) => {
      this.notifyviewMapSearchAddReferenceLayers(url, layerDefs);
    }

    this.viewMapShowGeographicalBox.notifyRemoveReferenceLayers = (url: string, layerDefs: string) => {
      this.notifyviewMapSearchRemoveReferenceLayers(url, layerDefs);
    }

  }

  createTreeMap(parent: string) {
    var colorList = ["#002b5f", "#28b3e8", "#00b29d", "#047c6c", "#aec63e", "#ed5f26", "#314e70", "#7081c4", "#0a5e2c"];
    var colorIndex = 0;
    var treeMapViews: TreeMapViewModel[] = [];
    if (parent == "") {
      if (this.tagView != null) {
        this.treeMapLevel = 0;
        for (var i = 0; i < this.tagView.length; i++) {
          var tmv = new TreeMapViewModel();
          tmv.data = new TreeMapDataModel();
          tmv.label = this.tagView[i].tag;
          tmv.value = this.tagView[i].tagID;
          if (colorIndex >= colorList.length)
            colorIndex = 0;
          tmv.color = colorList[colorIndex++];
          tmv.data.firstYear = this.tagView[i].firstYear;
          tmv.data.lastYear = this.tagView[i].lastYear;
          tmv.data.totalYears = this.tagView[i].totalYears;
          tmv.data.totalEvents = this.tagView[i].totalEvents.toLocaleString();
          tmv.data.link = this.router.url + '?tagid=' + this.tagView[i].tagID;
          tmv.label += ', <br />Total events: ' + this.tagView[i].totalEvents.toLocaleString();
          treeMapViews.push(tmv);
        }
      }
    }
    else {
      if (this.treeMapLevel == 0) {
        this.treeMapLevel = 1;
        var list: any[] = this.themeView.filter(
          s => s.tag == parent
        );
        colorIndex = 0;
        for (var i = 0; i < this.tagView.length; i++) {
          if (this.tagView[i].tag == parent)
            break;
          colorIndex++;
        }
        this.currentColorIndex = colorIndex;
        if (list != null) {
          for (var i = 0; i < list.length; i++) {
            var tmv = new TreeMapViewModel();
            tmv.label = list[i].theme;
            tmv.data = new TreeMapDataModel();
            //tmv.value = list[i].totalYears;
            tmv.value = list[i].themeID;
            tmv.color = this.brightColor(colorList[colorIndex], 10 * (i + 1));
            tmv.data.firstYear = list[i].firstYear;
            tmv.data.lastYear = list[i].lastYear;
            tmv.data.totalYears = list[i].totalYears;
            tmv.data.totalEvents = list[i].totalEvents.toLocaleString();
            tmv.data.link = this.router.url + '?themeid=' + list[i].themeID;
            tmv.label += ', <br />Total events: ' + list[i].totalEvents.toLocaleString();
            treeMapViews.push(tmv);
          }
        }
      }
      else
        if (this.treeMapLevel == 1) {
          this.treeMapLevel = 2;
          var list: any[] = this.datasetView.filter(
            s => s.theme == parent
          );

          if (list != null) {
            for (var i = 0; i < list.length; i++) {
              var tmv = new TreeMapViewModel();
              tmv.label = list[i].dataset;
              tmv.data = new TreeMapDataModel();
              //tmv.value = list[i].totalYears;
              tmv.value = list[i].datasetID;
              tmv.color = this.brightColor(colorList[this.currentColorIndex], 10 * (i + 1));
              tmv.data.firstYear = list[i].firstYear;
              tmv.data.lastYear = list[i].lastYear;
              tmv.data.totalYears = list[i].totalYears;
              tmv.data.totalEvents = list[i].totalEvents.toLocaleString();
              tmv.data.link = this.router.url + '?datasetid=' + list[i].datasetID;
              tmv.label += ', <br />Total events: ' + list[i].totalEvents.toLocaleString();
              treeMapViews.push(tmv);
            }
          }
        }
    }
    if (treeMapViews.length > 0) {
      let source: any =
      {
        datatype: 'json',
        datafields: [
          { name: 'label', type: 'string' },
          { name: 'value', type: 'string' },
          { name: 'color', type: 'string' },
          { name: 'data' }
        ],
        localdata: treeMapViews
      };
      this.treeMapData = new jqx.dataAdapter(source);
      this.treeMapDataIsChanged = true;

    }
  }


  brightColor(color, percent) {
    var ctx = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    var co = ctx.getImageData(0, 0, 1, 1);
    var r = co.data[0] - Math.floor(percent / 100 * 255);
    if (r < 0)
      r = 0;
    var g = co.data[1] - Math.floor(percent / 100 * 255);
    if (g < 0)
      g = 0;
    var b = co.data[2] - Math.floor(percent / 100 * 255);
    if (b < 0)
      b = 0;
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }


  groupBy(arr, key) {
    var newArr = [], types = {}, newItem, i, j, cur;
    for (i = 0, j = arr.length; i < j; i++) {
      cur = arr[i];
      if (!(cur[key] in types)) {
        types[cur[key]] = { type: cur[key], data: [] };
        newArr.push(types[cur[key]]);
      }
      types[cur[key]].data.push(cur);
    }
    return newArr;
  }

  backHome() {
    this.createTreeMap("");
    this.currentTreemapLevel = 0;
    this.currentTheme = '';
    var topicLinksHome = document.getElementById('topicLinksHome');
    topicLinksHome.setAttribute("style", "visibility:hidden;");
    var topicLinksTheme = document.getElementById('topicLinksTheme');
    topicLinksTheme.setAttribute("style", "visibility:hidden;");
  }

  backTheme() {
    var topicLinksTheme = document.getElementById('topicLinksTheme');
    topicLinksTheme.setAttribute("style", "visibility:hidden;");
    this.treeMapLevel = 0;
    this.createTreeMap(this.currentTheme);
    this.currentTreemapLevel = 1;
  }

  BindingComplete() {
    if (this.treeMapDataIsChanged) {
      for (var i = 0; i < this.treeMap.elementRef.nativeElement.childNodes[0].childNodes.length; i++) {
        this.listenerFN.push(this.rend.listen(this.treeMap.elementRef.nativeElement.childNodes[0].childNodes[i], 'click',
          (evt) => {
            this.treeMapClick(evt);
          }));
      }
      this.treeMapDataIsChanged = false;
    }
  }

  treeMapClick(arg: any): void {
    var str = arg.currentTarget.firstChild.childNodes[2].childNodes[0].childNodes[0].data.trimLeft().split(",", 2);
    if (!(
      (arg.originalTarget != undefined && arg.originalTarget.className == "fa fa-search-plus") ||
      (arg.originalTarget != undefined && arg.originalTarget.firstChild != undefined && arg.originalTarget.firstChild.className == "fa fa-search-plus") ||
      (arg.toElement != undefined && arg.toElement.className == "fa fa-search-plus") ||
      (arg.toElement != undefined && arg.toElement.localName == "input") ||
      (arg.originalTarget != undefined && arg.originalTarget.localName == "input") ||
      (arg.target != undefined && arg.target.className == "fa fa-search-plus") ||
      (arg.target != undefined && arg.target.localName == "input")

    ))
      return;
    if ((arg.originalTarget != undefined && arg.originalTarget.className == "fa fa-search-plus")
      || (arg.originalTarget != undefined && arg.originalTarget.firstChild != undefined && arg.originalTarget.firstChild.className == "fa fa-search-plus")
      || (arg.toElement != undefined && arg.toElement.className == "fa fa-search-plus")
      || (arg.target != undefined && arg.target.className == "fa fa-search-plus")
    ) {
      if (str.length == 2)
        this.createTreeMap(str[0]);

      if (this.currentTreemapLevel < 2) {
        this.msgNotification.closeAll();
        this.msgNotification.open();
        this.currentTreemapLevel++;
        if (this.currentTreemapLevel == 1) {
          var topicLinksHome = document.getElementById('topicLinksHome');
          topicLinksHome.setAttribute("style", "visibility:visible;");
          //this.topicLinksHome.nativeElement.children[0].childNodes[0].nodeValue = str[0];
          this.currentTheme = str[0];
        }
        if (this.currentTreemapLevel == 2) {
          var topicLinksTheme = document.getElementById('topicLinksTheme');
          topicLinksTheme.setAttribute("style", "visibility:visible;");
          this.topicLinksTheme.nativeElement.children[0].childNodes[0].nodeValue = this.currentTheme;

        }
      }
    }
    else
      if ((arg.toElement != undefined && arg.toElement.localName == "input") ||
        (arg.originalTarget != undefined && arg.originalTarget.localName == "input") ||
        (arg.target != undefined && arg.target.localName == "input")
      ) {
        var check;
        if (arg.toElement != undefined && arg.toElement.localName == "input")
          check = arg.toElement.checked;
        else
          if (arg.originalTarget != undefined && arg.originalTarget.localName == "input")
            check = arg.originalTarget.checked;
          else
            if (arg.target != undefined && arg.target.localName == "input")
              check = arg.target.checked;


        if (this.currentTreemapLevel == 0) {
          var themes: any[] = this.themeView.filter(
            s => s.tag == str[0]
          );
          if (themes != null) {
            for (var i = 0; i < themes.length; i++) {
              var datasets: any[] = this.datasetView.filter(
                s => s.themeID == themes[i].themeID
              );
              if (datasets != null) {
                for (var j = 0; j < datasets.length; j++) {
                  var rows = this.datasetGrid.getrows();
                  for (var k = 0; k < rows.length; k++) {
                    if (rows[k].datasetID == datasets[j].datasetID)
                      if (check)
                        this.datasetGrid.selectrow(rows[k].uid);
                      else
                        this.datasetGrid.unselectrow(rows[k].uid);
                  }
                }
              }
            }
          }
        }
        else
          if (this.currentTreemapLevel == 1) {
            var datasets: any[] = this.datasetView.filter(
              s => s.theme == str[0]
            );
            if (datasets != null) {
              for (var j = 0; j < datasets.length; j++) {
                var rows = this.datasetGrid.getrows();
                for (var k = 0; k < rows.length; k++) {
                  if (rows[k].datasetID == datasets[j].datasetID)
                    if (check)
                      this.datasetGrid.selectrow(rows[k].uid);
                    else
                      this.datasetGrid.unselectrow(rows[k].uid);
                }
              }
            }

          }
          else
            if (this.currentTreemapLevel == 2) {
              var rows = this.datasetGrid.getrows();
              for (var k = 0; k < rows.length; k++) {
                if (rows[k].dataset == str[0])
                  if (check)
                    this.datasetGrid.selectrow(rows[k].uid);
                  else
                    this.datasetGrid.unselectrow(rows[k].uid);
              }
            }

      }

  }

  renderCallbacks: any =
    {
      '*': (elementObject: any, dataElement: any) => {
        var foreColor = 'White';
        elementObject.css({
          color: foreColor
        });
        //TODO: check condition list when select a theme for double check


        //elementObject.html('<span style="font-size: 13px;" class="jqx-treemap-label">' + dataElement.label + '</span> <a href="' + dataElement.data.link + '" target="_blank" class=" button" title="View on map" ><i class="fas fa-eye" style="font-size:20px; color:cornsilk; cursor: pointer;"></i></a>');
        //elementObject.html('<span style="font-size: 13px;" class="jqx-treemap-label">' + dataElement.label + '</span> <img src="/image/themes/Biodiveristy.png">');
        var checkStatus = false;
        var html = "";
        if (this.currentTreemapLevel == 0) {
          html =
            '<div style="margin-top: 18px;"> ' +
            '<img style="width:120px; height:120px; background-color: white;margin-left: auto;margin-right: auto;display: block;" src="/image/themes/' + dataElement.label.split(",")[0] + '.png">' +
            '<div style="width: 100%; height:50px;text-align: center; margin-top: 5px;"><span style="font-size: 13px;" class= "jqx-treemap-label" > ' + dataElement.label + '</span></div></div> ' +
            '</div> <div style="width: 100%; height:40px; position: absolute; bottom: 0px;"> <button style="background-color: transparent; font-size:15px; bottom: 0px; position: absolute; right: 0;" type="button" cursor: pointer; title="Click on this button to see themes on this topic"><i class="fa fa-search-plus" aria-hidden="true"></i></button></div>';
          elementObject.html(html);
        }
        else
          if (this.currentTreemapLevel == 1) {
            if (this.selectedDatasets != undefined && this.selectedDatasets.length > 0) {
              var datasets: any[] = this.selectedDatasets.filter(
                s => s.theme == dataElement.label.split(",")[0]
              );
              if (datasets != null && datasets.length != 0)
                checkStatus = true;
            }
            html =
              '<div style="width: 100%; height:70%; float: left;"> ' +
              '<img style="width:80px; height:80px; background-color: white;margin-left: auto;margin-right: auto;display: block;" src="/image/themes/' + this.currentTheme + '.png">' +
              '<div style="width: 100%; height:50px;text-align: center; margin-top: 5px;"><span style="font-size: 13px;" class= "jqx-treemap-label" > ' + dataElement.label + '</span></div> </div></div> ' +
              '<div style="width: 100%; height:15%; position: static; padding-left: 3px;"> <input style="width:15px; height:15px;" title="Please check this box if you want select this theme" type="checkbox" ';
            if (checkStatus)
              html += ' checked ';
            html += '> </div> <div style="width: 100%; height:40px; position: absolute; bottom: 0px;"> <button style="background-color: transparent; font-size:15px; bottom: 0px; position: absolute; right: 0;" type="button" cursor: pointer; title="Click on this button to see datasets on this theme"><i class="fa fa-search-plus" aria-hidden="true"></i></button></div>';
            elementObject.html(html);
          }
          else
            if (this.currentTreemapLevel == 2) {
              if (this.selectedDatasets != undefined && this.selectedDatasets.length > 0) {
                var datasets: any[] = this.selectedDatasets.filter(
                  s => s.dataset == dataElement.label.split(",")[0]
                );
                if (datasets != null && datasets.length != 0)
                  checkStatus = true;
              }
              html =
                '<div style="width: 100%; height:70%; float: left;"> ' +
                '<img style="width:80px; height:80px; background-color: white;margin-left: auto;margin-right: auto;display: block;" src="/image/themes/' + this.currentTheme + '.png">' +
                '<div style="width: 100%; height:50px;text-align: center; margin-top: 5px;"><span style="font-size: 13px;" class= "jqx-treemap-label" > ' + dataElement.label + '</span></div> </div></div> ' +
                '<div style="width: 100%; height:15%; position: static; padding-left: 3px;"> <input style="width:15px; height:15px;" title="Please check this box if you want select this dataset" type="checkbox" ';
              if (checkStatus)
                html += ' checked ';
              html += '> </div> ';
              elementObject.html(html);
            }

        if (dataElement.data != undefined) {
          elementObject.jqxTooltip({
            content: '<div> ' +
              '<div style="max-width: 200px; font-family: verdana; font-size: 13px;white-space: pre;"><span> ' +
              dataElement.label +
              ' <br />First year: ' + dataElement.data.firstYear +
              ' <br />Last year: ' + dataElement.data.lastYear +
              ' <br />Years with data: ' + dataElement.data.totalYears +
              '</span></div></div>',
            position: 'mouse',
            autoHideDelay: 6000
          });
        }
        return true;
      }
    };

  public notifyUpdateMap: () => void;
  public notifyStartUpdateMap: () => void;
  public notifyviewMapSearchAddReferenceLayers: (url: string, layerDefs: string) => void;
  public notifyviewMapSearchRemoveReferenceLayers: (url: string, layerDefs: string) => void;



  UpdateSearchMap() {
    this.validationErrorMessage = "";
    this.validationWarningMessage = "";
    this.addCondition();
    this.pointLayerIndex = 0;
    this.polygonLayerIndex = 0;
    this.externalLayerIndex = 0;
    var len = this.mapLayers.length;
    this.mapLayers = [];

    this.getDateCondition();
    this.getDateCriteriaCondition();
    this.getBoundaryCondition();
    this.getReferenceLayerCondition();
    this.getwktGeometryConditions();

    if (this.validationErrorMessage != "") {
      this.alertService.showMessage(this.validationErrorMessage, "", MessageSeverity.error);
      return;
    }

    if (this.validationWarningMessage != "") {
      if (!window.confirm(this.validationWarningMessage))
        return;
    }


    this.close();
    var index = 0;
    for (var i = 0; i < this.PointSource.length; i++)
      this.PointSource[i].clear();
    for (var i = 0; i < this.PolygonSource.length; i++)
      this.PolygonSource[i].clear();
    for (var i = 0; i < this.externalSource.length; i++) {
      this.externalSource[i].setUrl('');
      this.externalSource[this.externalLayerIndex].updateParams({});
    }

    for (var i = 2; i < this.layers.length; i++)
      this.layers[i].setVisible(false);

    var list = this.viewMapShowGeographicalBox.mapLayers.filter(s => s.visible);
    if (list.length == 0)
      this.mainIndex = 2;
    else {
      for (var i = 0; i < list.length; i++)
        this.notifyviewMapSearchAddReferenceLayers(list[i].mapExternalLayers[0].url, list[i].mapExternalLayers[0].filter);
      this.mainIndex = list.length + 2;
    }

    if (this.viewMapShowGeographicalBox.source != undefined) {
      var mapLayer = new MapLayer();
      mapLayer.visible = true;
      mapLayer.sourceType = 1;
      mapLayer.layerIndex = this.polygonLayerIndex;
      this.polygonLayerIndex++;
      mapLayer.mainIndex = this.totalExternalLayer + mapLayer.layerIndex + 2;
      this.mainIndex++
      var feature = this.viewMapShowGeographicalBox.source.getFeatures();
      this.PolygonSource[mapLayer.layerIndex].addFeatures(feature);
      this.mapLayers.push(mapLayer);
      this.layers[mapLayer.mainIndex].setVisible(true);
      this.layers[mapLayer.mainIndex].setStyle(feature.style);
    }

    interface IInterface {
      run(feature: any);
    }
    this.downloadCondition = [];
    this.estimateLoading();
    this.loadingCount = this.selectedDatasets.length;
    if (this.selectedDatasets.length > 0)
      if (this.loadingMessage != '')
        this.alertService.startLoadingMessage(this.loadingMessage);
      else
        this.alertService.startLoadingMessage();
    this.notifyStartUpdateMap();

    for (let item of this.selectedDatasets) {
      let wkt = new WKT();
      var condition = new MapCondition();
      condition.formatTypeID = item.formatTypeID;
      var conditionString = "";
      var dataCriteriaCondition = "";
      var geoCondition = "";
      for (let co of item.conditions)
        if (co.text != undefined && co.text != "") {
          if (co.conditionTypeID != 5 && co.conditionTypeID != 2) {
            conditionString = conditionString + co.text + " and ";
            if (co.conditionTypeID == 3)
              dataCriteriaCondition = dataCriteriaCondition + co.text + " and ";
          }
          else
            if (co.conditionTypeID == 5) {
              conditionString = conditionString + " (GeometryType <> 0 or geometry::STGeomFromText('" + co.text + "', 4326).STContains(geometry::Point(Longitude, Latitude, 4326)) = 1) and ";
              geoCondition = co.text;
            }
            else
              if (co.conditionTypeID == 2) {
                conditionString = conditionString + " (GeometryType <> 0 or " + co.text + ") and ";
                geoCondition = " GEOMETRYCOLLECTION(" + co.description + ") ";
              }

        }

      conditionString = conditionString.substring(0, conditionString.length - 5);
      if (dataCriteriaCondition.length > 0)
        dataCriteriaCondition = dataCriteriaCondition.substring(0, dataCriteriaCondition.length - 5);
      condition.ids.push(conditionString);
      condition.conditionText = JSON.stringify(item.conditions);
      condition.dataCriteriaCondition = dataCriteriaCondition;
      condition.datasetID = item.datasetID;
      this.downloadCondition.push(condition);
      item.firstYear = 2100;
      item.lastYear = 1800;
      item.totalEvents = 0;

      this.viewMapService.getSummaryData(item.formatTypeID, conditionString, this.zoom, this.left, this.right, this.bottom, this.top).pipe(timeout(3600000)).subscribe(
        resultData => {
          var listData = <Array<any>>resultData;
          var features = [];
          if (listData.length > 0) {
            var mapLayer = new MapLayer();
            var formatType = this.formatTypes.localdata.filter(o => o.id == item.formatTypeID)[0];
            mapLayer.formatTypeID = item.formatTypeID;
            mapLayer.datasetID = item.datasetID;
            mapLayer.visible = true;
            this.mapLayers.push(mapLayer);
            mapLayer.layerIndex = -1;
            for (let entryData of listData) {
              try {
                if (item.firstYear > entryData.minYear)
                  item.firstYear = entryData.minYear;
                if (item.lastYear < entryData.maxYear)
                  item.lastYear = entryData.maxYear;
                item.totalEvents = entryData.totalEvents;
                //TODO: For external layer need to update based on filtering

                if (entryData.geometryType == 0) {
                  if (mapLayer.layerIndex == -1)
                    if (entryData.geometry.toString().includes('GEOMETRYCOLLECTION') || entryData.geometry.toString().includes('POLYGON')) {
                      mapLayer.layerIndex = this.polygonLayerIndex;
                      mapLayer.sourceType = 1;
                      mapLayer.mainIndex = this.totalExternalLayer + mapLayer.layerIndex + 2;
                      this.polygonLayerIndex++;
                    }
                    else
                      if (entryData.geometry.toString().includes('POINT')) {
                        mapLayer.layerIndex = this.pointLayerIndex;
                        mapLayer.sourceType = 0;
                        mapLayer.mainIndex = this.totalExternalLayer + this.totalPolygonLayer + mapLayer.layerIndex + 2;
                        this.pointLayerIndex++;
                      }
                  var feauture = wkt.readFeatures(entryData.geometry, true);
                  feauture[0].getGeometry().transform('EPSG:4326', 'EPSG:3857');
                  feauture[0].mapFeatureParameter = entryData.mapFeatureParameter;
                  feauture[0].mapFeatureTooltip = entryData.mapFeatureTooltip;
                  feauture[0].mapFeatureText = entryData.mapFeatureText;
                  var ids = [];
                  for (let entity of entryData.entities)
                    ids.push(entity.summaryDataID);
                  feauture[0].id = ids;
                  features.push(feauture[0]);
                }
                else
                  if (entryData.geometryType == 1) {
                    mapLayer.sourceType = 2;
                    for (let filter of entryData.geometryFilters) {
                      var mel = new MapExternalLayer();
                      mel.url = entryData.geometryLayerURL.replace("http:", "https:");
                      mel.layer = entryData.geometryLayerName;
                      mel.style = entryData.geometryLayerStyle;
                      mel.filter = filter[0].toString().trim();
                      mel.value = filter[1].toString().trim();
                      for (let entity of entryData.entities)
                        mel.ids.push(entity.summaryDataID);

                      var mapExternalLayer = mapLayer.mapExternalLayers.filter(o =>
                        o.url == mel.url
                        && o.layer == mel.layer
                        && o.filter == mel.filter
                        && o.value == mel.value
                        && o.style == mel.style)[0];
                      if (mapExternalLayer == null)
                        mapLayer.mapExternalLayers.push(mel);
                      else
                        mapExternalLayer.ids.push(mel.ids[0]);
                    }
                  }
              } catch (e) {
                this.alertService.showMessage('Unable to load geometry', Core.getHttpResponseMessage('', e), MessageSeverity.error);
              }
            }
            //this.AddFeatures(features);
            if (mapLayer.sourceType == 0 || mapLayer.sourceType == 1) {
              if (mapLayer.sourceType == 0)
                this.PointSource[mapLayer.layerIndex].addFeatures(features);
              else
                this.PolygonSource[mapLayer.layerIndex].addFeatures(features);
              var f: IInterface = eval(formatType.mapStyle);
              var style = function (feature) {
                return f.run(feature)[0];
              };
              this.layers[mapLayer.mainIndex].setStyle(style);
              this.layers[mapLayer.mainIndex].setVisible(true);

              //var layerStyle = this.layers[mapLayer.mainIndex].getStyleFunction();
              //var featureStyle = layerStyle(features[0]);
              //var image = featureStyle.getImage();
              //if (image) {
              //  var title = this.layers[mapLayer.mainIndex].get('title');
              //}

              ////append table
              //var legend = document.getElementById('legend');
              //var img = image.canvas_.toDataURL("image/png");
              //legend.style.backgroundImage = "url(" + img + ")";
            }
            else {
              //TODO: For each url, layer and filter (Name of filter attribute) create only one WMS call
              var fi = "";
              var finalLayers: MapExternalLayer[] = [];
              for (let mapExternalLayer of mapLayer.mapExternalLayers) {
                var mel = finalLayers.filter(o =>
                  o.url == mapExternalLayer.url
                  && o.layer == mapExternalLayer.layer
                  && o.filter == mapExternalLayer.filter
                  && o.style == mapExternalLayer.style)[0];
                if (mel == null) {
                  mel = new MapExternalLayer();
                  mel.url = mapExternalLayer.url;
                  mel.layer = mapExternalLayer.layer;
                  mel.style = mapExternalLayer.style;
                  mel.filter = mapExternalLayer.filter;
                  mel.value = "'" + mapExternalLayer.value + "',";
                  finalLayers.push(mel);
                }
                else {
                  mel.value += "'" + mapExternalLayer.value + "',";
                  mel.ids = mel.ids.concat(mapExternalLayer.ids);
                }
              }

              for (let fl of finalLayers) {
                fl.value = fl.value.substring(0, fl.value.length - 1);
                this.externalSource[this.externalLayerIndex].setUrl(fl.url);
                var filter = fl.filter + " in (" + fl.value + ")";
                if (geoCondition != "") {
                  filter = filter + " and INTERSECTS(Shape, " + geoCondition + ") ";
                }
                this.externalSource[this.externalLayerIndex].updateParams({
                  VERSION: "1.1.0",
                  LAYERS: fl.layer,
                  FORMAT: "image/png",
                  Styles: fl.style,
                  cql_filter: filter
                });
                mapLayer.layerIndex = this.externalLayerIndex;
                mapLayer.mainIndex = this.externalLayerIndex + 2;
                this.layers[mapLayer.mainIndex].setVisible(true);
                this.externalLayerIndex++;
              }
            }
          }
          this.loadingCount--;

          if (this.loadingCount != undefined && this.loadingCount <= 0) {
            this.alertService.stopLoadingMessage();
            this.notifyUpdateMap();
          }
        },
        error => {
          this.alertService.showMessage('Unable to load getSummaryData', Core.getHttpResponseMessage('', error), MessageSeverity.error);
          this.alertService.stopLoadingMessage();
          this.emailservice.sentError('UpdateSearchMap failed: ' + Core.getHttpResponseMessage('', error)).subscribe();
        });
    }


    if (this.selectedDatasets.length == 0)
      this.notifyUpdateMap();

  }

  estimateLoading() {
    var totalEvents = 0;
    for (let item of this.selectedDatasets) {
      totalEvents += item.totalEvents;
    }
    this.loadingMessage = '';
    if (((this.maxYear - this.minYear) * this.selectedDatasets.length > 30) || totalEvents > 100000)
      this.loadingMessage = 'Selected large data criteria may cause slowness of loading result';
  }


  UpdateClusterMap() {
    if (this.mapLayers.filter(s => s.formatTypeID != undefined).length == 0) return;
    this.loadingCount = 0;
    for (let mapLayer of this.mapLayers) {
      if (mapLayer.mapExternalLayers.length == 0 && mapLayer.formatTypeID != undefined) {
        let wkt = new WKT();
        var condition = new MapCondition();
        condition.formatTypeID = mapLayer.formatTypeID;
        var item = this.selectedDatasets.filter(s => s.formatTypeID == mapLayer.formatTypeID && s.datasetID == mapLayer.datasetID)[0];
        var conditionString = "";
        for (let co of item.conditions)
          if (co.text != undefined && co.text != "") {
            if (co.conditionTypeID != 5)
              conditionString = conditionString + co.text + " and ";
            else
              conditionString = conditionString + " geometry::STGeomFromText('" + co.text + "', 4326).STContains(geometry::Point(Longitude, Latitude, 4326)) = 1 and ";
          }

        conditionString = conditionString.substring(0, conditionString.length - 5);
        this.estimateLoading();
        if (this.loadingMessage != '')
          this.alertService.startLoadingMessage(this.loadingMessage);
        else
          this.alertService.startLoadingMessage();
        this.loadingCount++;
        this.viewMapService.getSummaryData(condition.formatTypeID, conditionString, this.zoom, this.left, this.right, this.bottom, this.top).pipe(timeout(3600000)).subscribe(
          resultData => {
            var listData = <Array<any>>resultData;
            var features = [];
            if (listData.length > 0) {
              for (let entryData of listData) {
                try {
                  var feauture = wkt.readFeatures(entryData.geometry, true);
                  feauture[0].getGeometry().transform('EPSG:4326', 'EPSG:3857');
                  feauture[0].mapFeatureParameter = entryData.mapFeatureParameter;
                  feauture[0].mapFeatureTooltip = entryData.mapFeatureTooltip;
                  feauture[0].mapFeatureText = entryData.mapFeatureText;
                  var ids = [];
                  for (let entity of entryData.entities)
                    ids.push(entity.summaryDataID);
                  feauture[0].id = ids;
                  features.push(feauture[0]);

                  for (let entity of entryData.entities) {
                    var splitted = entity.localID.split("_");
                    var id = "";
                    for (var k = 1; k < splitted.length; k++)
                      id = id.concat(splitted[k] + "_");
                    condition.ids.push(id.substring(0, id.length - 1))
                  }
                } catch (e) {
                  this.alertService.showMessage('Unable to load geometry', Core.getHttpResponseMessage('', e), MessageSeverity.error);
                }
              }
              if (mapLayer.sourceType == 0) {
                this.PointSource[mapLayer.layerIndex].clear();
                this.PointSource[mapLayer.layerIndex].addFeatures(features);
              }
              else
                if (mapLayer.sourceType == 1) {
                  this.PolygonSource[mapLayer.layerIndex].clear();
                  this.PolygonSource[mapLayer.layerIndex].addFeatures(features);
                }
            }
            this.loadingCount--;
            if (this.loadingCount == 0) {
              this.alertService.stopLoadingMessage();
              this.notifyUpdateMap();
            }
          },
          error => {
            this.alertService.showMessage('Unable to load getSummaryData', Core.getHttpResponseMessage('', error), MessageSeverity.error);
            this.alertService.stopLoadingMessage();
            this.emailservice.sentError('UpdateClusterMap failed: ' + Core.getHttpResponseMessage('', error)).subscribe();

          });
      }
    }
  }

  open() {
    //this.viewMapSearch.position({ x: (screen.width / 2) - 550, y: (screen.height / 2) - 375 });
    this.viewMapSearch.open();
    this.tabs.select(0);
    var date = new Date();
    if (this.startDate.nativeElement.value == '')
      this.startDate.nativeElement.value = date.getFullYear();
    if (this.endDate.nativeElement.value == '')
      this.endDate.nativeElement.value = date.getFullYear();
    if (this.east.nativeElement.value == '')
      this.east.nativeElement.value = '180';
    if (this.west.nativeElement.value == '')
      this.west.nativeElement.value = '-180';
    if (this.north.nativeElement.value == '')
      this.north.nativeElement.value = '90';
    if (this.south.nativeElement.value == '')
      this.south.nativeElement.value = '-90';
    this.currentDataCriteria = null;
    this.comboboxDataCriteria.clearSelection();
    this.setaddConditionVisble("hidden");
    this.comboboxDataCriteriaOnChange();

    this.showParam();

    if (this.selectedDatasets != undefined) {
      for (var i = 0; i < this.selectedDatasets.length; i++) {
        var row = this.datasetRecords.originaldata.filter(s => s.datasetID == this.selectedDatasets[i].datasetID);
        this.datasetGrid.selectrow(row[0].uid);
      }
    }

  }


  close() {
    this.viewMapSearch.hide();
  }

  tabclick(event: any): void {
    if (event.args.item == 2) {
      var sb = '';
      var items: any[] = [];
      let rows = this.datasetGrid.getselectedrowindexes();
      for (var i = 0; i < rows.length; i++) {
        var row = this.datasetGrid.getrowdata(rows[i]);
        var formatTypeList = this.formatTypes.localdata.filter(o => o.id == row.formatTypeID);
        sb += row.dataset + ', ';
        for (let formatType of formatTypeList)
          items.push(formatType)
      }
      var result = []

      if (items.length != 0) {
        sb = sb.substring(0, sb.length - 2);
        this.searchBy.nativeElement.innerHTML = sb;
        for (var i = 0; i < items.length; i++) {
          if (items[i] != undefined) {
            for (var j = 0; j < this.schema.localdata.length; j++) {
              var filters = this.schema.localdata[j].dataCriteriaNames.filter(o => o.formatTypeID == items[i].id);
              if (filters.length > 0 && result.filter(r => r.id == this.schema.localdata[j].id).length == 0)
                result.push(this.schema.localdata[j]);
            }
          }
        }
      }
      else {
        result = [];
      }
      this.dataCriteriaAdapter = new jqx.dataAdapter(result);


    }
  }


  comboboxDataCriteriaOpen(event: any) {
    let rows = this.datasetGrid.getselectedrowindexes();
    if (rows.length == 0) {
      this.msgNotificationdatacriteria.closeAll();
      this.msgNotificationdatacriteria.open();
    }
  }

  DateRadioGroupOnChecked() {
    if (this.msgNotificationdatetime != undefined) {
      this.msgNotificationdatetime.closeAll();
      this.msgNotificationdatetime.open();
    }
  }


  comboboxDataCriteriaOnChange() {
    let item = this.comboboxDataCriteria.getSelectedItem();
    if (item != null) {
      //Create code list combobox
      this.vocabservice.getCodeType(item.originalItem.codeType).subscribe(
        result => {
          var recoList: any = [];
          recoList = result;
          var vocabCondition = document.getElementById('vocabCondition');
          vocabCondition.setAttribute("style", "visibility:hidden; width: 0px; ");
          var textCondition = document.getElementById('textCondition');
          textCondition.setAttribute("style", "visibility:hidden; width: 0px;");
          this.currentDataCriteria = item.originalItem;
          if (recoList.length > 0) {
            this.isVocab = true;
            vocabCondition.setAttribute("style", "visibility:visible; width: 500px; margin-left:20px;");
            this.dataCriteriaVocabAdapter = new jqx.dataAdapter(result);
          }
          else {
            this.isVocab = false;
            this.currentDataCriteria = item.originalItem;
            textCondition.setAttribute("style", "visibility:visible; width: 500px; margin-left:20px;");
          }
        },
        error => {
          this.alertService.showMessage('Unable to get jsonSchema', Core.getHttpResponseMessage('', error), MessageSeverity.error);
        });
    }
    else {
      var vocabCondition = document.getElementById('vocabCondition');
      vocabCondition.setAttribute("style", "visibility:hidden; width: 0px; ");
      var textCondition = document.getElementById('textCondition');
      textCondition.setAttribute("style", "visibility:hidden; width: 0px;");
    }
  }

  comboboxDataCriteriaVocabOnSelect(event: any) {
    var args = event.args;
    if (args)
      this.setaddConditionVisble("visible");
  }

  inputDataCriteriaOnChange(event: any) {
    var args = event.args;
    if (args)
      this.setaddConditionVisble("visible");
  }

  addCondition() {
    if (this.currentDataCriteria != null && document.getElementById('addCondition').style.visibility == "visible" && (this.comboboxDataCriteriaVocab.getSelectedItems().length != 0 || this.inputDataCriteria.ngValue != '')) {
      var condition: string = "";
      var conditions: string[] = [];
      if (this.isVocab) {
        var items = this.comboboxDataCriteriaVocab.getSelectedItems();
        for (let item of items) {
          condition += "[" + this.currentDataCriteria.title + "] = '" + item.originalItem.title + "' or ";
          conditions.push(item.originalItem.id);
        }
        condition = condition.substring(0, condition.length - 3);
        condition = "(" + condition + ")";
      }
      else {
        var item = this.inputDataCriteria.ngValue;
        condition = "([" + this.currentDataCriteria.title + "] like '%" + item + "%')";
        conditions.push(item.toString());
      }

      if (this.conditionString == "") {
        this.conditionString = condition;
        if (this.andRadioButton.val())
          this.dataCriteriaConditions.push([this.currentDataCriteria, conditions, "and", condition]);
        else
          this.dataCriteriaConditions.push([this.currentDataCriteria, conditions, "or", condition]);
      }
      else {
        if (this.conditionString.toString().includes("[" + this.currentDataCriteria.title + "]")) {
          //Need to add as an or condition to the current condition
          var currentCondition = this.dataCriteriaConditions.filter(s => s[0].title == this.currentDataCriteria.title);
          if (currentCondition != undefined) {
            currentCondition[0][1].concat(conditions);
            currentCondition[0][3] += " or " + condition;
          }
        }
        else {
          //it is new condition
          if (this.andRadioButton.val()) {
            this.conditionString = this.conditionString + " and " + condition;
            this.dataCriteriaConditions.push([this.currentDataCriteria, conditions, "and", condition]);
          }
          else {
            this.conditionString = this.conditionString + " or " + condition;
            this.dataCriteriaConditions.push([this.currentDataCriteria, conditions, "or", condition]);
          }

        }
      }
      this.updateconditionString();
      this.comboboxDataCriteriaVocab.clearSelection();
      this.inputDataCriteria.val('');
    }
  }

  updateconditionString() {
    this.conditionString = "";
    for (let dataCriteriaCondition of this.dataCriteriaConditions) {
      var condition = dataCriteriaCondition[3] + " " + dataCriteriaCondition[2] + "\n";
      this.conditionString += condition;
    }
  }

  setaddConditionVisble(visible: string) {
    var addCondition = document.getElementById('addCondition');
    addCondition.setAttribute("style", "visibility:" + visible + "; margin-left:20px; margin-top:20px;");
    var addCondition = document.getElementById('addCondition1');
    addCondition.setAttribute("style", "visibility:" + visible + "; margin-top:20px;");
    var addCondition = document.getElementById('addCondition2');
    addCondition.setAttribute("style", "visibility:" + visible + "; margin-left:20px; margin-top:20px;");
  }

  resetCondition() {
    this.conditionString = "";
    this.comboboxDataCriteria.clearSelection();
    this.setaddConditionVisble("hidden");
    this.dataCriteriaConditions = [];
    this.comboboxDataCriteriaOnChange();

  }
  resetgeoCondition() {
    this.east.nativeElement.value = '180';
    this.west.nativeElement.value = '-180';
    this.north.nativeElement.value = '90';
    this.south.nativeElement.value = '-90';
    if (this.viewMapShowGeographicalBox != undefined)
      this.viewMapShowGeographicalBox.clearMap();
    this.geoConditionText = '';
    this.viewMapShowGeographicalBox.viewMapShowReferenceLayer.area = '';
    this.area = '';
  }

  resetdateCondition() {
    this.startDate.nativeElement.value = "";
    this.endDate.nativeElement.value = "";
    this.viewMapDateCondition.clearAllDateCondition();


  }


  removeCondition() {
    this.resetCondition();
  }

  getDateCondition() {
    if (this.validationErrorMessage != "") return;
    var condition: string = "";
    var startCondition: string = "";
    var startYearCondition: string = "";
    var endCondition: string = "";
    var endYearCondition: string = "";

    var sYear, sMonth, sDay, sHour, sMinute;
    var sd = this.startDate.nativeElement.value;
    if (sd != undefined) {
      if (sd.substring(0, 4) > "1800")
        sYear = sd.substring(0, 4);
      if (sd.substring(5, 7) > "00")
        sMonth = +sd.substring(5, 7);
      if (sd.substring(8, 10) > "00")
        sDay = +sd.substring(8, 10);
      if (sd.substring(11, 13) > "00")
        sHour = +sd.substring(11, 13);
      if (sd.substring(14, 16) > "00")
        sMinute = +sd.substring(14, 16);

      if (sYear != undefined && (sYear < "1800" || sYear > "2100")) {
        this.validationErrorMessage = "Start year is not valid. please enter again"
        return;
      }
      if (sMonth != undefined && (sMonth < "00" || sMonth > "12")) {
        this.validationErrorMessage = "Start month is not valid. please enter again"
        return;
      }
      if (sDay != undefined && (sDay < "00" || sDay > "31")) {
        this.validationErrorMessage = "Start day is not valid. please enter again"
        return;
      }
      if (sHour != undefined && (sHour < "00" || sHour > "24")) {
        this.validationErrorMessage = "Start hour is not valid. please enter again"
        return;
      }
      if (sMinute != undefined && (sMinute < "00" || sMinute > "60")) {
        this.validationErrorMessage = "Start minute is not valid. please enter again"
        return;
      }
    }
    var eYear, eMonth, eDay, eHour, eMinute;
    var ed = this.endDate.nativeElement.value;
    var eCondition = "";
    if (ed != undefined) {
      if (ed.substring(0, 4) > "1800")
        eYear = ed.substring(0, 4);
      if (ed.substring(5, 7) > "00")
        eMonth = +ed.substring(5, 7);
      if (ed.substring(8, 10) > "00")
        eDay = +ed.substring(8, 10);
      if (ed.substring(11, 13) > "00")
        eHour = +ed.substring(11, 13);
      if (ed.substring(14, 16) > "00")
        eMinute = +ed.substring(14, 16);
     

      if (eYear != undefined && sYear != undefined && sYear > eYear) {
        this.validationErrorMessage = "Start year should be less than end year. please enter again"
        return;
      }

      if (eYear != undefined && sYear != undefined && (eYear - sYear > 5)) {
        this.validationWarningMessage = "the selection will cause performance issues in the browser and map. Please consider narrowing the year selection. Are you sure to load all data for this period?";
        if (this.tabs != undefined && this.tabs.length() > 0)
          this.tabs.select(2);
      }

      if (eYear != undefined && (eYear < "1800" || eYear > "2100")) {
        this.validationErrorMessage = "End year is not valid. please enter again"
        return;
      }
      if (eMonth != undefined && (eMonth < "00" || eMonth > "12")) {
        this.validationErrorMessage = "End month is not valid. please enter again"
        return;
      }
      if (eDay != undefined && (eDay < "00" || eDay > "31")) {
        this.validationErrorMessage = "End day is not valid. please enter again"
        return;
      }
      if (eHour != undefined && (eHour < "00" || eHour > "24")) {
        this.validationErrorMessage = "End hour is not valid. please enter again"
        return;
      }
      if (eMinute != undefined && (eMinute < "00" || eMinute > "60")) {
        this.validationErrorMessage = "End minute is not valid. please enter again"
        return;
      }
    }

    if (sd != undefined) {
      // Date/Time range start
      if (this.period.val()) {
        if (sYear != undefined) {
          condition = "(Year > " + sYear + ")";
          this.minYear = sYear;
        }

        if (sYear == undefined || sYear == "" || sYear == "****") {
          this.validationErrorMessage = "Start year is not valid. please enter again"
          return;
        }

        if (sd.includes('*')) {
          this.validationErrorMessage = "Start date/time is not valid. please enter again"
          return;
        }


        if (sYear != undefined) {
          startCondition += " (Year = " + sYear;
          if (sMonth != undefined) {
            startCondition += " and (Month > " + sMonth;
          }
        }
        else
          if (sMonth != undefined) {
            if (condition != "")
              condition += " and ";
            condition += "(Month > " + sMonth + ")";
          }


        if (sMonth != undefined) {
          if (startCondition != "")
            startCondition += " or ";
          startCondition += " (Month = " + sMonth;
          if (sDay != undefined) {
            startCondition += " and (Day > " + sDay;
          }
          else if (sDay != undefined) {
            if (condition != "")
              condition += " and ";
            condition += "(Day > " + sDay + ")";
          }
        }

        if (sDay != undefined) {
          if (startCondition != "")
            startCondition += " or ";
          startCondition += " (Day = " + sDay;
          if (sHour != undefined) {
            startCondition += " and (Hour > " + sHour;
          }
        }
        else if (sHour != undefined) {
          if (condition != "")
            condition += " and ";
          condition += "(Hour > " + sHour + ")";
        }


        if (sHour != undefined) {
          if (startCondition != "")
            startCondition += " or ";
          startCondition += " (Hour = " + sHour;
          if (sMinute != undefined) {
            startCondition += " and (Minute >= " + sMinute;
          }
        }
        else if (sMinute != undefined) {
          if (condition != "")
            condition += " and ";
          condition += "(Minute >= " + sMinute + ")";
        }


        if (sYear != undefined)
          startCondition += ")";
        if (sMonth != undefined && sYear != undefined)
          startCondition += ")";
        if (sMonth != undefined)
          startCondition += ")";
        if (sDay != undefined && sMonth != undefined)
          startCondition += ")";
        if (sDay != undefined)
          startCondition += ")";
        if (sHour != undefined && sDay != undefined)
          startCondition += ")";
        if (sHour != undefined)
          startCondition += ")";
        if (sHour != undefined && sMinute != undefined)
          startCondition += ")";

        if (condition != "")
          if (startCondition != "")
            condition = "(" + condition + " or (" + startCondition + ")) ";
      }
      else
        //Periodic Intervals Start
        if (this.season.val()) {
          if (sYear != undefined) {
            startYearCondition = "(Year >= " + sYear + ")";
            this.minYear = sYear;
          }

          if (sMonth != undefined) {
            if (condition != "")
              condition += " and ";
            condition += "(Month > " + sMonth + ")";
          }

          if (sMonth != undefined) {
            if (startCondition != "")
              startCondition += " or ";
            startCondition += " (Month = " + sMonth;
            if (sDay != undefined) {
              startCondition += " and (Day > " + sDay;
            }
            else if (sDay != undefined) {
              if (condition != "")
                condition += " and ";
              condition += "(Day > " + sDay + ")";
            }
          }

          if (sDay != undefined) {
            if (startCondition != "")
              startCondition += " or ";
            startCondition += " (Day = " + sDay;
            if (sHour != undefined) {
              startCondition += " and (Hour > " + sHour;
            }
          }
          else if (sHour != undefined) {
            if (condition != "")
              condition += " and ";
            condition += "(Hour > " + sHour + ")";
          }


          if (sHour != undefined) {
            if (startCondition != "")
              startCondition += " or ";
            startCondition += " (Hour = " + sHour;
            if (sMinute != undefined) {
              startCondition += " and (Minute >= " + sMinute;
            }
          }
          else if (sMinute != undefined) {
            if (condition != "")
              condition += " and ";
            condition += "(Minute >= " + sMinute + ")";
          }


          if (sMonth != undefined)
            startCondition += ")";
          if (sDay != undefined && sMonth != undefined)
            startCondition += ")";
          if (sDay != undefined)
            startCondition += ")";
          if (sHour != undefined && sDay != undefined)
            startCondition += ")";
          if (sHour != undefined)
            startCondition += ")";
          if (sHour != undefined && sMinute != undefined)
            startCondition += ")";

          if (condition != "")
            if (startCondition != "")
              condition = "(" + condition + " or (" + startCondition + ")) ";

        }
    }

    if (ed != undefined) {
    
      // Date/Time range start
      if (this.period.val()) {

        if (eYear != undefined) {
          eCondition += "(Year < " + eYear + ")";
          this.maxYear = eYear;
        }

        if (eYear == undefined || eYear == "" || eYear == "****") {
          this.validationErrorMessage = "End year is not valid. please enter again"
          return;
        }

        if (ed.includes('*')) {
          this.validationErrorMessage = "End date/time is not valid. please enter again"
          return;
        }

        if (eYear != undefined) {
          endCondition += " (Year = " + eYear;
          if (eMonth != undefined) {
            endCondition += " and (Month < " + eMonth;
          }
        }
        else
          if (eMonth != undefined) {
            if (eCondition != "")
              eCondition += " and ";
            eCondition += "(Month < " + eMonth + ")";
          }


        if (eMonth != undefined) {
          if (endCondition != "")
            endCondition += " or ";
          endCondition += " (Month = " + eMonth;
          if (eDay != undefined) {
            endCondition += " and (Day < " + eDay;
          }
          else if (eDay != undefined) {
            if (eCondition != "")
              eCondition += " and ";
            eCondition += "(Day < " + eDay + ")";
          }
        }

        if (eDay != undefined) {
          if (endCondition != "")
            endCondition += " or ";
          endCondition += " (Day = " + eDay;
          if (eHour != undefined) {
            endCondition += " and (Hour < " + eHour;
          }
        }
        else if (eHour != undefined) {
          if (eCondition != "")
            eCondition += " and ";
          eCondition += "(Hour < " + eHour + ")";
        }


        if (eHour != undefined) {
          if (endCondition != "")
            endCondition += " or ";
          endCondition += " (Hour = " + eHour;
          if (eMinute != undefined) {
            endCondition += " and (Minute <= " + eMinute;
          }
        }
        else if (eMinute != undefined) {
          if (eCondition != "")
            eCondition += " and ";
          eCondition += "(Minute <= " + eMinute + ")";
        }


        if (eYear != undefined)
          endCondition += ")";
        if (eMonth != undefined && eYear != undefined)
          endCondition += ")";
        if (eMonth != undefined)
          endCondition += ")";
        if (eDay != undefined && eMonth != undefined)
          endCondition += ")";
        if (eDay != undefined)
          endCondition += ")";
        if (eHour != undefined && eDay != undefined)
          endCondition += ")";
        if (eHour != undefined)
          endCondition += ")";
        if (eHour != undefined && eMinute != undefined)
          endCondition += ")";

        if (eCondition != "") {
          if (endCondition != "")
            eCondition = "(" + eCondition + " or (" + endCondition + ")) ";
        }
      }
      else
        //Periodic Intervals endyear
        if (this.season.val()) {
          if (eYear != undefined) {
            endYearCondition = "(Year <= " + eYear + ")";
            this.maxYear = eYear;
          }

          if (eMonth != undefined) {
            if (eCondition != "")
              eCondition += " and ";
            eCondition += "(Month < " + eMonth + ")";
          }

          if (eMonth != undefined) {
            if (endCondition != "")
              endCondition += " or ";
            endCondition += " (Month = " + eMonth;
            if (eDay != undefined) {
              endCondition += " and (Day < " + eDay;
            }
            else if (eDay != undefined) {
              if (eCondition != "")
                eCondition += " and ";
              eCondition += "(Day < " + eDay + ")";
            }
          }

          if (eDay != undefined) {
            if (endCondition != "")
              endCondition += " or ";
            endCondition += " (Day = " + eDay;
            if (eHour != undefined) {
              endCondition += " and (Hour < " + eHour;
            }
          }
          else if (eHour != undefined) {
            if (eCondition != "")
              eCondition += " and ";
            eCondition += "(Hour < " + eHour + ")";
          }


          if (eHour != undefined) {
            if (endCondition != "")
              endCondition += " or ";
            endCondition += " (Hour = " + eHour;
            if (eMinute != undefined) {
              endCondition += " and (Minute <= " + eMinute;
            }
          }
          else if (eMinute != undefined) {
            if (eCondition != "")
              eCondition += " and ";
            eCondition += "(Minute <= " + eMinute + ")";
          }


          if (eMonth != undefined)
            endCondition += ")";
          if (eDay != undefined && eMonth != undefined)
            endCondition += ")";
          if (eDay != undefined)
            endCondition += ")";
          if (sHour != undefined && eDay != undefined)
            endCondition += ")";
          if (sHour != undefined)
            endCondition += ")";
          if (eHour != undefined && eMinute != undefined)
            endCondition += ")";

          if (eCondition != "")
            if (endCondition != "")
              eCondition = "(" + eCondition + " or (" + endCondition + ")) ";

        }
    }
    

    if (condition != "") {
      var dateCondition;
      if (this.period.val()) {
        dateCondition = condition;
        if (eCondition != "")
          if (dateCondition == "")
            dateCondition = " (" + eCondition + ") ";
          else
            dateCondition = dateCondition + " and " + " (" + eCondition + ") ";
      }
      else
        if (this.season.val()) {
          dateCondition = startYearCondition;
          if (eCondition != "")
            if (dateCondition == "")
              dateCondition = " (" + endYearCondition + ") ";
            else
              dateCondition = dateCondition + " and " + endYearCondition;

          if (condition != "" || eCondition != "")
            dateCondition += " and ";
          if ((sMonth != undefined && sMonth >= eMonth) ||
            (sMonth == undefined && sDay != undefined && sDay >= eDay) ||
            (sMonth == undefined && sDay == undefined && sHour != undefined && sHour >= eHour) ||
            (sMonth == undefined && sDay == undefined && sHour == undefined && sMinute != undefined && sMinute >= eMinute))
            {
            dateCondition = " (((Year > " + sYear + ") and (Year < " + eYear + ") and " + " ( " + condition + " or " + eCondition + ")) or " +
              " ((Year = " + sYear + ") and " + " ( " + condition + ")) or " +
              " ((Year = " + eYear + ") and " + " ( " + eCondition + ")))";
            }
            else
              dateCondition = dateCondition + " ( " + condition + " and " + eCondition + ") ";
        }


      if (dateCondition != "") {
        for (var i = 0; i < this.selectedDatasets.length; i++) {
          var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 1);
          var co = new Condition();
          co.conditionTypeID = 1;
          if (dCondition != undefined && dCondition.length > 0)
            co = dCondition[0];
          else
            this.selectedDatasets[i].conditions.push(co);
          co.text = dateCondition;
        }
      }
      else {
        this.validationErrorMessage = "Please define time period"
        this.tabs.select(2);
      }
    }
    else {
      this.validationErrorMessage = "Please define time period"
      this.tabs.select(2);
    }
  }

  getDateCriteriaCondition() {
    if (this.validationErrorMessage != "") return;
    if (this.selectedDatasets.length == 0) {
      this.validationErrorMessage = "Please select a dataset"
      this.tabs.select(1);
    }

    for (var i = 0; i < this.selectedDatasets.length; i++) {
      var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 3);
      var co = new Condition();
      co.conditionTypeID = 3;
      if (dCondition != undefined && dCondition.length > 0)
        co = dCondition[0];
      else
        this.selectedDatasets[i].conditions.push(co);
      co.text = "";
      co.description = "";

      for (let dataCriteriaCondition of this.dataCriteriaConditions) {
        var condition = "";
        var ar = dataCriteriaCondition[0].dataCriteriaNames;
        var d = ar.filter(s => s.formatTypeID == this.selectedDatasets[i].formatTypeID);
        if (d != undefined && d.length > 0) {
          if (dataCriteriaCondition[0].codeType != "" && dataCriteriaCondition[0].codeType != undefined) {
            for (let id of dataCriteriaCondition[1])
              condition += "[" + d[0].name + "] = '" + id + "' or ";
            condition = condition.substring(0, condition.length - 3);
            condition = " (" + condition + ") ";
          }
          else
            condition = " ([" + d[0].name + "] like '%" + dataCriteriaCondition[1][0] + "%') ";
        }
        else {
          // Apply data criteria condition on all datasets
          condition = " ID = 0 "
        }
        if (condition != "") {
          if (co.text == "")
            co.text = condition;
          else
            co.text = " (" + co.text + dataCriteriaCondition[2] + condition + ") ";
          if (co.description == "")
            co.description = dataCriteriaCondition[3];
          else
            co.description = " (" + co.description + dataCriteriaCondition[2] + dataCriteriaCondition[3] + ") ";
        }
      }
    }
  }

  getBoundaryCondition() {
    if (this.validationErrorMessage != "") return;
    var boundaryCondition = '';
    if (this.east.nativeElement.value != '180' ||
      this.west.nativeElement.value != '-180' ||
      this.north.nativeElement.value != '90' ||
      this.south.nativeElement.value != '-90') {
      if (this.east.nativeElement.value != '180')
        boundaryCondition = ' Longitude <= ' + this.east.nativeElement.value;

      if (this.west.nativeElement.value != '-180') {
        if (boundaryCondition != '')
          boundaryCondition += ' and ';
        boundaryCondition += ' Longitude >= ' + this.west.nativeElement.value;
      }

      if (this.north.nativeElement.value != '90') {
        if (boundaryCondition != '')
          boundaryCondition += ' and ';
        boundaryCondition += ' Latitude <= ' + this.north.nativeElement.value;
      }

      if (this.south.nativeElement.value != '-90') {
        if (boundaryCondition != '')
          boundaryCondition += ' and ';
        boundaryCondition += ' Latitude >= ' + this.south.nativeElement.value;
      }

      if (boundaryCondition != "") {
        for (var i = 0; i < this.selectedDatasets.length; i++) {
          var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 4);
          var co = new Condition();
          co.conditionTypeID = 4;
          if (dCondition != undefined && dCondition.length > 0)
            co = dCondition[0];
          else
            this.selectedDatasets[i].conditions.push(co);
          co.text = boundaryCondition;
        }
      }
    }
    else {
      for (var i = 0; i < this.selectedDatasets.length; i++) {
        var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 4);
        if (dCondition != undefined && dCondition.length > 0)
          dCondition[0].text = '';
      }
    }
  }

  getReferenceLayerCondition() {
    if (this.validationErrorMessage != "") return;
    if (this.viewMapShowGeographicalBox.referenceLayerConditions != "") {
      for (var i = 0; i < this.selectedDatasets.length; i++) {
        var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 2);
        var co = new Condition();
        co.conditionTypeID = 2;
        if (dCondition != undefined && dCondition.length > 0)
          co = dCondition[0];
        else
          this.selectedDatasets[i].conditions.push(co);
        co.text = "(" + this.viewMapShowGeographicalBox.referenceLayerConditions + ")";
        co.description = this.viewMapShowGeographicalBox.referenceLayerConditionsExternalLayer;
      }
    }
    else {
      for (var i = 0; i < this.selectedDatasets.length; i++) {
        var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 2);
        if (dCondition != undefined && dCondition.length > 0)
          dCondition[0].text = '';
      }
    }
  }

  getwktGeometryConditions() {
    if (this.validationErrorMessage != "") return;
    if (this.viewMapShowGeographicalBox.wktGeometryConditions != "") {
      for (var i = 0; i < this.selectedDatasets.length; i++) {
        var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 5);
        var co = new Condition();
        co.conditionTypeID = 5;
        if (dCondition != undefined && dCondition.length > 0)
          co = dCondition[0];
        else
          this.selectedDatasets[i].conditions.push(co);
        co.text = this.viewMapShowGeographicalBox.wktGeometryConditions;
      }
    }
    else {
      for (var i = 0; i < this.selectedDatasets.length; i++) {
        var dCondition = this.selectedDatasets[i].conditions.filter(s => s.conditionTypeID == 5);
        if (dCondition != undefined && dCondition.length > 0)
          dCondition[0].text = '';
      }
    }
  }


  dateKeyUp(event) {
    if (event.keyCode == 8 || event.keyCode == 46) return;
    var v = event.target.value;
    if (v.match(/^\d{4}$/) !== null) {
      event.target.value = v + '-';
    }
    else
      if (v.match(/^\d{5}$/) !== null) {
        event.target.value = v.substring(0, 4) + '-' + v.substring(4, 5)
      }
      else
        if (v.match(/^\d{4}\-\d{2}$/) !== null) {
          event.target.value = v + '-';
        }
        else
          if (v.match(/^\d{4}\-\d{3}$/) !== null) {
            event.target.value = v.substring(0, 7) + '-' + v.substring(7, 8)
          }
          else
            if (v.match(/^\d{4}\-\d{2}-\d{2}$/) !== null) {
              event.target.value = v + ' ';
            }
            else
              if (v.match(/^\d{4}\-\d{2}-\d{3}$/) !== null) {
                event.target.value = v.substring(0, 10) + ' ' + v.substring(10, 11)
              }
              else
                if (v.match(/^\d{4}\-\d{2}-\d{2} \d{2}$/) !== null) {
                  event.target.value = v + ':';
                }
                else
                  if (v.match(/^\d{4}\-\d{2}-\d{2} \d{3}$/) !== null) {
                    event.target.value = v.substring(0, 13) + ':' + v.substring(13, 14)
                  }

  }

  getWidth(): any {
    return '99.7%';
  }

  ondatasetGridRowselect(event: any) {
    var args = event.args;
    if (args.rowindex.length != undefined) {
      var count = this.datasetRecords.records.length;
      var check = true;
      if (args.rowindex.length == 0)
        check = false;
      for (var i = 0; i < count; i++)
        this.gridRowCheck(i, check);
    }
    else
      this.gridRowCheck(args.rowindex, true);
  }

  gridRowCheck(rowindex: number, check: boolean) {
    if (check) {
      var row = this.datasetGrid.getrowdata(rowindex);
      if (row != undefined) {
        this.datasetChecked(row.datasetID);
        //reset datsetid, themid, tagid param with user reaction
        this.datasetID = '';
        this.themeID = '';
        this.tagID = '';
      }
    }
    else {
      var row = this.datasetGrid.getrowdata(rowindex);
      var item = this.selectedDatasets.filter(o => o.datasetID == row.datasetID);
      this.selectedDatasets.splice(this.selectedDatasets.indexOf(item[0]), 1);
    }
    this.setDateCondition();
  }

  datasetChecked(datasetid: number) {
    var row = this.datasetRecords.originaldata.filter(s => s.datasetID == datasetid)[0];
    var mdc = new MapDatasetCondition();
    mdc.formatTypeID = row.formatTypeID;
    mdc.datasetID = row.datasetID;
    mdc.dataset = row.dataset;
    mdc.theme = row.theme;
    mdc.themeID = row.themeID;
    mdc.layerType = row.layerType;
    mdc.layerTypeID = row.layerTypeID;
    mdc.totalEvents = 0;
    mdc.firstYear = row.firstYear;
    mdc.lastYear = row.lastYear;
    var condition = new Condition();
    condition.text = "(DatasetID = " + row.datasetID + ")";
    condition.conditionTypeID = 0;
    mdc.conditions.push(condition);
    this.selectedDatasets.push(mdc);
  }

  setDateCondition() {
    var sDate;
    var eDate;
    if (this.selectedDatasets.length > 0) {
      sDate = this.selectedDatasets[0].firstYear;
      eDate = this.selectedDatasets[0].lastYear;

      for (let dataset of this.selectedDatasets) {
        if (sDate > dataset.firstYear)
          sDate = dataset.firstYear;
        if (eDate < dataset.lastYear)
          eDate = dataset.lastYear;
      }

      if (sDate != '2100' && eDate != '1800') {
        this.startDate.nativeElement.value = sDate;
        this.endDate.nativeElement.value = eDate;
      }
    }
  }

  ondatasetGridRowunselect(event: any) {
    var args = event.args;
    this.gridRowCheck(args.rowindex, false);
  }

  showGeographicalSearch() {
    this.viewMapShowGeographicalBox.open();
  }

  showDateConditions() {
    this.viewMapDateCondition.open(this.startDate.nativeElement.value, this.endDate.nativeElement.value);
  }

  showParam() {
    if ((this.datasetID == '' || this.datasetID == undefined) &&
      (this.themeID == '' || this.themeID == undefined) &&
      (this.tagID == '' || this.tagID == undefined) &&
      (this.area == '' || this.area == undefined)) return;
    this.removeCondition();
    if (this.datasetID != '' && this.datasetID != undefined) {
      var rows = this.datasetRecords.originaldata;
      for (var i = 0; i < rows.length; i++) {
        if (rows[i].datasetID == this.datasetID) {
          this.datasetChecked(rows[i].datasetID);
          this.datasetGrid.selectrow(rows[i].uid);
        }
      }
    }
    else
      if (this.themeID != '' && this.themeID != undefined) {
        var rows = this.datasetRecords.originaldata.filter(s => s.themeID == Number(this.themeID));
        for (var i = 0; i < rows.length; i++) {
          this.datasetChecked(rows[i].datasetID);
          this.datasetGrid.selectrow(rows[i].uid);
        }
      }
      else
        if (this.tagID != '' && this.tagID != undefined) {
          var rows = this.themeView.filter(s => s.tagID == Number(this.tagID));
          for (var i = 0; i < rows.length; i++) {
            var datasetrows = this.datasetRecords.originaldata.filter(s => s.themeID == Number(rows[i].themeID));
            for (var j = 0; j < datasetrows.length; j++) {
              this.datasetChecked(datasetrows[i].datasetID);
              this.datasetGrid.selectrow(datasetrows[i].uid);
            }
          }
        }

    if (this.area != '' && this.area != undefined) {
      if ((this.datasetID == "" || this.datasetID == undefined) &&
        (this.themeID == "" || this.themeID == undefined) &&
        (this.tagID == "" || this.tagID == undefined)) {
        this.removeCondition();
        var rows = this.datasetRecords.originaldata;
        for (var i = 0; i < rows.length; i++) {
          this.datasetChecked(rows[i].datasetID);
          this.datasetGrid.selectrow(rows[i].uid);
        }
      }
      this.viewMapShowGeographicalBox.viewMapShowReferenceLayer.area = this.area;
      this.viewMapShowGeographicalBox.viewMapShowReferenceLayer.mode = 1;
      this.viewMapShowGeographicalBox.open();
      this.viewMapShowGeographicalBox.close();
    }

    this.setDateCondition();
    if (this.area != '' && this.area != undefined)
      this.tabs.select(2);
    else
      this.tabs.select(1);
  }
}
