import { Component, ViewChild } from '@angular/core';
import { jqxMenuModule }  from 'jqwidgets-ng/jqxmenu';

@Component({
  selector: 'app-management-component',
  templateUrl: './management.component.html'
})
export class ManagementComponent {
  @ViewChild('menu')
  myMenu: jqxMenuModule;

  readonly formatTypeTab = 'formatType';
  isFormatTypeActivated = false;

  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();
    this.isFormatTypeActivated = activeTab == this.formatTypeTab;
  }
}
