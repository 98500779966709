<jqxWindow #viewGeographicalBoxWindow id="viewGeographicalBoxWindow" [width]="800" [height]="540" [resizable]="false" [isModal]="true" [autoOpen]="false" [modalOpacity]="'0.5'">
  <div #windowHeader>
    <span id="captureContainer" style="float: left">Geographical filter(s)</span>
  </div>
  <div #windowContent style="overflow: hidden; float:left">
    <form class="form-inline" style="height:100%; width:100%;" #viewMap="ngForm" (ngSubmit)="AddGeoBoxCondition()">
      <div class="form-group row" style="float:left; width:100%; height:92%; margin-top: 5px;">
        <div class="container">
          <div #geoBoxMap id="geoBoxMap" (pointermove)="mapPointerMove($event)" style="float: left; width:790px; height:450px; position: relative;">
            <div class="ol-custom-right ol-unselectable ol-control">
              <button style="width: 180px; height: 35px;font-size: 12px;font-weight: normal;" id="drawRectangle" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" (click)="drawRectangle()" title="Draw Rectangle"><img style="width: 20px; height: 20px; margin-right: 10px;" src="/image/Box.png" />Draw Rectangle</button>
              <button style="width: 180px; height: 35px;font-size: 12px;font-weight: normal;" id="drawPolygon" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" (click)="drawPolygon()" title="Draw Polygon"><img style="width: 20px; height: 20px; margin-right: 10px;" src="/image/Polygon.png" />Draw Polygon</button>
              <button style="width: 180px; height: 35px;font-size: 12px;font-weight: normal;" id="geoBoxShowReferenceLayers" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" (click)="showReferenceLayers($event)" title="Select Reference Layers"><i class="glyphicon glyphicon-th-list" style="margin-right: 10px;" aria-hidden="true"></i>Filter by reference layer</button>
              <button style="width: 180px; height: 35px;font-size: 12px;font-weight: normal;" id="clearMap" class="ol-zoom-in ol-custom-right-button btn btn-primary btn-sm" type="button" (click)="clearMap()" title="Clear map"><i class="glyphicon glyphicon glyphicon-erase" style="margin-right: 10px;" aria-hidden="true"></i>Clear map</button>
            </div>
            <div>
              <app-viewMap-showReferenceLayer #viewMapShowReferenceLayer> </app-viewMap-showReferenceLayer>
            </div>
          </div>
        </div>

      </div>

      <div class="form-group row" style="float:left; width:790px; height:40px;">
          <div class="col-sm-2 pull-left">
            <button type="submit" class="btn btn-primary btn-sm">Apply filter(s)</button>
          </div>
          <div class="col-sm-1 pull-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="cancel()">Cancel</button>
          </div>
      </div>

    </form>
    </div>
</jqxWindow>
