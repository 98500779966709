import { ToastaModule } from 'ngx-toasta';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationConfig } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxMenuModule } from 'jqwidgets-ng/jqxmenu';
import { jqxComboBoxModule } from 'jqwidgets-ng/jqxcombobox';
import { jqxTreeModule } from 'jqwidgets-ng/jqxtree';
import { jqxTreeMapModule } from 'jqwidgets-ng/jqxtreemap';
import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { jqxRadioButtonModule } from 'jqwidgets-ng/jqxradiobutton';
import { jqxDockingLayoutModule } from 'jqwidgets-ng/jqxdockinglayout';
import { jqxTreeGridModule } from 'jqwidgets-ng/jqxtreegrid';
import { jqxLayoutModule } from 'jqwidgets-ng/jqxlayout';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxPanelModule } from 'jqwidgets-ng/jqxpanel';
import { jqxTooltipModule } from 'jqwidgets-ng/jqxtooltip';
import { jqxNotificationModule } from 'jqwidgets-ng/jqxnotification'

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ViewMapComponent } from './view-map/view-map.component';
import { ViewMapSearchComponent } from './components/viewMap/viewMap-search.component';
import { ViewMapProfileComponent } from './components/viewMap/viewMap-profile.component';
import { ViewMapDisclaimerComponent } from './components/viewMap/viewMap-disclaimer.component';
import { ViewMapShowReferenceLayerComponent } from './components/viewMap/viewMap-showReferenceLayer.component';
import { ViewMapGeographicalBoxComponent } from './components/viewMap/viewMap-geographicalBox.component';
import { ViewMapDateConditionComponent } from './components/viewMap/viewMap-dateCondition.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { DownloadManagementComponent } from './download-management/download-management.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { ManagementComponent } from './management/management.component';
import { FormatTypeManagementComponent } from './components/formatType/formatType-management.component';
import { FormatTypeEditorComponent } from './components/formatType/formatType-editor.component';

import { AppRoutingModule } from './app-routes.module';


import { LocalStorageService } from './services/local-storage.service';
import { AuthService } from './services/auth.service';
import { AlertService } from './services/alert.service';
import { AuthGuardService } from './services/auth-guard.service';
import { FormatTypeService } from './services/formatType.service';
import { ViewMapService } from './services/view-map.service';
import { DownloadService } from './services/download.service';
import { RECOService } from './services/reco.service';
import { VocabService } from './services/vocab.service';
import { EmailService } from './services/email.service';




@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ViewMapSearchComponent,
        ViewMapComponent,
        ViewMapProfileComponent,
        ViewMapDisclaimerComponent,
        ViewMapShowReferenceLayerComponent,
        ViewMapGeographicalBoxComponent,
        ViewMapDateConditionComponent,
        NotFoundComponent,
        LoginComponent,
        FormatTypeManagementComponent,
        ManagementComponent,
        FormatTypeEditorComponent,
        DownloadsComponent,
        DownloadManagementComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }), 
    FormsModule,
    ToastaModule.forRoot(),
    AppRoutingModule,
    jqxGridModule,
    jqxButtonModule,
    jqxCheckBoxModule,
    jqxInputModule,
    jqxWindowModule,
    jqxMenuModule,
    jqxComboBoxModule,
    jqxTreeModule,
    jqxTabsModule,
    jqxRadioButtonModule,
    jqxTreeMapModule,
    jqxDockingLayoutModule,
    jqxTreeGridModule,
    jqxPanelModule,
    jqxRibbonModule,
    jqxLayoutModule,
    jqxTooltipModule,
    jqxNotificationModule
  ],
  providers: [
        LocalStorageService,
        AlertService,
        AuthService,
        AuthGuardService,
        FormatTypeService,
        ViewMapService,
        RECOService,
        VocabService,
        DownloadService,
        EmailService,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
