import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Core } from '../shared/core';

@Injectable()
export class EmailService {
  baseURL: any;
  constructor(protected http: HttpClient, @Inject('BASE_URL') url: string, protected router: Router) {
    this.baseURL = url;
  }

  sentError(error: string) {
    return this.sentErrorEndPoint(error);
  }

  sentErrorEndPoint(error: string) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `application/vnd.iman.v1 json, application/json, text/plain, */*`
      })
    };
    var mapCondition = {
      FormatTypeID: 0,
      Condition: error
    };
    return this.http.post(this.baseURL + 'Email/SendError', Core.tryJsonStringify(mapCondition), options);
  }

}
