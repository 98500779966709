import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs';


export enum MessageType{
  alert,
  confirm,
  prompt
}

export enum MessageSeverity {
  default,
  information,
  error,
  warning,
  success,
  wait
}

export class Dialog {
  constructor(
    public message: string,
    public type: MessageType,
    public okCallback: (val?: any) => any,
    public cancelCallback: () => any,
    public defaultValue: string,
    public okLabel: string,
    public cancelLabel: string) { }
}

export class Message {
  constructor(
    public severity: MessageSeverity,
    public summary: string,
    public detail: string,
    public showClose: boolean = true
  ) { }
}

@Injectable()
export class AlertService {
  private isLoading = false;
  private messages = new Subject<Message>();
  private remainMessages = new Subject<Message>();
  private dialogs = new Subject<Dialog>();
  private loadingMessageId: any;

  showDialog(
    message: string,
    type?: MessageType,
    okCallback?: (val?: any) => any,
    cancelCallback?: () => any,
    okLabel?: string,
    cancelLabel?: string,
    defaultValue?: string) {
    if (!type)
      type = MessageType.alert;
    document.getElementById("toasta").classList.remove('toasta-position-center-center');
    document.getElementById("toasta").classList.add('toasta-position-top-right');

    this.dialogs.next({
      message: message,
      type: type,
      okCallback: okCallback,
      cancelCallback: cancelCallback,
      okLabel: okLabel,
      cancelLabel: cancelLabel,
      defaultValue: defaultValue
    });
  }

  showMessage(
    summary: string,
    detail: string,
    severity: MessageSeverity,
    error?: any) {
    if (!severity)
      severity = MessageSeverity.default;
    document.getElementById("toasta").classList.remove('toasta-position-center-center');
    document.getElementById("toasta").classList.add('toasta-position-top-right');
    this.messages.next({ severity: severity, summary: summary, detail: detail, showClose: true});
  }

  showRemainMessage(
    summary: string,
    detail: string,
    severity: MessageSeverity,
    error?: any,
    showInCenter: boolean = false,
    showClose: boolean = true) {
    if (!severity)
      severity = MessageSeverity.default;
    if (!showInCenter) {
      document.getElementById("toasta").classList.remove('toasta-position-center-center');
      document.getElementById("toasta").classList.add('toasta-position-top-right');
    }
    else {
      document.getElementById("toasta").classList.remove('toasta-position-top-right');
      document.getElementById("toasta").classList.add('toasta-position-center-center');
    }
    this.remainMessages.next({ severity: severity, summary: summary, detail: detail, showClose: showClose });
  }

  startLoadingMessage(message = 'Loading...', caption = '') {
    //this.stopLoadingMessage();
    this.isLoading = true;
    document.getElementById("overlay").style.display = "block";
    clearTimeout(this.loadingMessageId);
    this.resetMessage();
    this.loadingMessageId = setTimeout(() => {
      this.showRemainMessage(caption, message, MessageSeverity.wait, '', true, false);
    }, 1000);
  }

  stopLoadingMessage() {
    this.isLoading = false;
    clearTimeout(this.loadingMessageId);
    this.resetMessage();
    document.getElementById("overlay").style.display = "none";
  }


  resetMessage() {
    //this.messages.next({ severity: MessageSeverity.default, summary: '', detail: '', showClose: true });
    this.messages.next(null);
  }
  getDialogEvent(): Observable<Dialog> {
    return this.dialogs.asObservable();
  }

  getMessageEvent(): Observable<Message> {
    return this.messages.asObservable();
  }

  getRemainMessageEvent(): Observable<Message> {
    return this.remainMessages.asObservable();
  }

}

