import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class VocabService {
  baseURL: any;
  constructor(protected http: HttpClient, @Inject('BASE_URL') url: string, protected router: Router) {
    this.baseURL = url;
  }

  getCodeType(codeType: string) {
    return this.getCodeTypeEndPoint(codeType);
  }

  getCodeTypeEndPoint(codeType: string ) {
    return this.http.get(this.baseURL + 'Vocab/GetCodeType/' + codeType);
  }

}
