<div class="container" style="float:left; width: 100%; height:800px;">
  <div>
    <div class="navbar">
      <ul class="nav navbar-nav navbar-Left">
        <li>
          <a href="javascript:;" (click)="CleanUpDownloadFiles()">
            <i class="fas fa-check-double"></i>Clean up download file
          </a>
        </li>
        <li>
          <a href="javascript:;" (click)="GetAllDownloadFiles()">
            <i class="fas fa-check-double"></i>Load all download files
          </a>
        </li>

      </ul>
    </div>
  </div>
  <div style="float: left; width: 100%; height: 100%; margin-left: auto; margin-right: auto;">
    <h1>List of downloads</h1>
    <div>
      <jqxGrid #downloadGrid [source]="downloadAdapter" [columns]="columns" [columnsautoresize]="true" (onCellclick)="Cellclick($event)" [width]="getWidth()"
               [pageable]="true" [autoheight]="true" [sortable]="true"
               [altrows]="true" [enabletooltips]="true" [editable]="false"
               [selectionmode]="'singlecell'" [pagesize]="30">
        </jqxGrid>
    </div>
  </div>
</div>
<div>
</div>



