<h1>Format Type List</h1>
<div>
  <div>
    <div class="navbar">
      <ul class="nav navbar-nav navbar-Left">
        <li>
          <a href="javascript:;" (click)="newFormatType()">
            <i class="fa fa-plus-circle"></i>New Format Type
          </a>
        </li>
        <li>
          <a href="javascript:;" (click)="syncAll()">
            <i class="fas fa-check-double"></i>Synchronize all Format Types
          </a>
        </li>

      </ul>
    </div>
  </div>
  <div>
    <jqxGrid #formatTypeGrid [source]="dataAdapter" [columns]="columns" [width]="getWidth()"
             [pageable]="true" [autoheight]="true" [sortable]="true"
             [altrows]="true" [enabletooltips]="true" [editable]="false"
             [selectionmode]="'singlerow'" [pagesize]="30">
    </jqxGrid>
  </div>
  <div>
    <app-formatType-editor #FormatTypeEditor> </app-formatType-editor>
  </div>
</div>

